export const translation = {
  header: {
    items: [
      {
        name: 'Strona główna',
        href: '/',
      },
      {
        name: 'System',
        href: '#system',
      },
      {
        name: 'Moduł',
        href: '#module',
      },
      {
        name: 'Montaż',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Język',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Łatwość',
        text2: 'połącznia',
      },
      subtitle: 'Twoje łącze na przyszłość',
      cta: 'więcej',
    },
    handling: {
      title: 'INTUICYJNA OBSŁUGA SPOTYKA SIĘ Z ZAawansowaną TECHNOLOGIĄ',
      vorreiterPrefix: 'Połączenie precyzji i funkcjonalnego designu czyni ',
      vorreiterSuffix: ' wiodącym wyborem w technologii sieciowej.',
      experte: ' oferuje efektywne rozwiązania 360°, łączące design i wydajność celem zminimalizowania złożoności',
      pionier: ' jest pionierem w tworzeniu nowych przestrzeni roboczych, łącząc ekspercką wiedzę z intuicyjną obsługą.',
    },
    download: {
      prefix: 'Przegląd ',
      suffix: ' - pobierz system',
      cta: 'Pobierz',
      href: 'https://fixlink.eu/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Przegląd produktów',
      title: 'FIXLINK<sup>®</sup> - SYSTEM',
    },
    module: {
      overview: 'Pobierz przegląd',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT - Certyfikaty',
          text: 'Ufamy GHMT i ich Premium Verification Program w celu niezależnego zapewnienia jakości naszych produktów sieciowych. Gwarantujemy najwyższe standardy produktów dla naszych klientów, bazując na 20-letnim doświadczeniu.',
        },
        datasheets: {
          title: 'Karty katalogowe',
          text: 'Tutaj znajdziesz wszystkie niezbędne karty katalogowe. Pobierz je i miej wszystkie informacje pod ręką.',
        },
        compatibilitySheets: {
          title: 'Listy kompatybilności',
          text: '',
        },
        excelTemplates: {
          title: 'Okna etykiet w formacie Excel',
          text: '',
        },
      },
    },
    video: {
      title: 'INSTRUKCJA MONTAŻU',
      text: 'Obejrzyj film lub pobierz PDF tutaj',
      manual: {
        title: 'INSTRUKCJE MONTAŻU:',
      },
    },
    separator: {
      title: 'Więcej niż tylko połączenie.',
      title2: 'Odkryj wszechświat EasyLan',
      text: 'Odkryj różnorodność innowacyjnych rozwiązań sieciowych na EasyLan.de: dostosowane indywidualnie do Ciebie. GameChanger dla twojej sieci to systemy okablowania EasyLan.',
      cta: 'więcej',
    },
    combination: {
      bau: {
        prefix: 'Doświadcz maksymalnej elastyczności i jakości z ',
        text: ' nośnikiem modułów EasyLan ',
        suffix: ' bezpośrednio z Europy.',
      },
      intelligent: {
        prefix: 'Twoja sieć',
        text: ' dostosowana ',
        suffix: 'z konfigurowalną skrzynką spawania EasyLan',
      },
      fixlink: {
        prefix: 'Z naszymi ',
        suffix: ' produktami możesz trwale ulepszyć swoje budynki biurowe.',
      },
      hds: {
        prefix: 'System ',
        text: ' EasyLan<sup>®</sup> H.D.S. ',
        suffix: ' łączy łatwość instalacji i konserwacji jak żaden inny system trunkingowy na rynku.',
      },
    },
    recommended: {
      titleLabel: 'Zalecane',
      title: 'fixLink<sup>®</sup> - SYSTEM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Gwarancja produktu',
        text: [
          'Gwarancja produktu obejmuje wszystkie komponenty systemu okablowania ',
          ' - światłowodowego i miedzianego, które zostały zainstalowane i przetestowane przez wykwalifikowanego instalatora zgodnie z wytycznymi ',
          ' oraz obowiązującymi normami instalacyjnymi EN50174.',
        ],
      },
      {
        title: 'Gwarancja wydajności',
        text: [
          'Gwarancja wydajności gwarantuje właściwości systemu, w tym prędkości transmisji (10MBit, 100MBit, 1GBit i 10GBit) oprócz aplikacji (Ethernet, Fiber Channel, ATM itp.).',
        ],
      },
      {
        title: 'Gwarancja aplikacji',
        text: [
          'Gwarancja aplikacji gwarantuje wszystkie specyfikowane aplikacje (Ethernet, Fiber Channel, ATM itp.), które są oparte na obowiązujących standardach okablowania ISO/IEC 11801, EN50173 i EIA/TIA 568B w momencie instalacji.',
        ],
      },
    ],
    end: {
      title: 'POŁĄCZENIA NIGDY NIE BYŁY TAK PROSTE.',
      text: 'Intuicyjna obsługa spotyka się z zaawansowaną technologią; odkryj bezproblemowe połączenie przyszłości: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Moduły przyłączeniowe',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone-Modul Kat. 6A ISO/IEC, ekranowany',
        text: 'Dzięki modułowi fixLink<sup>®</sup> Pro RJ45 Keystone użytkownik otrzymuje najwyższej jakości moduł gniazd RJ45. Moduł fixLink<sup>®</sup> Pro RJ45 Keystone jest mozliwy do zainstalowania w mniej niż minutę za pomocą obcinaka bocznego. Łatwo dostępna strefa styku zapewnia doskonałą jakość montażu, a tym samym niezawodną wydajność transmisji. Specjalna geometria kontaktu w połączeniu z 50 μ" powłoką złota zapewnia 4PPoE zgodnie z IEEE 802.3bt w każdych warunkach.Obudowa wykonana jest z niklowanego odlewu cynkowego, co spełnia najwyższe wymagania środowiskowe i mechaniczne. W połączeniu z kompatybilną ramką modułową możliwe jest połączenie ze wszystkimi programami przełączników. Certyfikaty niezależnych laboratoriów testowych potwierdzają najwyższą jakość modułu fixLink<sup>®</sup> SL Keystone. Moduły fixLink<sup>®</sup> SL RJ45 Keystone dostarczane są w przyjaznym dla środowiska opakowaniu kartonowym.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A Certyfikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Certyfikat Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Kat. 6A ISO/IEC, ekranowany',
        text: 'Za pomocą modułu Extender fixLink<sup>®</sup> można łatwo przedłużyć przewody. Ponadto można reaktywować nieaktywne przewody w instalacji i przedłużyć je do wymaganej dlugości. Ograniczona przestrzeń w kanale kablowym zostanie zoptymalizowana. Extender posiada możliwość mocowania na ścianie. Po poprawnej intalacji przedłużony przewód może realizować trasmisję 10-Gigait-Ethernet oraz PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'Wtyczka RJ45 Kat. 6A ISO/IEC, ekranowana',
        text: 'Dzięki wtzkowemu modułowi fixLink<sup>®</sup> RJ45 użytkownik otrzymuje najwyższej jakości moduł wtyczki RJ45. Specjalna geometria kontaktu w połączeniu z 50 μ" powłoką złota zapewnia 4PPoE zgodnie z IEEE 80.3bt w każdych warunkach. Obudowa wykonana jest z niklowanego odlewu cynkowego, co zapewnia najwyższe wymagania środowiskowe i mechaniczne. Certyfikaty niezależnych laboratoriów testowych(aktualna lsta: www.easylan.de) potwierdzają najwyższą jakośćmodułu fixLink<sup>®</sup> RJ45.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'Moduł RJ45 Keystone Kat. 6A EIA/TIA, nieekranowany',
        text: 'Dzięki modułowi fixLink<sup>®</sup> RJ45 Keystone (zdolnemu do PoE+) użytkownik otrzymuje najwyższej jakości moduł gniazd RJ45. Moduł fixLink<sup>®</sup> RJ45 Keystone jest mozliwy do zainstalowania w mniej niż minutę za pomocą obcinaka bocznego. DŁatwo dostępna strefa styku zapewnia doskonałą jakość montażu, a tym samym niezawodną wydajność transmisji. Obudowa wykonana jest z poliwęglanu, co zapewnia najwyższe wymagania środowiskowe i mechaniczne. W połączeniu z kompatybilną ramką modułową możliwe jest połączenie ze wszystkimi programami przełączników. Certyfikaty niezależnych laboratoriów testowych potwierdzają najwyższą jakośćmodułu fixLink<sup>®</sup> SL Keystone. Moduły fixLink<sup>®</sup> SL RJ45 Keystone dostarczane są w przyjaznym dla środowiska opakowaniu kartonowym.',
      },
      fixLink_SL_02: {
        name: 'Moduł SL RJ45 Keystone Kat. 6A ISO/IEC, ekranowany',
        text: 'Dzięki modułowi fixLink<sup>®</sup> SL RJ45 Keystone użytkownik otrzymuje najwyższej jakości moduł gniazd RJ45. Moduł fixLink<sup>®</sup> SL RJ45 Keystone jest mozliwy do zainstalowania w mniej niż minutę za pomocą obcinaka bocznego. Łatwo dostępna strefa styku zapewnia doskonałą jakość montażu, a tym samym niezawodną wydajność transmisji. Do uzyskania efektu ekranowania 360° wystarczy zapiąć zintegrowany klin, co eliminuje czasochłonne zakładanie opaski zaciskowej. Specjalna geometria kontaktu w połączeniu z 50 μ" powłoką złota zapewnia 4PPoE zgodnie z IEEE 802.3bt w każdych warunkach. Obudowa wykonana jest z niklowanego odlewu cynkowego, co spełnia najwyższe wymagania środowiskowe i mechaniczne. W połączeniu z kompatybilną ramką modułową możliwe jest połączenie ze wszystkimi programami przełączników. Certyfikaty niezależnych laboratoriów testowych potwierdzają najwyższą jakość modułu fixLink<sup>®</sup> SL Keystone. Moduły fixLink<sup>®</sup> SL RJ45 Keystone dostarczane są w przyjaznym dla środowiska opakowaniu kartonowym.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A Certyfikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Raport z testów 4PoE według DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'Certyfikat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'Moduł SL RJ45 Keystone, kątowy Kat. 6A ISO/IEC, ekranowany',
        text: 'Dzięki modułowi fixLink<sup>®</sup> SL RJ45 Keystone użytkownik otrzymuje najwyższej jakości moduł gniazd RJ45, który został specjalnie zaprojektowany do zastosowań w ograniczonej przestrzeni.Wyjściu kabla jest realizowane pod kątem 75° co ten nadaje się szczególnie do zastosowania w gniazdach danych i okablowaniach wnękowych. Minimalna głębokość montażu wynosząca 27 mm pozwala na niemal nieograniczoną różnorodność zastosowań. Moduł fixLink<sup>®</sup> SL RJ45 Keystone jest mozliwy do zainstalowania w mniej niż minutę za pomocą obcinaka bocznego. Łatwo dostępna strefa styku zapewnia doskonałą jakość montażu, a tym samym niezawodną wydajność transmisji. Specjalna geometria kontaktu w połączeniu z 50 μ" powłoką złota zapewnia PoE+ zgodnie z IEEE 80.3at w każdych warunkach. Obudowa wykonana jest z niklowanego odlewu cynkowego, co spełnia najwyższe wymagania środowiskowe i mechaniczne. W połączeniu z kompatybilną ramką modułową możliwe jest połączenie ze wszystkimi programami przełączników. Certyfikaty niezależnych laboratoriów testowych potwierdzają najwyższą jakość modułu fixLink<sup>®</sup> SL Keystone. Moduły fixLink<sup>®</sup> SL RJ45 Keystone dostarczane są w przyjaznym dla środowiska opakowaniu kartonowym.',
        ghmtCertificates: [
          {
            name: 'Certyfikat GHMT PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certyfikat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Narzędzia i akcesoria',
        text: [
          'Narzędzie do zdejmowania izolacji do usuwania zewnętrznej powłoki i metalowych folii kabla danych. Kolor niebieski.',
          'Taśma miedziana do mocowania ekranu. Wstępnie przycięta na 35 x 9 mm. Jednostka opakowaniowa: 20 sztuk/torebka 1500 sztuk/rolka',
          'Kolorowe osłony przeciwpyłowe dla modułów preLink<sup>®</sup> SL i fixLink<sup>®</sup> SL Keystone.',
          'Rodzaj opakownania - woreczek strunowy (50 szt.)',
        ],
        ghmtCertificates: [
          {
            name: 'Certyfikat GHMT PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certyfikat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Kabel instalacyjny',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Kabel danych EasyLan<sup>®</sup> Gold S/FTP AWG22/1 1500 MHz Kat. 7A',
        text: [
          'Kabel danych do przesyłania sygnałów analogowych i cyfrowych w okablowaniu pierwotnym, wtórnym i trzeciorzędnym. Nadaje się do zastosowań do klasy FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certyfikat GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'Certyfikat GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Karta katalogowa',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Kabel danych EasyLan<sup>®</sup> Silber S/FTP AWG23/1 1200 MHz Kat. 7A',
        text: [
          'Kabel danych do przesyłania sygnałów analogowych i cyfrowych w okablowaniu pierwotnym, wtórnym i trzeciorzędnym. Nadaje się do zastosowań do klasy FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certyfikat GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Karta katalogowa',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Kabel danych EasyLan<sup>®</sup> Bronze S/FTP AWG23/1 1000 MHz Kat. 7',
        text: 'Kabel danych do przesyłania sygnałów analogowych i cyfrowych w okablowaniu pierwotnym, wtórnym i trzeciorzędnym. Nadaje się do zastosowań do klasy FA.',
        datasheets: [
          {
            name: 'Karta katalogowa',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Ganiazda internetowe',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Obudowa natynkowa i ramka dla wymienionych modułów',
        text: [
          'Obudowa natynkowa IP20, 80x80x42mm do montażu modułów EasyLan',
          'Ramka natynkowej o wysokości 10mm',
          'Ramka do gniazda pojedyńczego 80x80mm',
          'Ramka do gniazda podwójnego 80x150mm',
        ],
        datasheets: [
          {
            name: 'Obudowa natynkowa',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Rozszerzenie głębokości obudowy natynkowej',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Moduł SL, wyjście 45°, natynkowe, podtynkowe, kanałowe, podłogowe, 1-krotne, 2-krotne, 3-krotne',
        text: 'Moduły SL są szczególnie odpowiednie do montażu w kanałach kablowych (EK), montażu podtynkowego (UP) oraz natynkowego (AP).Poprzez wyłamanie dwóch uchwytów montażowych możliwy jest również montaż w skrzynkach podłogowych (BT). Moduł SL może bezpiecznie i trwale pomieścić od jednego do trzech keystone’ów EasyLan. Posiada duże, centralnie umieszczone okno etykiety dla łatwego oznaczania. Płytka centralna (50x50 mm) i ramka (80x80 mm) wykonane jest z wytrzymałego na pęknięcia tworzywa sztucznego,odpornego na promieniowanie UV, zapewniając wytrzymałość oraz trwały wygląd.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Moduł SL, wyjście 45°, natynkowe, podtynkowe, kanałowe, podłogowe, 1-krotne, 2-krotne',
        text: 'Moduł SL, wykonany jest z ramki odlewanej z cynku. Nadaje się do montażu w kanałach kablowych (EK), montażu podtynkowego (UP) lub natynkowego (AP) za pomocą oddzielnej ramki. Przez wyłamanie dwóch uchwytów montażowych możliwy jest również montaż w skrzynkach podłogowych (BT). Gniazdo danych można łączyć z niemal wszystkimi dostępnymi szstemami na rynku. Dzięki kompatybilności z UAE gniazdo danych nie ma miejsca na klapkę przeciwpyłową.',
        datasheets: [
          {
            name: 'Keystone 1-/2-krotny, bez płytki centralnej',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: 'Keystone 2-krotny, z płytką centralną 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: 'Keystone 1-krotny z płytką centralną',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Moduł IP44 natynkowy, 1-krotny, 2-krotny',
        text: 'Wytrzymała obudowa natynkowa nadaje się do instalacji dwóch modułów preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone przy użyciu wkładki adaptera. Z tyłu obudowy znajdują się dwa łatwe do przebicia punkty do wprowadzenia dwóch kabli o średnicy 5,0 - 9,5 mm każdy. Uchwyt modułu osiąga stopień ochrony IP20 po podłączeniu i IP44 po zamknięciu.',
      },
      DoseUPleer2xKS: {
        name: 'Moduł, wyjście proste, 2-krotny',
        text: 'Moduł z prostym wyjściem keystone składa się z ramki odlewanej z cynku i nadaje się do montażu w kanałach kablowych (EK), montażu podtynkowego (UP) lub natynkowego (AP) za pomocą oddzielnej ramki.',
      },
      bodentank: 'Gniazda podlogowe',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'Systemy skrzynek podłogowych OBO/Ackermann UT3, UT4',
        text: 'Keystone-Bodentankträger umieszsczony w skrzynki podłogowej oferuje maksymalizacje przestrzeni do instalacji kabli. Pochyłe wejścia i wyjścia kabli zapewniają bezpieczne prowadzenie kabli nawet przy bardzo niskich przestrzeniach międzypodłogowych. Możliwe jest instalacja dla 4x modułów preLink®/fixLink<sup>®</sup> lub 1x H.D.S. Keystone-Bodentankträger jest mocowany za pomocą dwóch śrub, a nieużywane otwory mogą być zabezpieczone przed kurzem za pomocą zaślepek.',
        datasheets: [
          {
            name: 'Płyta nośna dla UT3 bez płyty montażowej',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Płyta nośna dla UT4 bez płyty montażowej',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'Podstawowy nośnik skrzynki podłogowej OBO/Ackermann UT3, UT4',
        text: 'Keystone-Bodentankträger w umieszsczony w skrzynki podłogowej oferuje maksymalizacje przestrzeni do instalacji kabli. Pochyłe wejścia i wyjścia kabli zapewniają bezpieczne prowadzenie kabli nawet przy bardzo niskich przestrzeniach międzypodłogowych. Keystone- Bodentankträger jest mocowany za pomocą dwóch śrub, a nieużywane otwory mogą być zabezpieczone przed kurzem za pomocą zaślepek. Dzięki adapterowi istnieje możliwość kompatybilności z skrzynkami podłogowymi firmy Electraplan.',
        datasheets: [
          {
            name: 'Nośnik skrzynki podłogowej UT3 na maksymalnie 6 modułów',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Nośnik skrzynki podłogowej UT4 na maksymalnie 9 modułów',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Nośnik skrzynki podłogowej UT4 na maksymalnie 12 modułów',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'Podstawowy nośnik skrzynki podłogowej PUK UT4',
        text: 'Keystone-Bodentankträger umieszsczony w skrzynki podłogowej oferuje maksymalizacje przestrzeni do instalacji kabli. Pochyłe wejścia i wyjścia kabli zapewniają bezpieczne prowadzenie kabli nawet przy bardzo niskich przestrzeniach międzypodłogowych. Keystone- Bodentankträger jest mocowany za pomocą dwóch śrub, a nieużywane otwory Keystone- Bodentankträger mogą być zabezpieczone przed kurzem za pomocą zaślepek. Nośniki skrzynki podłogowej są kompatybilne z systemem firmy PUK.',
        datasheets: [
          {
            name: 'Nośnik skrzynki podłogowej UT4 na maksymalnie 9 modułów',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Nośnik skrzynki podłogowej UT4 na maksymalnie 12 modułów',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'Wkładki OBO/Ackermann do systemów skrzynek podłogowych UT3, UT4, akcesoria',
        text: 'Wkładki modułów są powlekane proszkowo lub cynkowane ze względu na uziemienie. Płyty montażowe są mocowane do podstawy za pomocą dwóch śrub. Nieużywane otwory mogą być zabezpieczone przed kurzem za pomocą zaślepek',
        datasheets: [
          {
            name: 'Płyta montażowa dla 4x modułów RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Płyta montażowa dla 3x modułów RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Adapter do szyn DIN',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Kompaktowa obudowa modułu szyny DIN dla 1 modułu',
        text: 'Obudowa modułu szyny DIN dla modułów preLink<sup>®</sup> / fixLink®, przystosowany do rozdzilni bezpieczników bez pokrywy',
        datasheets: [
          {
            name: 'Kompaktowa obudowa modułu szyny DIN dla 1 modułu',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Adapter Keystone-F i zaślepka modułu pustego Keystone',
        text: 'Adapter F i zaślepka jako zamiennik modułu\nAdapter F służy do integracji kabli koncentrycznych w panelach krosowych preLink<sup>®</sup> / fixLink<sup>®</sup> oraz w programie gniazd. Zaślepka preLink<sup>®</sup>/fixLink<sup>®</sup> może być używana zarówno w polu dystrybucyjnym, jak i w gniazdach do zakrywania nieużywanych portów.\n\nModuł pusty dla SC-S lub LC-D jako zamiennik modułu\nModuł pusty dla adapterów SC-S lub LC-D w polu dystrybucyjnym lub w gniazdach.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Obudowa modułu szyny DIN dla 1 modułu',
        text: 'Obudowa modułu szyny DIN dla mod ułów preLink<sup>®</sup> / fixLink®, przystosowany do rozdzilnibezpieczników wzposażonych w pokrywe',
        datasheets: [
          {
            name: 'Obudowa modułu szyny DIN dla 1 modułu',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Patch panele',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1HE dla 24 modułów Keystone, wyjście proste',
        text: 'Patch Panel 19" 1HE może być wyposarzony maksymalnie w 24 moduły preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone. Część przednia zostałą skonstruowana w dwóch poziomach, dzięki czemu moduły są zlicowane z panelem przednim. Panel można zamówić w wersji ze stali nierdzewnej lub lakierowany metodą proszkową. Elementy montażowe i moduły Keystone nie są dołączone do zestawu.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1HE dla 48 modułów Keystone, wyjście proste',
        text: 'Patch Panel 19" 1HE może być wyposarzony w maksymalnie 48 fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 Keystone modułów. Część przednia zostałą skonstruowana w dwóch poziomach, dzięki czemu moduły są zlicowane z panelem przednim. Panel można zamówić w wersji ze stali nierdzewnej lub lakierowany metodą proszkową. Elementy montażowe i moduły Keystone nie są dołączone do zestawu.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0,5HE dla 24 modułów Keystone, wyjście proste',
        text: 'Patch Panel 19" 0,5HE preLink<sup>®</sup> / fixLink<sup>®</sup> może być wyposażone w do 24 modułów RJ45 Keystone fixLink®. Moduły są wyrównane z przednią płytą.\n\nElementy montażowe i moduły Keystone nie są dołączone do zestawu.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1HE dla 24 modułów Keystone, z oknem etykiety',
        text: 'Patch Panel 19" 1HE może być wyposażone w do 24 modułów RJ45 Keystone preLink<sup>®</sup> / fixLink®.Część przednia zostałą skonstruowana w dwóch poziomach, dzięki czemu moduły są zlicowane z panelem przednim. Panel można zamówić w wersji ze stali nierdzewnej lub lakierowany metodą proszkową. Dodatkowo istnieje możliwość oznaczenia poszczególnych portów za pomocą dołączonych, 12x33 perforowanych etykiet.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Panel kątowy 30° 19" 1HE dla 24 modułów Keystone',
        text: 'W Patch Panelu preLink<sup>®</sup> / fixLink<sup>®</sup> użytkownik ma możliwość zamontowania 24 modułów Keystone pod kątem 30°, dzięki czemu podłączone kable sieciowe automatycznie prowadzone zostają pod kątem. Dzięki temu użytkownik może zrezygnować z dodatkowych elemtów prowadzących ora z  utrzymu j ąc ych kable. Zastosowanie panelu kątowego wraz zzamontowanymi w identyczny sposóbPatchcordem pod kątem, oszczędza w sposóbznaczący miejsce w porównaniu dotradycyjnych rozwiązań. Elementy montażowe imoduły Keystone nie są dołączone do zestawu.',
      },
      baugruppentraeger: 'Nośnik modułów',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Nośnik modułów 19" 3HE do użycia z modułami wtykowymi',
        text: 'Nośnik modułów 19" 3HE do użycia z modułami wtykowymi 7TE w technologii miedzianej i światłowodowej. Są one mocowane do nośnika modułów za pomocą dwóch śrub od strony panelu. Moduły wtykowe można całkowicie wysunąć z nośnika modułów. Można zamocować maksymalnie 12 modułów wtykowych 7TE w nośniku modułów.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Moduł wtykowy Keystone dla nośnika modułów',
        text: 'Moduł wtykowy Keystone do montażu w nośnikach modułów. Moduł wtykowy Keystone można wyposażyć w do 6 modułów RJ45 Keystone preLink<sup>®</sup> / fixLink<sup>®</sup>. Moduł wtykowy Keystone jest dwupoziomowy, dzięki czemu moduły są wyrównane z przednią płytą. Mocowanie kabla odbywa się bezpośrednio na obudowie modułu wtykowego. Można zamocować 6 pojedynczych kabli lub 1 kabel trunkingowy.\n\nMateriał montażowy i moduły Keystone nie są dołączone do zestawu.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Nośnik modułów (BGT) 19" 1HE do użycia z modułami wtykowymi',
        text: 'Nośnik modułów (BGT) do użycia z modułami wtykowymi 7TE w technologii miedzianej i światłowodowej. Są one mocowane do nośnika modułów za pomocą dwóch śrub od strony panelu. Moduły wtykowe można całkowicie wysunąć z nośnika modułów. Można zamocować maksymalnie 3 moduły wtykowe 7TE (=18 portów) w nośniku modułów.\n\nMateriał montażowy i moduły Keystone nie są dołączone do zestawu.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Nośnik modułów boczny 3HE/14TE do użycia z modułami wtykowymi',
        text: 'Nośnik modułów boczny (S-BGT) do użycia z modułami wtykowymi 7TE w technologii miedzianej i światłowodowej. Moduły wtykowe są mocowane do nośnika modułów za pomocą dwóch śrub od strony panelu. Moduły wtykowe można całkowicie wysunąć z nośnika modułów. Można zamocować maksymalnie 2 moduły wtykowe 7TE (=12 portów) w nośniku modułów bocznych. Mocowanie nośnika modułów bocznych (S-BGT) odbywa się na bocznym mocowaniu szafy. Dzięki temu możliwy jest łatwy i szybki montaż. Dzięki bocznemu montażowi szafy można zrealizować oszczędne i przyjazne dla klimatu okablowanie bez zakłócania przepływu powietrza.\n\nMateriał montażowy i moduły Keystone nie są dołączone do zestawu.',
      },
      consolidation_point_panel: 'Panel do montażu i konsolidacji punktów, szyny DIN itp.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Panel punktu konsolidacji podłogowy 19" lub 10" 1HE',
        text: 'Panel punktu konsolidacji podłogowy z wymiennymi przednimi płytami do montażu różnych złączy w technologii miedzianej i światłowodowej. Opcjonalnie panel punktu konsolidacji podłogowy można zabezpieczyć zamkiem przed nieuprawnionym dostępem.\n\nMateriał montażowy i moduły Keystone nie są dołączone do zestawu.',
        datasheets: [
          {
            name: 'Panel punktu konsolidacji 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Panel punktu konsolidacji 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Panel punktu konsolidacji do 4-12 modułów Keystone',
        text: 'Panel punktu konsolidacji preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone, słuzy bezpośredniego montażu przy użyciu szyn DIN w rozdzielnicach elektrycznych. Część przednia zostałą skonstruowana w dwóch poziomach, dzięki czemu moduły są zlicowane z panelem przednim.\n\nElementy montażowe i moduły Keystone nie są dołączone do zestawu.',
      },
      trunkkabel: 'Kabel trunkingowy',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'SL CTBC System podziału Copper-Trunk-Bundled-Cable, Klasa EA ISO/IEC',
        text: 'System podziału CTBC jest odpowiedni do elastycznego użycia w strukturze okablowania budynku i w centrach danych. Kable zebrane w wężu plecionym są fabrycznie wyposażone w moduły Keystone fixLink<sup>®</sup> i testowane. Wiązki można realizować zarówno z kablami instalacyjnymi, jak i elastycznymi. Maksymalna długość kabla z wiązką wynosi do 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'SL CTCC System podziału Copper-Trunk-Compact-Cable, Klasa EA ISO/IEC',
        text: 'System podziału CTCC jest odpowiedni do elastycznego użycia w strukturze okablowania budynku i w centrach danych ze względu na minimalną średnicę kabla i odpowiednio niski ładunek ogniowy. Maksymalna długość kabla wielokrotnego wynosi od 60 do 90 m, w zależności od typu. System podziału jako rozwiązanie 10-Gbit dla okablowania centrów danych spełnia co najmniej wymagania klasy EA.',
      },
      hds_innengabel: 'Kabel wewnętrzny H.D.S.',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'Kabel wewnętrzny H.D.S. Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'Kabel wewnętrzny H.D.S. Kat. 7 S/FTP do prostego okablowania między szafami w centrach danych i wielokrotnych połączeń między pomieszczeniami dystrybucyjnymi a podrozdzielniami. Konstrukcja kabla składa się z 24 elementów kabla ekranowanego, z których każdy 4 pary są zebrane w jeden element wiązki. Każdy element wiązki jest oznaczony numerem dla łatwiejszej identyfikacji. Elastyczny centralny element napinający chroni kabel szczególnie dobrze podczas wciągania. Płaszcz wykonany jest z elastycznego materiału LSOH.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'Kabel wewnętrzny H.D.S. Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'Kabel wewnętrzny H.D.S. Kat. 7 S/FTP do prostego okablowania między szafami w centrach danych i wielokrotnych połączeń między pomieszczeniami dystrybucyjnymi a podrozdzielniami. Konstrukcja kabla składa się z 24 elementów kabla ekranowanego, z których każdy 4 pary są zebrane w jeden element wiązki. Każdy element wiązki jest oznaczony numerem dla łatwiejszej identyfikacji. Elastyczny centralny element napinający chroni kabel szczególnie dobrze podczas wciągania. Płaszcz wykonany jest z elastycznego materiału LSOH.',
      },
      generic: {
        download: 'Pobierz',
      },
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Ochrona danych',
  },

  footer: {
    dataProtection: 'Ochrona danych',
    imprint: 'Impressum',
    contact: 'Kontakt',
  },

  UI: {
    autocomplete: {
      searchError: 'Nie można przeprowadzić wyszukiwania.',
      searchEmpty: 'Brak wyników',
    },
    dropdown: {
      emptySelection: '--- proszę wybrać ---',
    },
  },
}

export const langKey = 'PL'

const Default = {
  translation,
  langKey,
}

export default Default
