export const translation = {
  header: {
    items: [
      {
        name: 'الصفحة الرئيسية',
        href: '/',
      },
      {
        name: 'النظام',
        href: '#system',
      },
      {
        name: 'وحدة',
        href: '#module',
      },
      {
        name: 'التثبيت',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'اللغة',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'البساطة',
        text2: 'في الاتصال',
      },
      subtitle: 'رابطك للمستقبل',
      cta: 'المزيد',
    },
    handling: {
      title: 'التعامل البديهي يلتقي مع التكنولوجيا المتقدمة',
      vorreiterPrefix: 'يجعل الجمع بين الدقة والتصميم الوظيفي ',
      vorreiterSuffix: ' الخيار الرائد في تكنولوجيا الاتصال.',
      experte: ' يوفر حلول 360° فعالة تجمع بين التصميم والأداء لتحقق تقليل التعقيد.',
      pionier: ' رائد في إعادة تشكيل بيئات العمل، يجمع بين الخبرة والتعامل البديهي.',
    },
    download: {
      prefix: 'نظرة عامة ',
      suffix: ' - تنزيل النظام',
      cta: 'تحميل',
      href: 'https://fixlink.eu/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'نظرة عامة على المنتج',
      title: 'نظام FIXLINK<sup>®</sup>',
    },
    module: {
      overview: 'تنزيل نظرة عامة',
      accordion: {
        ghmtCertificates: {
          title: 'شهادات GHMT',
          text: 'نحن نعتمد على GHMT وبرنامج التحقق المتميز الخاص بها لضمان جودة منتجات شبكتنا بشكل مستقل. هكذا نضمن، استنادًا إلى 20 عامًا من الخبرة، أعلى معايير الجودة لعملائنا.',
        },
        datasheets: {
          title: 'أوراق البيانات',
          text: 'هنا ستجد جميع أوراق البيانات الضرورية. قم بتنزيلها واحتفظ بجميع المعلومات في نظرة واحدة.',
        },
        compatibilitySheets: {
          title: 'قوائم التوافق',
          text: '',
        },
        excelTemplates: {
          title: 'نافذة التسمية كقالب Excel',
          text: '',
        },
      },
    },
    video: {
      title: 'دليل التركيب',
      text: 'شاهد الفيديو أو قم بتنزيل ملف PDF هنا ببساطة',
      manual: {
        title: 'أدلة التركيب:',
      },
    },
    separator: {
      title: 'أكثر من مجرد اتصال',
      title2: 'اكتشف عالم EasyLan',
      text: 'اكتشف مجموعة متنوعة من حلول الشبكة المبتكرة على EasyLan.de: مخصصة لك. مغير اللعبة لشبكتك: أنظمة الكابلات وحلول إدارة الكابلات لدينا.',
      cta: 'المزيد',
    },
    combination: {
      bau: {
        prefix: 'تجربة التكيف الأقصى والجودة،',
        text: ' حاملي المكونات من EasyLan ',
        suffix: 'مباشرة من أوروبا.',
      },
      intelligent: {
        prefix: 'شبكتك',
        text: ' مصممة خصيصا ',
        suffix: 'مع صندوق التوزيع القابل للتكوين EasyLan',
      },
      fixlink: {
        prefix: 'مع منتجاتنا ',
        suffix: 'يمكنك تحسين مباني مكتبك على المدى الطويل.',
      },
      hds: {
        prefix: 'النظام',
        text: ' EasyLan<sup>®</sup> H.D.S. - ',
        suffix: 'يجمع بين سهولة التركيب والصيانة مثل أي نظام توصيل رئيسي آخر في السوق.',
      },
    },
    recommended: {
      titleLabel: 'موصى به',
      title: 'نظام fixLink<sup>®</sup>',
      text: 'لوريم إيبسوم دولور سيت أميت، كونسيكتيتور أديبيسشينغ إليت. أينيان كومودو ليغولا إيغيت دولار. أينيان ماسا. كوم سوسيس ناتوك بيناتيبوس إت ماجنس ديس بارتيورينت مونتس، ناسيتور ريديكولوس موس.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'لوريم إيبسوم دولور ميت' },
        { imgName: 'b', name: 'FIXLINK B', text: 'لوريم إيبسوم دولور ميت' },
        { imgName: 'c', name: 'FIXLINK C', text: 'لوريم إيبسوم دولور ميت' },
        { imgName: 'd', name: 'FIXLINK D', text: 'لوريم إيبسوم دولور ميت' },
        { imgName: 'e', name: 'FIXLINK E', text: 'لوريم إيبسوم دولور ميت' },
      ],
    },
    guarantee: [
      {
        title: 'ضمان المنتج',
        text: [
          'يشمل ضمان المنتج جميع مكونات ',
          '- نظام الكابلات الألياف البصرية أو النحاس، التي تم تركيبها واختبارها من قبل فني مؤهل وفقًا لمتطلبات ',
          ' وكذلك معايير التركيب السارية EN50174.',
        ],
      },
      {
        title: 'ضمان الأداء',
        text: [
          'يضمن ضمان الأداء، بالإضافة إلى التطبيق (Ethernet, Fiber Channel, ATM, etc.) خصائص النظام بما في ذلك سرعات النقل (10MBit, 100MBit, 1GBit و 10GBit).',
        ],
      },
      {
        title: 'ضمان التطبيق',
        text: [
          'يضمن ضمان التطبيق جميع التطبيقات المحددة (Ethernet, Fiber Channel, ATM, etc.) التي تعتمد على معايير الكابلات السارية في وقت التركيب وفقًا لـ ISO/IEC 11801, EN50173 و EIA/TIA 568B.',
        ],
      },
    ],
    end: {
      title: 'الاتصالات لم تكن أسهل من قبل.',
      text: 'التعامل البديهي يلتقي مع التكنولوجيا المتقدمة؛ اكتشف الاتصال السلس للمستقبل: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'وحدات الاتصال',
      csm_FixLink_Pro03_b20813b518: {
        name: 'وحدة Keystone RJ45 Pro الفئة 6A ISO/IEC، محمية',
        text: 'مع وحدة Keystone RJ45 Pro من fixLink<sup>®</sup> يحصل المستخدم على وحدة RJ45 ذات جودة عالية. يمكن تركيب وحدة Keystone RJ45 Pro من fixLink<sup>®</sup> باستخدام قاطع جانبي في أقل من دقيقة. يضمن الوصول السهل إلى منطقة التوصيل جودة تركيب مثالية وأداء نقل مضمون. توفر الهندسة الخاصة للاتصال مع طلاء ذهبي 30 ميكرون 4PPoE وفقًا لـ IEEE 802.3bt تحت جميع الظروف. يتكون هيكل الوحدة من زنك مصبوب مغلف بالنيكل وبالتالي يفي بأعلى متطلبات البيئة والمتطلبات الميكانيكية. مع حاملي الوحدات القابلين للتصميم، يمكن دمج الوحدة مع جميع برامج التبديل. يتم تسليمها في عبوات كرتونية صديقة للبيئة.',
        ghmtCertificates: [
          {
            name: 'شهادة PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'شهادة Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'موسع الفئة 6A ISO/IEC، محمي',
        text: 'مع موسع fixLink<sup>®</sup> يمكن تمديد الكابلات بسهولة دون الكثير من الجهد. بالإضافة إلى ذلك، يمكن إعادة تفعيل الكابلات المتوقفة وتمديدها إلى أقرب نقطة اتصال مطلوبة. يتم الاستفادة بشكل أمثل من المساحة الضيقة في قناة الكابلات. يتم تثبيت الموسع ببساطة في نهاية الكابل المثبت في القناة أو على الحائط. بعد التركيب، يمكن استخدام الكابل الممتد حتى طول الرابط الأقصى لنقل 10 جيجابت إيثرنت ويدعم PoE+ (الطاقة عبر الإيثرنت).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'قابس RJ45 الفئة 6A ISO/IEC، محمي',
        text: 'مع وحدة قابس RJ45 من fixLink<sup>®</sup> يحصل المستخدم على وحدة قابس RJ45 قابلة للتجميع في الميدان بأعلى جودة ومرونة. توفر الهندسة الخاصة للاتصال مع طلاء ذهبي 50 ميكرون 4PPoE وفقًا لـ IEEE 80.3bt تحت جميع الظروف. يتكون هيكل الوحدة من زنك مصبوب مغلف بالنيكل وبالتالي يفي بأعلى متطلبات البيئة والمتطلبات الميكانيكية. تكمل العديد من الشهادات من مختبرات الاختبار المستقلة أعلى متطلبات الجودة لنظام fixLink<sup>®</sup>.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'وحدة Keystone RJ45 الفئة 6A EIA/TIA، غير محمية',
        text: 'مع وحدة Keystone RJ45 من fixLink<sup>®</sup> (PoE+ متوافق) يحصل المستخدم على وحدة RJ45 ذات جودة عالية. يمكن تركيب وحدة Keystone RJ45 من fixLink<sup>®</sup> باستخدام قاطع جانبي في أقل من دقيقة. يضمن الوصول السهل إلى منطقة التوصيل جودة تركيب مثالية وأداء نقل مضمون. يتكون هيكل الوحدة من بولي كربونات ويفي بأعلى متطلبات البيئة والمتطلبات الميكانيكية. مع حاملي الوحدات القابلين للتصميم، يمكن دمج الوحدة مع جميع برامج التبديل. تكمل العديد من الشهادات من مختبرات الاختبار المستقلة أعلى متطلبات الجودة لوحدة Keystone fixLink<sup>®</sup> SL. يتم تسليمها في عبوات كرتونية صديقة للبيئة.',
      },
      fixLink_SL_02: {
        name: 'وحدة Keystone RJ45 SL الفئة 6A ISO/IEC، محمية',
        text: 'مع وحدة Keystone RJ45 SL من fixLink<sup>®</sup> يحصل المستخدم على وحدة RJ45 ذات جودة عالية. يمكن تركيب وحدة Keystone RJ45 SL من fixLink<sup>®</sup> باستخدام قاطع جانبي في أقل من دقيقة. يضمن الوصول السهل إلى منطقة التوصيل جودة تركيب مثالية وأداء نقل مضمون. لإزالة الشيلد بزاوية 360 درجة، يتم قفل الوتد المدمج، مما يلغي الحاجة إلى تركيب رباط الكابل الذي يستغرق وقتًا. توفر الهندسة الخاصة للاتصال مع طلاء ذهبي 50 ميكرون 4PPoE وفقًا لـ IEEE 802.3bt تحت جميع الظروف. يتكون هيكل الوحدة من زنك مصبوب مغلف بالنيكل وبالتالي يفي بأعلى متطلبات البيئة والمتطلبات الميكانيكية. مع حاملي الوحدات القابلين للتصميم، يمكن دمج الوحدة مع جميع برامج التبديل. تكمل العديد من الشهادات من مختبرات الاختبار المستقلة أعلى متطلبات الجودة لوحدة Keystone fixLink<sup>®</sup> SL. يتم تسليمها في عبوات كرتونية صديقة للبيئة.',
        ghmtCertificates: [
          {
            name: 'شهادة PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'تقرير اختبار 4PoE وفقًا لـ DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'شهادة GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'وحدة Keystone RJ45 SL الفئة 6A ISO/IEC، محمية بزاوية',
        text: 'مع وحدة Keystone RJ45 SL من fixLink<sup>®</sup> يحصل المستخدم على وحدة RJ45 ذات جودة عالية، والتي تم تطويرها خصيصًا للتطبيقات ذات المساحة الضيقة. بفضل زاوية خروج الكابل 75 درجة، تكون الوحدة مناسبة بشكل خاص للاستخدام في صناديق البيانات وكابلات التجويف. يسمح عمق التركيب البسيط 27 مم بتنوع تطبيقات غير محدود تقريبًا. يمكن تركيب وحدة Keystone RJ45 SL من fixLink<sup>®</sup> باستخدام قاطع جانبي في أقل من دقيقة. يضمن الوصول السهل إلى منطقة التوصيل جودة تركيب مثالية وأداء نقل مضمون. توفر الهندسة الخاصة للاتصال مع طلاء ذهبي 50 ميكرون PoE+ وفقًا لـ IEEE 80.3at تحت جميع الظروف. يتكون هيكل الوحدة من زنك مصبوب مغلف بالنيكل وبالتالي يفي بأعلى متطلبات البيئة والمتطلبات الميكانيكية. مع حاملي الوحدات القابلين للتصميم، يمكن دمج الوحدة مع جميع برامج التبديل. تكمل العديد من الشهادات من مختبرات الاختبار المستقلة أعلى متطلبات الجودة لوحدة Keystone fixLink<sup>®</sup> SL. يتم تسليمها في عبوات كرتونية صديقة للبيئة.',
        ghmtCertificates: [
          {
            name: 'شهادة GHMT PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'شهادة GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'أدوات وإكسسوارات',
        text: [
          'أداة لتعرية الكابلات لإزالة الغلاف الخارجي والرقائق المعدنية لكابل البيانات. لون أزرق.',
          'شريط نحاسي لتثبيت الغلاف الواقي. مقطوع مسبقًا إلى 35 × 9 مم. وحدة التعبئة: 20 قطعة/كيس 1500 قطعة/لفة',
          'أغطية حماية من الغبار ملونة للوحدات preLink® SL وfixLink® SL Keystone.',
          'وحدة التعبئة 50 قطعة/كيس بلاستيكي',
        ],
        ghmtCertificates: [
          {
            name: 'شهادة GHMT PVP الفئة 6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'شهادة GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'كابل تركيب',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'كابل بيانات EasyLan® Gold S/FTP AWG22/1 1500 ميجاهرتز الفئة 7A',
        text: ['كابل بيانات لنقل الإشارات التناظرية والرقمية في الكابلات في المناطق الأولية والثانوية والثالثية. مناسب لتطبيقات حتى الفئة FA.'],
        ghmtCertificates: [
          {
            name: 'شهادة GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'شهادة GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'ورقة البيانات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'كابل بيانات EasyLan® Silber S/FTP AWG23/1 1200 ميجاهرتز الفئة 7A',
        text: ['كابل بيانات لنقل الإشارات التناظرية والرقمية في الكابلات في المناطق الأولية والثانوية والثالثية. مناسب لتطبيقات حتى الفئة FA.'],
        ghmtCertificates: [
          {
            name: 'شهادة GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'ورقة البيانات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'كابل بيانات EasyLan® Bronze S/FTP AWG23/1 1000 ميجاهرتز الفئة 7',
        text: 'كابل بيانات لنقل الإشارات التناظرية والرقمية في الكابلات في المناطق الأولية والثانوية والثالثية. مناسب لتطبيقات حتى الفئة FA.',
        datasheets: [
          {
            name: 'ورقة البيانات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'صناديق البيانات',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'إسكان وإطار للتثبيت السطحي للوحدات المذكورة أعلاه',
        text: [
          'إسكان سطحي IP20، 80x80x42mm لتثبيت وحدات EasyLan',
          'إطار بيني لإسكان السطح لتوسيع الارتفاع بمقدار 10mm',
          'إطار تغطية واحد 80x80mm',
          'إطار تغطية مزدوج 80x150mm',
        ],
        datasheets: [
          {
            name: 'إسكان السطح',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'توسيع العمق لإسكان السطح',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'وحدة تثبيت SL، 45° خروج UP، BK، AP، BT واحد، مزدوج، ثلاثي',
        text: 'تعد وحدات تثبيت SL مناسبة بشكل خاص للتثبيت في قناة الكابلات (EK)، التثبيت المدمج (UP) والتثبيت السطحي (AP). من خلال كسر اثنين من لوحات التثبيت، يمكن أيضًا تركيبها في خزانة الأرضية (BT). يمكن لوحدة التثبيت SL استيعاب ما يصل إلى ثلاثة مفاتيح EasyLan Keystone بأمان وثبات. تحتوي على نافذة مركزية كبيرة للتسمية لسهولة التحديد. تتكون اللوحة المركزية (50x50 مم) والإطار (80x80 مم) من بلاستيك مقاوم للأشعة فوق البنفسجية وقوي للغاية. هذا يضمن مظهرًا دائمًا وصادقًا.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'وحدة تثبيت تصميمية SL، 45° خروج UP، BK، AP، BT واحد، مزدوج',
        text: 'تتكون وحدة التثبيت التصميمية من إطار زنك مصبوب. إنها مناسبة للتثبيت في قناة الكابلات (EK)، التثبيت المدمج (UP) أو باستخدام إطار منفصل أيضًا للتثبيت السطحي (AP). من خلال كسر اثنين من لوحات التثبيت، يمكن أيضًا تركيبها في خزانة الأرضية (BT). يمكن دمج وحدة البيانات التصميمية مع جميع برامج التبديل المتاحة في السوق تقريبًا. نظرًا للتوافق مع UAE، لا توفر وحدة البيانات التصميمية مساحة لغطاء الحماية من الغبار.',
        datasheets: [
          {
            name: 'Keystone واحد / مزدوج، بدون لوحة مركزية',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: 'Keystone مزدوج مع لوحة مركزية 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: 'Keystone واحد مع لوحة مركزية',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'وحدة تثبيت IP44 سطحية، واحد، مزدوج',
        text: 'يعد الإسكان السطحي المتين مناسبًا لتثبيت وحدتين من وحدات preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone باستخدام محول. يوجد في الجزء الخلفي من الوحدة مكانان يمكن اختراقهما بسهولة لإدخال كابلين بقطر 5.0 - 9.5 ملم. تحقق وحدة التثبيت مستوى الحماية IP20 في حالة التوصيل، ومستوى الحماية IP44 في حالة الإغلاق.',
      },
      DoseUPleer2xKS: {
        name: 'وحدة تثبيت بزاوية مستقيمة، مزدوجة',
        text: 'تتكون وحدة التثبيت بزاوية مستقيمة من إطار زنك مصبوب وتناسب التثبيت في قناة الكابلات (EK)، التثبيت المدمج (UP) أو باستخدام إطار منفصل أيضًا للتثبيت السطحي (AP).',
      },
      bodentank: 'خزانة الأرضية',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'أنظمة خزانات الأرضية OBO/Ackermann UT3, UT4',
        text: 'تحل لوحة الحامل محل حاملات الأجهزة وتوفر بالتالي أكبر مساحة ممكنة لتوجيه الكابلات. يسمح التوجيه المائل للإدخال والإخراج بتوجيه الكابلات بأمان حتى في أدنى ارتفاعات الأرضية. من المتوقع الجمع بين لوحات التثبيت لأربعة وحدات preLink<sup>®</sup>/fixLink<sup>®</sup> أو وحدة H.D.S. واحدة. يتم تثبيت لوحات التثبيت بلوحتين من البراغي على لوحة الحامل، يمكن تغطية الحقول غير المستخدمة بلوحات عمياء لحماية من الغبار.',
        datasheets: [
          {
            name: 'لوحة الحامل لـ UT3 بدون لوحة تثبيت',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'لوحة الحامل لـ UT4 بدون لوحة تثبيت',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'حامل خزان الأرضية OBO/Ackermann Basic UT3, UT4',
        text: 'تحل حاملة خزان الأرضية من Keystone محل حاملة الجهاز وتوفر بالتالي أكبر مساحة ممكنة لتوجيه الكابلات. يسمح التوجيه المائل للإدخال والإخراج بتوجيه الكابلات بأمان حتى في أدنى ارتفاعات الأرضية. يتم تثبيت لوحات التثبيت بلوحتين من البراغي على لوحة الحامل، يمكن تغطية الحقول غير المستخدمة لحاملة خزان الأرضية بلوحات عمياء لحماية من الغبار. باستخدام محول، يمكن أيضًا استخدام حاملة خزان الأرضية لحاملات أجهزة Electraplan.',
        datasheets: [
          {
            name: 'حامل خزان الأرضية UT3 لما يصل إلى 6 وحدات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'حامل خزان الأرضية UT4 لما يصل إلى 9 وحدات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'حامل خزان الأرضية UT4 لما يصل إلى 12 وحدة',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'حامل خزان الأرضية PUK Basic UT4',
        text: 'تحل حاملة خزان الأرضية من Keystone محل حاملة الجهاز وتوفر بالتالي أكبر مساحة ممكنة لتوجيه الكابلات. يسمح التوجيه المائل للإدخال والإخراج بتوجيه الكابلات بأمان حتى في أدنى ارتفاعات الأرضية. يتم تثبيت لوحات التثبيت بلوحتين من البراغي على لوحة الحامل، يمكن تغطية الحقول غير المستخدمة لحاملة خزان الأرضية بلوحات عمياء لحماية من الغبار. حاملة خزان الأرضية متوافقة مع نظام خزانات الأرضية PUK.',
        datasheets: [
          {
            name: 'حامل خزان الأرضية UT4 لما يصل إلى 9 وحدات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'حامل خزان الأرضية UT4 لما يصل إلى 12 وحدة',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'وحدات OBO/Ackermann لنظام خزانات الأرضية UT3, UT4، ملحقات',
        text: 'تم تركيبات الوحدات لأسباب التأريض دون طلاء ومسحوق ومجلفنة. يتم تثبيت لوحات التثبيت بلوحتين من البراغي على لوحة الحامل، يمكن تغطية الحقول غير المستخدمة بلوحات عمياء لحماية من الغبار.',
        datasheets: [
          {
            name: 'لوحة التثبيت لـ 4 وحدات RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'لوحة التثبيت لـ 3 وحدات RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'محول السكك الحديدية DIN',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'وحدة محول السكك الحديدية DIN المدمجة لوحدة واحدة',
        text: 'وحدة محول السكك الحديدية DIN لوحدات preLink<sup>®</sup>/fixLink<sup>®</sup>، مناسبة لخزائن التوزيع بدون غطاء لقواطع الدوائر.',
        datasheets: [
          {
            name: 'وحدة محول السكك الحديدية DIN المدمجة لوحدة واحدة',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'محول Keystone-F وغطاء عمياء لوحدة Keystone',
        text: 'محول F وغطاء عمياء كبديل للوحدة. يستخدم محول F لتكامل كابلات الكوكس في لوحات التصحيح preLink<sup>®</sup>/fixLink<sup>®</sup> وفي برنامج الوحدات. يمكن استخدام غطاء العمياء لوحدة preLink<sup>®</sup>/fixLink<sup>®</sup> في مجال التوزيع وكذلك في الوحدات لتغطية المنافذ غير المستخدمة. وحدة فارغة لـ SC-S أو LC-D كبديل للوحدة. وحدة فارغة لموصلات SC-S أو LC-D في مجال التوزيع أو الوحدة.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'وحدة محول السكك الحديدية DIN لوحدة واحدة',
        text: 'وحدة محول السكك الحديدية DIN لوحدات preLink<sup>®</sup>/fixLink<sup>®</sup>، مناسبة لخزائن التوزيع بغطاء لقواطع الدوائر.',
        datasheets: [
          {
            name: 'وحدة محول السكك الحديدية DIN لوحدة واحدة',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'لوحة التوزيع',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'لوحة التوزيع 19" 1HE لـ 24 وحدة Keystone بخروج مستقيم',
        text: 'يمكن توصيل لوحة التوزيع 19" 1HE بما يصل إلى 24 وحدة preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone. وهي مبنية على مستويين، وبالتالي تكون الوحدات مدمجة في اللوحة الأمامية. يمكن طلب لوحة التوزيع إما من الفولاذ المقاوم للصدأ أو من الصلب المغلف بالمسحوق. لا يتم تضمين مواد التثبيت ووحدات Keystone في الحزمة.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'لوحة التوزيع 19" 1HE لـ 48 وحدة Keystone بخروج مستقيم',
        text: 'يمكن توصيل لوحة التوزيع 19" 1HE بما يصل إلى 48 وحدة fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 Keystone. وهي مبنية على مستويين، وبالتالي تكون الوحدات مدمجة في اللوحة الأمامية. تتكون لوحة التوزيع من الصلب المغلف بالمسحوق. لا يتم تضمين مواد التثبيت ووحدات Keystone في الحزمة.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'لوحة التوزيع 19" 0.5HE لـ 24 وحدة Keystone بخروج مستقيم',
        text: 'يمكن توصيل لوحة التوزيع preLink<sup>®</sup>/fixLink<sup>®</sup> 19" 0.5HE بما يصل إلى 24 وحدة fixLink<sup>®</sup> RJ45 Keystone. وهي مدمجة في اللوحة الأمامية. لا يتم تضمين مواد التثبيت ووحدات Keystone في الحزمة.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'لوحة التوزيع 19" 1HE لـ 24 وحدة Keystone مع نافذة التسمية',
        text: 'يمكن توصيل لوحة التوزيع 19" 1HE بما يصل إلى 24 وحدة preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone. وهي مبنية على مستويين، وبالتالي تكون الوحدات مدمجة في اللوحة الأمامية. يمكن طلب لوحة التوزيع إما من الفولاذ المقاوم للصدأ أو من الصلب المغلف بالمسحوق. بالإضافة إلى ذلك، يمكن تمييز المنافذ الفردية باستخدام ورقة التسمية المثقبة 12x33 المرفقة.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'لوحة بزاوية 30° 19" 1HE لـ 24 وحدة Keystone',
        text: 'مع لوحة بزاوية 30° preLink<sup>®</sup>/fixLink<sup>®</sup> يمكن للمستخدم تثبيت 24 وحدة Keystone في اللوحة بزاوية 30°، مما يجعل الكابلات المتصلة تجري تلقائيًا إلى جانب الخزانة ويجب تثبيتها فقط على الجانب بجانب مستوى التصحيح. يمكن للمستخدم الاستغناء عن لوحات التوجيه الإضافية لتوجيه الكابلات. في التشغيل، تحتاج اللوحات بزاوية مع الواجهة المسطحة والكابلات المتصلة بزاوية إلى مساحة أقل في الأمام مقارنة بلوحات التصحيح التقليدية مع المنافذ العمودية. لا يتم تضمين مواد التثبيت ووحدات Keystone في الحزمة.',
      },
      baugruppentraeger: 'حامل المكونات',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'حامل المكونات 19" 3HE لاستخدام الوحدات القابلة للإدخال',
        text: 'حامل المكونات 19" 3HE لاستخدام الوحدات القابلة للإدخال 7TE في النحاس والألياف البصرية. يتم تثبيتها بلوحتين من البراغي من الجانب الأمامي على حامل المكونات. يمكن إخراج الوحدات القابلة للإدخال بالكامل من حامل المكونات. يمكن تثبيت ما يصل إلى 12 وحدة إدخال 7TE في حامل المكونات.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'وحدة إدخال Keystone لحامل المكونات',
        text: 'وحدة إدخال Keystone لتجهيز حامل المكونات. يمكن تجهيز وحدة إدخال Keystone بما يصل إلى 6 وحدات preLink<sup>®</sup> أو fixLink<sup>®</sup> Keystone. يتم بناء وحدة إدخال Keystone على مستويين، وبالتالي تكون الوحدات مدمجة في اللوحة الأمامية. يتم تثبيت الكابلات مباشرة على هيكل وحدة الإدخال. يمكن تثبيت 6 كابلات فردية أو كابل جذع واحد. لا يتم تضمين مواد التثبيت ووحدات Keystone في الحزمة.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'حامل الوحدات (BGT) 19" 1HE لاستخدام الوحدات القابلة للإدخال',
        text: 'حامل الوحدات (BGT) لاستخدام الوحدات القابلة للإدخال 7TE في النحاس والألياف البصرية. يتم تثبيتها بلوحتين من البراغي من الجانب الأمامي على حامل الوحدات. يمكن إخراج الوحدات القابلة للإدخال بالكامل من حامل الوحدات. يمكن تثبيت ما يصل إلى 3 وحدات إدخال 7TE (=18 منفذ) في حامل الوحدات. لا يتم تضمين مواد التثبيت ووحدات Keystone في الحزمة.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'حامل الوحدات الجانبي 3HE/14TE لاستخدام الوحدات القابلة للإدخال',
        text: 'حامل الوحدات الجانبي (S-BGT) لاستخدام الوحدات القابلة للإدخال 7TE في النحاس والألياف البصرية. يتم تثبيت الوحدات القابلة للإدخال بلوحتين من البراغي من الجانب الأمامي على حامل الوحدات. يمكن إخراج الوحدات القابلة للإدخال بالكامل من حامل الوحدات. يمكن تثبيت ما يصل إلى 2 وحدات إدخال 7TE (=12 منفذ) في حامل الوحدات. يتم تثبيت حامل الوحدات الجانبي (S-BGT) في فتحة الخزانة الجانبية. هذا يتيح تركيبًا سهلاً وسريعًا. يتيح تركيب الكابلات الجانبي تحقيق توصيل كابلات موفر للمساحة وصديق للبيئة دون تعطيل تدفق الهواء. لا يتم تضمين مواد التثبيت ووحدات Keystone في الحزمة.',
      },
      consolidation_point_panel: 'لوحة Consolidation Point، DIN Rail وغيرها',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'لوحة Consolidation Point السفلية 19" أو 10" 1HE',
        text: 'لوحة Consolidation Point السفلية مع ألواح أمامية قابلة للتبادل لاستيعاب مجموعة متنوعة من التوصيلات في النحاس والألياف البصرية. يمكن تأمين لوحة Consolidation Point السفلية بشكل اختياري بقفل لمنع الوصول غير المصرح به. لا يتم تضمين مواد التثبيت ووحدات Keystone في الحزمة.',
        datasheets: [
          {
            name: 'لوحة Consolidation Point 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'لوحة Consolidation Point 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'لوحة Consolidation Point السطحية لـ 4-12 وحدة Keystone',
        text: 'لوحة Consolidation Point السطحية لوحدات preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone، للاستخدام كلوحة Consolidation، التثبيت المباشر أو باستخدام مجموعة التثبيت على السكك الحديدية DIN في توزيعات الكهرباء. يتم بناء لوحة Consolidation Point السطحية على مستويين. وبالتالي تكون الوحدات مدمجة في اللوحة الأمامية. لا يتم تضمين مواد التثبيت ووحدات Keystone في الحزمة.',
      },
      trunkkabel: 'كابل الجذع',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'نظام تقسيم CTBC للنحاس، فئة EA ISO/IEC',
        text: 'يوفر نظام تقسيم CTBC مرونة في الاستخدام في الكابلات المهيكلة للمباني ومراكز البيانات. يتم تجميع الكابلات في أنبوب ضفيرة ويتم تجميعها وفحصها مسبقًا باستخدام وحدات Keystone fixLink<sup>®</sup>. يمكن تجميعها بكابلات تركيب أو كابلات مرنة. يبلغ الطول الأقصى للكابل المجمع حتى 20 مترًا.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'نظام تقسيم CTCC للنحاس، فئة EA ISO/IEC',
        text: 'يوفر نظام تقسيم CTCC بفضل قطره الصغير وحمله الحراري المنخفض مرونة في الاستخدام في الكابلات المهيكلة للمباني ومراكز البيانات. يبلغ الطول الأقصى للكابل المتعدد حسب النوع 60-90 مترًا. يلبي نظام التقسيم كحل 10 جيجابت لمراكز البيانات على الأقل متطلبات الفئة EA.',
      },
      hds_innengabel: 'كابل H.D.S. الداخلي',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'كابل H.D.S. الداخلي الفئة 7، 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'كابل H.D.S. الداخلي الفئة 7 S/FTP لتوصيل الخزائن بالخزائن في مراكز البيانات والتوصيلات المتعددة من غرف التوزيع إلى التوزيعات الفرعية. يتكون الكابل من 24 عنصرًا محميًا، يتم تجميع كل 4 أزواج في عنصر جديلة. يتم تزويد كل عنصر جديلة بشريط أرقام لسهولة التعريف. يوفر عنصر التخفيف المركزي المرن حماية إضافية للكابل عند السحب. يتكون الغلاف من مادة LSOH المرنة.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'كابل H.D.S. الداخلي الفئة 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'كابل H.D.S. الداخلي الفئة 7 S/FTP لتوصيل الخزائن بالخزائن في مراكز البيانات والتوصيلات المتعددة من غرف التوزيع إلى التوزيعات الفرعية. يتكون الكابل من 24 عنصرًا محميًا، يتم تجميع كل 4 أزواج في عنصر جديلة. يتم تزويد كل عنصر جديلة بشريط أرقام لسهولة التعريف. يوفر عنصر التخفيف المركزي المرن حماية إضافية للكابل عند السحب. يتكون الغلاف من مادة LSOH المرنة.',
      },
      generic: {
        download: 'تحميل',
      },
    },
  },

  imprint: {
    headline: 'بيانات الطبع',
  },

  dataProtection: {
    headline: 'حماية البيانات',
  },

  footer: {
    dataProtection: 'حماية البيانات',
    imprint: 'بيانات الطبع',
    contact: 'اتصال',
  },

  UI: {
    autocomplete: {
      searchError: 'تعذر تنفيذ البحث.',
      searchEmpty: 'لا توجد نتائج',
    },
    dropdown: {
      emptySelection: '--- الرجاء الاختيار ---',
    },
  },
}

export const langKey = 'TN'

const Default = {
  translation,
  langKey,
}

export default Default
