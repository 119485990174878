export const translation = {
  header: {
    items: [
      {
        name: 'Domov',
        href: '/',
      },
      {
        name: 'Systém',
        href: '#system',
      },
      {
        name: 'Modul',
        href: '#module',
      },
      {
        name: 'Montáž',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Jazyk',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Jednoduchosť',
        text2: 'v spojení',
      },
      subtitle: 'Tvoj link do budúcnosti',
      cta: 'viac',
    },
    handling: {
      title: 'INTUITÍVNE OVLÁDANIE SA STRETÁVA S POKROČILOU TECHNOLÓGIOU',
      vorreiterPrefix: 'Kombinácia presnosti a funkčného dizajnu robí ',
      vorreiterSuffix: ' vedúcou voľbou v oblasti sieťových technológií.',
      experte: ' ponúka efektívne 360° riešenia, ktoré optimálne spájajú dizajn a výkon, aby sa minimalizovala zložitosť.',
      pionier: ' je priekopníkom v premenách pracovných priestorov, spájajúc odbornosť s intuitívnym ovládaním.',
    },
    download: {
      prefix: 'Prehľad ',
      suffix: ' - systém na stiahnutie',
      cta: 'Stiahnuť',
      href: 'https://fixlink.eu/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Prehľad produktu',
      title: 'FIXLINK<sup>®</sup> - SYSTÉM',
    },
    module: {
      overview: 'Stiahnite si prehľad',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT - Certifikáty',
          text: 'Spoliehame sa na GHMT a ich program Premium Verification Program pre nezávislé zabezpečenie kvality našich sieťových produktov. Takto garantujeme, na základe 20-ročnej expertízy, najvyššie produktové štandardy pre našich zákazníkov.',
        },
        datasheets: {
          title: 'Technické listy',
          text: 'Tu nájdete všetky potrebné technické listy. Stiahnite si ich a majte všetky informácie na jednom mieste.',
        },
        compatibilitySheets: {
          title: 'Zoznamy kompatibility',
          text: '',
        },
        excelTemplates: {
          title: 'Popisovacie okno ako Excel šablóna',
          text: '',
        },
      },
    },
    video: {
      title: 'NÁVOD NA MONTÁŽ',
      text: 'Pozrite si video alebo si jednoducho stiahnite PDF tu',
      manual: {
        title: 'NÁVODY NA MONTÁŽ:',
      },
    },
    separator: {
      title: 'Viac ako len spojenie',
      title2: 'Objavte svet EasyLan',
      text: 'Objavte rozmanitosť inovatívnych sieťových riešení na EasyLan.de: Individuálne prispôsobené pre vás. GameChanger pre vašu sieť: Naše systémy káblovania a riešenia pre správu káblov.',
      cta: 'viac',
    },
    combination: {
      bau: {
        prefix: 'Zažite maximálnu prispôsobivosť a kvalitu s ',
        text: ' nosičmi modulov EasyLan ',
        suffix: ' priamo z Európy.',
      },
      intelligent: {
        prefix: 'Vaša sieť',
        text: ' na mieru ',
        suffix: 's konfigurovateľnou EasyLan spojovacou skrinkou',
      },
      fixlink: {
        prefix: 'S našimi ',
        suffix: ' produktmi môžete dlhodobo zlepšiť vaše kancelárske budovy.',
      },
      hds: {
        prefix: 'Systém',
        text: ' EasyLan<sup>®</sup> H.D.S. ',
        suffix: 'spája inštalačnú a údržbovú priateľnosť ako žiadny iný systém káblovania na trhu.',
      },
    },
    recommended: {
      titleLabel: 'Odporúčané',
      title: 'fixLink<sup>®</sup> - SYSTÉM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Produktová záruka',
        text: [
          'Produktová záruka zahŕňa všetky komponenty ',
          '- optických vlákien a medených káblových systémov, ktoré boli nainštalované a testované kvalifikovaným inštalatérom v súlade s pokynmi od ',
          ' a platnými inštalačnými normami EN50174.',
        ],
      },
      {
        title: 'Výkonnostná záruka',
        text: [
          'Výkonnostná záruka garantuje okrem aplikácie (Ethernet, Fiber Channel, ATM, atď.) aj vlastnosti systému vrátane prenosových rýchlostí (10MBit, 100MBit, 1GBit a 10GBit).',
        ],
      },
      {
        title: 'Aplikačná záruka',
        text: [
          'Aplikačná záruka garantuje všetky špecifikované aplikácie (Ethernet, Fiber Channel, ATM, atď.), ktoré sú založené na platných káblových štandardoch ISO/IEC 11801, EN50173 a EIA/TIA 568B v čase inštalácie.',
        ],
      },
    ],
    end: {
      title: 'SPOJENIA NIKDY NEBOLI TAKÉ JEDNODUCHÉ.',
      text: 'Intuitívne ovládanie sa stretáva s pokročilou technológiou; objavte bezproblémové spojenie budúcnosti: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Pripojovacie moduly',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone-Modul Kat. 6A ISO/IEC, tienené',
        text: 'S modulom fixLink<sup>®</sup> Pro RJ45 Keystone užívateľ získa RJ45 zásuvný modul najvyššej kvality. Modul fixLink<sup>®</sup> Pro RJ45 Keystone môže byť nainštalovaný pomocou bočného strihu za menej ako minútu. Vďaka voľne prístupnej kontaktnej zóne je zabezpečená perfektná kvalita spojenia a tým aj zaručený prenosový výkon. Špeciálna kontaktná geometria v spojení s 30 μ" zlatým povlakom zabezpečuje 4PPoE podľa IEEE 802.3bt za všetkých podmienok. Materiál krytu je vyrobený z poniklovanej zinkovej zliatiny a spĺňa najvyššie environmentálne a mechanické požiadavky. V kombinácii s dizajnovým modulárnym držiakom je možná kombinácia so všetkými programami prepínačov. Sú dodávané v ekologickom kartónovom balení.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Permanent Link certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Kat. 6A ISO/IEC, tienený',
        text: 'S fixLink<sup>®</sup> Extenderom môžete predĺžiť káble bez veľkého úsilia. Okrem toho môžete reaktivovať nepoužívané káble a predĺžiť ich až k ďalšiemu potrebnému pripojeniu. Úzky priestor v káblovom kanáli je tak optimálne využitý. Extender je jednoducho nainštalovaný na konci nainštalovaného kábla do kanála alebo na stenu. Po inštalácii môže byť takto predĺžený kábel používaný až do maximálnej dĺžky linky aj pre 10-Gigabitové Ethernetové prenosy a podporuje PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug Kat. 6A ISO/IEC tienené',
        text: 'S modulom fixLink<sup>®</sup> RJ45 plug užívateľ získa polemontážny RJ45 zásuvný modul najvyššej kvality a flexibility. Špeciálna kontaktná geometria v spojení s 50 μ" zlatým povlakom zabezpečuje 4PPoE podľa IEEE 80.3bt za všetkých podmienok. Materiál krytu je vyrobený z poniklovanej zinkovej zliatiny a spĺňa najvyššie environmentálne a mechanické požiadavky. Rôzne certifikáty (aktuálny zoznam: www.easylan.de) od nezávislých skúšobných laboratórií dopĺňajú najvyšší nárok na kvalitu systému fixLink<sup>®</sup>.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'RJ45 Keystone-Modul Kat. 6A EIA/TIA, netienené',
        text: 'S modulom fixLink<sup>®</sup> RJ45 Keystone (PoE+ schopné) užívateľ získa RJ45 zásuvný modul najvyššej kvality. Modul fixLink<sup>®</sup> RJ45 Keystone môže byť nainštalovaný pomocou bočného strihu za menej ako minútu. Vďaka voľne prístupnej kontaktnej zóne je zabezpečená perfektná kvalita spojenia a tým aj zaručený prenosový výkon. Materiál krytu je vyrobený z polykarbonátu a spĺňa najvyššie environmentálne a mechanické požiadavky. V kombinácii s dizajnovým modulárnym držiakom je možná kombinácia so všetkými programami prepínačov. Rôzne certifikáty od nezávislých skúšobných laboratórií dopĺňajú najvyšší nárok na kvalitu fixLink<sup>®</sup> SL Keystone modulu. Sú dodávané v ekologickom kartónovom balení.',
      },
      fixLink_SL_02: {
        name: 'SL RJ45 Keystone-Modul Kat. 6A ISO/IEC, tienené',
        text: 'S modulom fixLink<sup>®</sup> SL RJ45 Keystone užívateľ získa RJ45 zásuvný modul najvyššej kvality. Modul fixLink<sup>®</sup> SL RJ45 Keystone môže byť nainštalovaný pomocou bočného strihu za menej ako minútu. Vďaka voľne prístupnej kontaktnej zóne je zabezpečená perfektná kvalita spojenia a tým aj zaručený prenosový výkon. Pre 360° tienenie je potrebné len zacvaknutie integrovaného klina, čím sa eliminuje časovo náročné pripevňovanie káblovej svorky. Špeciálna kontaktná geometria v spojení s 50 μ" zlatým povlakom zabezpečuje 4PPoE podľa IEEE 802.3bt za všetkých podmienok. Materiál krytu je vyrobený z poniklovanej zinkovej zliatiny a spĺňa najvyššie environmentálne a mechanické požiadavky. V kombinácii s dizajnovým modulárnym držiakom je možná kombinácia so všetkými programami prepínačov. Rôzne certifikáty od nezávislých skúšobných laboratórií dopĺňajú najvyšší nárok na kvalitu fixLink<sup>®</sup> SL Keystone modulu. Sú dodávané v ekologickom kartónovom balení.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Správa o skúške 4PoE podľa DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'SL RJ45 Keystone-Modul uhlový Kat. 6A ISO/IEC, tienené',
        text: 'S modulom fixLink<sup>®</sup> SL RJ45 Keystone užívateľ získa RJ45 zásuvný modul najvyššej kvality, ktorý bol špeciálne vyvinutý pre aplikácie s obmedzeným priestorom. Vďaka 75° odklonu kábla je modul ideálny pre použitie v dátových zásuvkách a dutinových káblovaniach. Minimálna inštalačná hĺbka 27 mm umožňuje takmer neobmedzené použitie. Modul fixLink<sup>®</sup> SL RJ45 Keystone môže byť nainštalovaný pomocou bočného strihu za menej ako minútu. Vďaka voľne prístupnej kontaktnej zóne je zabezpečená perfektná kvalita spojenia a tým aj zaručený prenosový výkon. Špeciálna kontaktná geometria v spojení s 50 μ" zlatým povlakom zabezpečuje PoE+ podľa IEEE 80.3at za všetkých podmienok. Materiál krytu je vyrobený z poniklovanej zinkovej zliatiny a spĺňa najvyššie environmentálne a mechanické požiadavky. V kombinácii s dizajnovým modulárnym držiakom je možná kombinácia so všetkými programami prepínačov. Rôzne certifikáty od nezávislých skúšobných laboratórií dopĺňajú najvyšší nárok na kvalitu fixLink<sup>®</sup> SL Keystone modulu. Sú dodávané v ekologickom kartónovom balení.',
        ghmtCertificates: [
          {
            name: 'GHMT PVP Kat.6A certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikát',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Nástroje a príslušenstvo',
        text: [
          'Nástroj na odizolovanie pre odstránenie vonkajšieho plášťa a kovových fólií dátového kábla. Modrá farba.',
          'Medená páska na upevnenie tieniacej vrstvy. Predrezané na 35 x 9 mm. Balenie: 20 kusov/taška 1500 kusov/rolka',
          'Farebné ochranné kryty proti prachu pre moduly preLink® SL a fixLink® SL Keystone.',
          'Balenie 50 kusov/polyetylénový sáčok',
        ],
        ghmtCertificates: [
          {
            name: 'Certifikát GHMT PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certifikát GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Inštalačný kábel',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Dátový kábel EasyLan® Gold S/FTP AWG22/1 1500 MHz Kat. 7A',
        text: [
          'Dátový kábel na prenos analógových a digitálnych signálov pri kabeláži v primárnej, sekundárnej a terciárnej oblasti. Vhodné pre aplikácie do triedy FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certifikát GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'Certifikát GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Technický list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Dátový kábel EasyLan® Silber S/FTP AWG23/1 1200 MHz Kat. 7A',
        text: [
          'Dátový kábel na prenos analógových a digitálnych signálov pri kabeláži v primárnej, sekundárnej a terciárnej oblasti. Vhodné pre aplikácie do triedy FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certifikát GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Technický list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Dátový kábel EasyLan® Bronze S/FTP AWG23/1 1000 MHz Kat. 7',
        text: 'Dátový kábel na prenos analógových a digitálnych signálov pri kabeláži v primárnej, sekundárnej a terciárnej oblasti. Vhodné pre aplikácie do triedy FA.',
        datasheets: [
          {
            name: 'Technický list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Dátové zásuvky',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Nástenné skrinky a rámy pre vyššie uvedené modulárne držiaky',
        text: [
          'Nástenné skrinky IP20, 80x80x42mm pre inštaláciu EasyLan modulárnych držiakov',
          'Medzi rám pre nástenné skrinky na zväčšenie výšky o 10 mm',
          'Rám na zakrytie 1-násobný 80x80mm',
          'Rám na zakrytie 2-násobný 80x150mm',
        ],
        datasheets: [
          {
            name: 'Nástenné skrinky',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Hĺbkové rozšírenie nástennej skrinky',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Modulárny držiak SL, 45° vývod, na povrch, pod omietku, v nádrži 1-násobný, 2-násobný, 3-násobný',
        text: 'Modulárne držiaky SL sú vhodné najmä pre inštaláciu v káblových kanáloch (EK), na povrchovú montáž (UP) a na povrchovú montáž (AP). Pri zlomení dvoch montážnych úchytiek je možné aj montáž do nádrže (BT). Modulárny držiak SL môže bezpečne a trvalo umiestniť jeden až tri EasyLan Keystony. Má veľké, centrálne umiestnené označovacie okno pre jednoduché označenie. Centrálna doska (50x50 mm) a rám (80x80 mm) sú vyrobené z UV odolného a mimoriadne odolného plastu. Tým je zabezpečený dlhodobý a farebne stály vzhľad.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Dizajnový modulárny držiak SL, 45° vývod, na povrch, pod omietku, v nádrži 1-násobný, 2-násobný',
        text: 'Dizajnový modulárny držiak pozostáva z rámu z tlakovo liateho zinku. Je vhodný pre inštaláciu do káblového kanála (EK), pre podomietkovú montáž (UP) alebo pomocou samostatného rámu aj pre povrchovú montáž (AP). Pri zlomení dvoch montážnych úchytiek je možné aj montáž do nádrže (BT). Dizajnová dátová zásuvka je kompatibilná s takmer všetkými dostupnými prepínačmi na trhu. Vďaka kompatibilite s UAE neposkytuje dizajnová dátová zásuvka miesto pre kryt proti prachu.',
        datasheets: [
          {
            name: '1-/2-násobný Keystone, bez centrálnej dosky',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: '2-násobný Keystone, s centrálnou doskou 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1-násobný s centrálnou doskou',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Modulárny držiak IP44 na povrch, 1-násobný, 2-násobný',
        text: 'Robustná povrchová skrinka je pomocou adaptéra vhodná na inštaláciu dvoch preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone modulov. Na zadnej strane skrinky sú dva ľahko priechodné miesta na zavedenie dvoch káblov s priemerom 5,0 - 9,5 mm. Modulárny držiak dosahuje triedu ochrany IP20 v zasunutom stave, v zatvorenom stave triedu ochrany IP44.',
      },
      DoseUPleer2xKS: {
        name: 'Modulárny držiak s priamym vývodom, 2-násobný',
        text: 'Modulárny držiak s priamym vývodom Keystone pozostáva z rámu z tlakovo liateho zinku a je vhodný pre inštaláciu do káblového kanála (EK), pre podomietkovú (UP) alebo pomocou samostatného rámu aj pre povrchovú montáž (AP).',
      },
      bodentank: 'Podlahová nádrž',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'Systémy podlahových nádrží OBO/Ackermann UT3, UT4',
        text: 'Nosná doska nahrádza nosič zariadení a tým ponúka maximálny priestor na zavedenie káblov. Šikmé vedenie kábla umožňuje bezpečné vedenie káblov aj pri veľmi nízkych medzipodlažiach. Je možné kombinovať s montážnymi doskami pre 4x preLink<sup>®</sup>/fixLink<sup>®</sup> moduly alebo 1x H.D.S. Montážne dosky sú pripevnené k nosnej doske dvoma skrutkami, nepoužívané otvory v nosnej doske môžu byť zakryté prachotesnými zaslepovacími doskami.',
        datasheets: [
          {
            name: 'Nosná doska pre UT3 bez montážnej dosky',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Nosná doska pre UT4 bez montážnej dosky',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'Základný nosič podlahových nádrží OBO/Ackermann UT3, UT4',
        text: 'Nosič Keystone pre podlahové nádrže nahrádza nosič zariadení a tým ponúka maximálny priestor na zavedenie káblov. Šikmé vedenie kábla umožňuje bezpečné vedenie káblov aj pri veľmi nízkych medzipodlažiach. Montážne dosky sú pripevnené k nosnej doske dvoma skrutkami, nepoužívané otvory v nosnej doske môžu byť zakryté prachotesnými zaslepovacími doskami. Pomocou adaptéra môže byť nosič podlahových nádrží použitý aj pre nosiče zariadení Electraplan.',
        datasheets: [
          {
            name: 'Nosič podlahových nádrží UT3 pre až 6 modulov',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Nosič podlahových nádrží UT4 pre až 9 modulov',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Nosič podlahových nádrží UT4 pre až 12 modulov',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'Základný nosič podlahových nádrží PUK UT4',
        text: 'Nosič Keystone pre podlahové nádrže nahrádza nosič zariadení a tým ponúka maximálny priestor na zavedenie káblov. Šikmé vedenie kábla umožňuje bezpečné vedenie káblov aj pri veľmi nízkych medzipodlažiach. Montážne dosky sú pripevnené k nosnej doske dvoma skrutkami, nepoužívané otvory v nosnej doske môžu byť zakryté prachotesnými zaslepovacími doskami. Nosiče podlahových nádrží sú kompatibilné so systémom podlahových nádrží PUK.',
        datasheets: [
          {
            name: 'Nosič podlahových nádrží UT4 pre až 9 modulov',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Nosič podlahových nádrží UT4 pre až 12 modulov',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'Vložky OBO/Ackermann pre systémy podlahových nádrží UT3, UT4, príslušenstvo',
        text: 'Vložky pre moduly sú z dôvodu uzemnenia nepokryté a žiarovo zinkované. Montážne dosky sú pripevnené k nosnej doske dvoma skrutkami, nepoužívané otvory v nosnej doske môžu byť zakryté prachotesnými zaslepovacími doskami.',
        datasheets: [
          {
            name: 'Montážna doska pre 4x RJ45 moduly',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Montážna doska pre 3x RJ45 moduly',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'DIN lišty adaptéry',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Kompaktné modulárne púzdro pre 1 modul na DIN lištu',
        text: 'Modulárne púzdro pre preLink<sup>®</sup> / fixLink<sup>®</sup> moduly, vhodné pre rozvádzače bez krytu pre ističe.',
        datasheets: [
          {
            name: 'Kompaktné modulárne púzdro pre 1 modul na DIN lištu',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Keystone-F-konektor a zaslepovací modul Keystone',
        text: 'F-konektor a zaslepovací modul ako náhradný modul\nF-konektor slúži na integráciu koaxiálnych káblov do preLink<sup>®</sup> / fixLink<sup>®</sup> patch panelov a do programu zásuviek. PreLink<sup>®</sup>/fixLink<sup>®</sup> zaslepovací modul môže byť použitý na pokrytie nepoužívaných portov v rozvádzači alebo v zásuvkách.\n\nPrázdny modul pre SC-S alebo LC-D ako náhradný modul\nPrázdny modul pre SC-S alebo LC-D konektory v rozvádzači alebo v zásuvkách.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Modulárne púzdro pre 1 modul na DIN lištu',
        text: 'Modulárne púzdro pre preLink<sup>®</sup> / fixLink<sup>®</sup> moduly, vhodné pre rozvádzače s krytom pre ističe.',
        datasheets: [
          {
            name: 'Modulárne púzdro pre 1 modul na DIN lištu',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panel',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1HE pre 24 modulov Keystone s priamym vývodom',
        text: 'Rozvádzač 19" 1HE je možné vybaviť až 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone modulmi. Je postavený v dvoch úrovniach, takže moduly sú zarovnané s predným panelom. Rozvádzač môže byť objednaný buď z nehrdzavejúcej ocele alebo z práškovo lakovaného plechu.\n\nMontážny materiál a moduly Keystone nie sú súčasťou dodávky.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1HE pre 48 modulov Keystone s priamym vývodom',
        text: 'Rozvádzač 19" 1HE je možné vybaviť až 48 fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 Keystone modulmi. Je postavený v dvoch úrovniach, takže moduly sú zarovnané s predným panelom. Rozvádzač je vyrobený z práškovo lakovaného plechu.\nMontážny materiál a moduly Keystone nie sú súčasťou dodávky.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0,5HE pre 24 modulov Keystone s priamym vývodom',
        text: 'Rozvádzač preLink<sup>®</sup> / fixLink<sup>®</sup> 19" 0,5HE je možné vybaviť až 24 fixLink<sup>®</sup> RJ45 Keystone modulmi. Sú zarovnané s predným panelom.\n\nMontážny materiál a moduly Keystone nie sú súčasťou dodávky.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1HE pre 24 modulov Keystone vrátane označovacieho okna',
        text: 'Rozvádzač 19" 1HE je možné vybaviť až 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone modulmi. Je postavený v dvoch úrovniach, takže moduly sú zarovnané s predným panelom. Rozvádzač môže byť objednaný buď z nehrdzavejúcej ocele alebo z práškovo lakovaného plechu. Okrem toho je možné jednotlivé porty označiť pomocou priloženého označovacieho listu 12x33.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Uhlový panel 30° 19" 1HE pre 24 modulov Keystone',
        text: 'S uhlovým panelom preLink<sup>®</sup> / fixLink<sup>®</sup> má užívateľ možnosť zapojiť 24 modulov Keystone pod uhlom 30° do panela, čím pripojené patch káble automaticky smerujú k bočnej strane rozvádzača a musia byť len pripevnené vedľa patch panelu. Užívateľ tak môže vynechať dodatočné vedenie káblov. V prevádzke vyžadujú uhlové panely s rovným predným panelom a šikmo umiestnenými patch káblami menej miesta vpredu ako bežné patch panely s vertikálne umiestnenými portami.\n\nMontážny materiál a moduly Keystone nie sú súčasťou dodávky.',
      },
      baugruppentraeger: 'Nosiče modulov',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Nosič modulov 19" 3HE pre použitie v zasúvacích moduloch',
        text: 'Modulárny nosič 19" 3HE pre použitie v 7TE zasúvacích moduloch z medi a optických vlákien. Moduly sú pripevnené k nosiču pomocou dvoch skrutiek z prednej strany. Zasúvacie moduly môžu byť úplne vysunuté z nosiča. Do nosiča je možné pripevniť maximálne 12x 7TE zasúvacie moduly.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Zasúvací modul Keystone pre modulárne nosiče',
        text: 'Zasúvací modul Keystone na vybavenie modulárnych nosičov. Zasúvací modul Keystone môže byť vybavený až 6 preLink<sup>®</sup>- alebo fixLink<sup>®</sup> Keystone modulmi. Zasúvací modul Keystone je postavený v dvoch úrovniach, takže moduly sú zarovnané s predným panelom. Káblová opierka je pripevnená priamo k púzdru zasúvacieho modulu. Je možné pripevniť 6 jednotlivých káblov alebo 1 trunk kábel.\n\nMontážny materiál a moduly Keystone nie sú súčasťou dodávky.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Modulárny nosič (BGT) 19" 1HE pre použitie v zasúvacích moduloch',
        text: 'Modulárny nosič (BGT) pre použitie v 7TE zasúvacích moduloch z medi a optických vlákien. Moduly sú pripevnené k nosiču pomocou dvoch skrutiek z prednej strany. Zasúvacie moduly môžu byť úplne vysunuté z nosiča. Do nosiča je možné pripevniť maximálne 3x 7TE zasúvacie moduly (=18 portov).\n\nMontážny materiál a moduly Keystone nie sú súčasťou dodávky.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Bočný nosič modulov 3HE/14TE pre použitie v zasúvacích moduloch',
        text: 'Bočný nosič modulov (S-BGT) pre použitie v 7TE zasúvacích moduloch z medi a optických vlákien. Moduly sú pripevnené k nosiču pomocou dvoch skrutiek z prednej strany. Zasúvacie moduly môžu byť úplne vysunuté z nosiča. Do nosiča je možné pripevniť maximálne 2x 7TE zasúvacie moduly (=12 portov). Montáž bočného nosiča modulov (S-BGT) sa vykonáva na bočnom otvore skrinky. Tým je možné jednoduché a rýchle namontovanie. Bočná montáž do skrinky umožňuje realizáciu priestorovo úsporného a mimoriadne ekologického káblovania bez narušenia prúdenia vzduchu.\n\nMontážny materiál a moduly Keystone nie sú súčasťou dodávky.',
      },
      consolidation_point_panel: 'Panel konsolidačných bodov, DIN lišty atď.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Podlahový panel pre konsolidačné body 19" alebo 10" 1HE',
        text: 'Podlahový panel pre konsolidačné body s vymeniteľnými prednými panelmi na pripojenie rôznych typov konektorov z medi a optických vlákien. Voliteľne môže byť panel pre konsolidačné body uzamknutý na ochranu pred neoprávneným prístupom.\n\nMontážny materiál a moduly Keystone nie sú súčasťou dodávky.',
        datasheets: [
          {
            name: 'Panel pre konsolidačné body 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Panel pre konsolidačné body 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Panel pre konsolidačné body na povrch pre 4-12 modulov Keystone',
        text: 'Panel pre konsolidačné body pre preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 moduly Keystone, určený na použitie ako konsolidačný panel, priame pripojenie alebo aj pomocou montážnej sady na DIN lištu v rozvádzačoch elektroinštalácií. Panel pre konsolidačné body je postavený v dvoch úrovniach. Moduly sú zarovnané s predným panelom.\n\nMontážny materiál a moduly Keystone nie sú súčasťou dodávky.',
      },
      trunkkabel: 'Trunk káble',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'SL CTBC deliaci systém Copper-Trunk-Bundled-Cable, trieda EA ISO/IEC',
        text: 'Deliaci systém CTBC je vhodný pre flexibilné použitie v štruktúrovanej káblovej infraštruktúre budov a v dátových centrách. Káble zoskupené v opletovom rukáve sú z výroby osadené a testované s fixLink<sup>®</sup> Keystone modulmi. Zoskupenie môže byť realizované s inštalačnými káblami alebo s flexibilnými káblami. Maximálna dĺžka zoskupeného kábla je až 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'SL CTCC deliaci systém Copper-Trunk-Compact-Cable, trieda EA ISO/IEC',
        text: 'Deliaci systém CTCC je vďaka svojmu minimálnemu priemeru kábla a zodpovedajúcej nízkej požiarnosti vhodný pre flexibilné použitie v štruktúrovanej káblovej infraštruktúre budov a v dátových centrách. Maximálna dĺžka viacnásobného kábla je v závislosti od typu 60-90 m. Deliaci systém ako 10-Gbit riešenie pre dátové centrá spĺňa minimálne požiadavky triedy EA.',
      },
      hds_innengabel: 'H.D.S. vnútorné káble',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'H.D.S. vnútorný kábel Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'H.D.S. vnútorný kábel Kat. 7 S/FTP pre jednoduché prepojenie skrinky na skrinku v dátových centrách a viacnásobné prepojenia z distribučných miestností na podriadené rozvádzače. Káblová štruktúra pozostáva z 24 párovo tienených káblových prvkov, každé 4 páry sú zoskupené do jednej jednotky. Každá jednotka je označená identifikačným pásom pre jednoduchšiu identifikáciu. Flexibilný centrálny ťahový prvok chráni kábel pri inštalácii. Plášť je vyrobený z flexibilného LSOH materiálu.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'H.D.S. vnútorný kábel Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'H.D.S. vnútorný kábel Kat. 7 S/FTP pre jednoduché prepojenie skrinky na skrinku v dátových centrách a viacnásobné prepojenia z distribučných miestností na podriadené rozvádzače. Káblová štruktúra pozostáva z 24 párovo tienených káblových prvkov, každé 4 páry sú zoskupené do jednej jednotky. Každá jednotka je označená identifikačným pásom pre jednoduchšiu identifikáciu. Flexibilný centrálny ťahový prvok chráni kábel pri inštalácii. Plášť je vyrobený z flexibilného LSOH materiálu.',
      },
      generic: {
        download: 'Stiahnuť',
      },
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Ochrana osobných údajov',
  },

  footer: {
    dataProtection: 'Ochrana osobných údajov',
    imprint: 'Impressum',
    contact: 'Kontakt',
  },

  UI: {
    autocomplete: {
      searchError: 'Vyhľadávanie sa nepodarilo.',
      searchEmpty: 'Žiadne výsledky',
    },
    dropdown: {
      emptySelection: '--- prosím vyberte ---',
    },
  },
}

export const langKey = 'SK'

const Default = {
  translation,
  langKey,
}

export default Default
