export const translation = {
  header: {
    items: [
      {
        name: 'Shtëpi',
        href: '/',
      },
      {
        name: 'Sistem',
        href: '#sistem',
      },
      {
        name: 'Modul',
        href: '#modul',
      },
      {
        name: 'Montimi',
        href: '#montimi',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Gjuha',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Thjeshtësi',
        text2: 'në lidhje',
      },
      subtitle: 'Lidhja juaj për të ardhmen',
      cta: 'më shumë',
    },
    handling: {
      title: 'MANIPULIM INTUITIV TAKON TEKNOLOGJINË E AVANCUAR',
      vorreiterPrefix: 'Kombinimi i saktësisë dhe dizajnit funksional bën që ',
      vorreiterSuffix: ' të jetë zgjedhja kryesore në teknologjinë e rrjetit.',
      experte: ' ofron zgjidhje efikase 360°, që kombinon dizajnin dhe performancën për të minimizuar kompleksitetin.',
      pionier: ' është pionier në transformimin e hapësirave të punës, duke kombinuar ekspertizën me manipulimin intuitiv.',
    },
    download: {
      prefix: 'Shkarko përmbledhjen e ',
      suffix: ' - Sistemi',
      cta: 'Shkarko',
      href: 'https://fixlink.eu/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Përmbledhje e Produktit',
      title: 'FIXLINK<sup>®</sup> - SISTEM',
    },
    module: {
      overview: 'Shkarkimi i përmbledhjes',
      accordion: {
        ghmtCertificates: {
          title: 'Certifikatat GHMT',
          text: 'Ne mbështetemi te GHMT dhe Programi i tyre i Verifikimit Premium për sigurimin e cilësisë së pavarur të produkteve tona të rrjetit. Kështu garantojmë, bazuar në 20 vjet ekspertizë, standardet më të larta të produktit për klientët tanë.',
        },
        datasheets: {
          title: 'Fletë të Dhënash',
          text: 'Këtu do të gjeni të gjitha fletët e dhënash të nevojshme. Shkarkojini dhe keni të gjitha informacionet në një vend.',
        },
        compatibilitySheets: {
          title: 'Listat e Kompatibilitetit',
          text: '',
        },
        excelTemplates: {
          title: 'Dritaret e Etiketimit si Shabllon Excel',
          text: '',
        },
      },
    },
    video: {
      title: 'UDHËZUES PËR MONTIM',
      text: 'Shikoni videon ose shkarkoni PDF-in këtu',
      manual: {
        title: 'UDHËZUES PËR MONTIM:',
      },
    },
    separator: {
      title: 'Më shumë se thjesht lidhje',
      title2: 'Zbuloni botën e EasyLan',
      text: 'Zbuloni larmishmërinë e zgjidhjeve inovative të rrjetit në EasyLan.de: Personalizuar për ju. Ndryshuesi i lojës për rrjetin tuaj: Sistemet tona të kabllove dhe zgjidhjet e menaxhimit të kabllove.',
      cta: 'më shumë',
    },
    combination: {
      bau: {
        prefix: 'Përjetoni përshtatjen maksimale dhe cilësinë me ',
        text: ' mbajtësin e grupit të EasyLan ',
        suffix: 'direkt nga Evropa.',
      },
      intelligent: {
        prefix: 'Rrjeti juaj',
        text: ' i personalizuar ',
        suffix: 'me kutinë e ndarjes së EasyLan',
      },
      fixlink: {
        prefix: 'Me produktet tona ',
        suffix: ' mund të përmirësoni ndërtesat tuaja të zyrave afatgjatë.',
      },
      hds: {
        prefix: 'Sistemi',
        text: ' EasyLan<sup>®</sup> H.D.S. ',
        suffix: 'kombinon lehtësinë e instalimit dhe mirëmbajtjes si asnjë tjetër sistem kabllosh në treg.',
      },
    },
    recommended: {
      titleLabel: 'Rekomanduar',
      title: 'fixLink<sup>®</sup> - SISTEM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Garancia e Produktit',
        text: [
          'Garancia e produktit përfshin të gjitha komponentët e një ',
          '- sistemi kabllor me fibra optike ose bakër, të instaluara dhe të testuara nga një instalues i kualifikuar në përputhje me udhëzimet e ',
          ' dhe standardet e vlefshme të instalimit EN50174.',
        ],
      },
      {
        title: 'Garancia e Performancës',
        text: [
          'Garancia e performancës garanton, përveç aplikacionit (Ethernet, Fiber Channel, ATM, etj.), karakteristikat e sistemit duke përfshirë shpejtësitë e transmetimit (10MBit, 100MBit, 1GBit dhe 10GBit).',
        ],
      },
      {
        title: 'Garancia e Aplikacionit',
        text: [
          'Garancia e aplikacionit garanton të gjitha aplikacionet e specifikuara (Ethernet, Fiber Channel, ATM, etj.) bazuar në standardet e kabllimit ISO/IEC 11801, EN50173 dhe EIA/TIA 568B të vlefshme në kohën e instalimit.',
        ],
      },
    ],
    end: {
      title: 'LIDHJET NUK KANË QENË KURRË KAQ TË THJESHTA.',
      text: 'Manipulimi intuitiv takon teknologjinë e avancuar; zbuloni lidhjen e qetë të së ardhmes: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Modulet e lidhjes',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Moduli Keystone Pro RJ45 Kategoria 6A ISO/IEC, i mbrojtur',
        text: 'Me modulin fixLink<sup>®</sup> Pro RJ45 Keystone, përdoruesi përfiton një modul të lidhjes RJ45 të cilësisë më të lartë. Moduli fixLink<sup>®</sup> Pro RJ45 Keystone mund të montohet me një mjet prerës anësor brenda një minute. Përmes zonës së lirë të kontaktit, sigurohet një cilësi perfekte e montimit dhe për këtë arsye performanca e garantuar e transmetimit. Gjeometria speciale e kontaktit në kombinim me një shtresë ari prej 30 μ" siguron 4PPoE sipas IEEE 802.3bt në të gjitha kushtet. Materiali i strehës përbëhet nga zink i nikeluar, duke përmbushur kërkesat më të larta mjedisore dhe mekanike. Në kombinim me mbajtësen e modulit të dizajnit, është e mundur një kombinim me të gjitha programet e ndërprerësve. Ato dorëzohen në një paketim kartoni miqësor me mjedisin.',
        ghmtCertificates: [
          {
            name: 'Certifikata PVP Kategoria 6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Certifikata e Lidhjes së Përhershme',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Zgjatuesi Kategoria 6A ISO/IEC, i mbrojtur',
        text: 'Me zgjatuesin fixLink<sup>®</sup>, kabllot mund të zgjerohen pa shumë përpjekje. Për më tepër, kabllot e pavena në përdorim mund të reaktivizohen dhe të zgjaten deri në lidhjen tjetër të nevojshme. Hapësira e ngushtë në kanalin e kabllove shfrytëzohet kështu në mënyrë optimale. Zgjatuesi thjesht montohet në fund të kabllit të instaluar në kanal ose në mur. Pas instalimit të suksesshëm, kablli i zgjatur mund të përdoret për transmetime Ethernet 10-Gigabit dhe mbështet PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'Moduli i lidhjes RJ45 Kategoria 6A ISO/IEC, i mbrojtur',
        text: 'Me modulin fixLink<sup>®</sup> RJ45, përdoruesi përfiton një modul të lidhjes RJ45 të cilësisë më të lartë dhe fleksibilitetit. Gjeometria speciale e kontaktit në kombinim me një shtresë ari prej 50 μ" siguron 4PPoE sipas IEEE 80.3bt në të gjitha kushtet. Materiali i strehës përbëhet nga zink i nikeluar, duke përmbushur kërkesat më të larta mjedisore dhe mekanike. Certifikatat e ndryshme (lista e plotë: www.easylan.de) nga laboratorët e pavarur plotësojnë kërkesat më të larta të cilësisë të sistemit fixLink<sup>®</sup>.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'Moduli i lidhjes RJ45 Kategoria 6A EIA/TIA, i pambrojtur',
        text: 'Me modulin fixLink<sup>®</sup> RJ45 (i përshtatshëm për PoE+), përdoruesi përfiton një modul të lidhjes RJ45 të cilësisë më të lartë. Moduli fixLink<sup>®</sup> RJ45 mund të montohet me një mjet prerës anësor brenda një minute. Përmes zonës së lirë të kontaktit, sigurohet një cilësi perfekte e montimit dhe për këtë arsye performanca e garantuar e transmetimit. Materiali i strehës përbëhet nga polikarbonati dhe përmbush kërkesat më të larta mjedisore dhe mekanike. Në kombinim me mbajtësen e modulit të dizajnit, është e mundur një kombinim me të gjitha programet e ndërprerësve. Certifikatat e ndryshme nga laboratorët e pavarur plotësojnë kërkesat më të larta të cilësisë të modulit fixLink<sup>®</sup> SL Keystone. Ato dorëzohen në një paketim kartoni miqësor me mjedisin.',
      },
      fixLink_SL_02: {
        name: 'Moduli i lidhjes SL RJ45 Kategoria 6A ISO/IEC, i mbrojtur',
        text: 'Me modulin fixLink<sup>®</sup> SL RJ45, përdoruesi përfiton një modul të lidhjes RJ45 të cilësisë më të lartë. Moduli fixLink<sup>®</sup> SL RJ45 mund të montohet me një mjet prerës anësor brenda një minute. Përmes zonës së lirë të kontaktit, sigurohet një cilësi perfekte e montimit dhe për këtë arsye performanca e garantuar e transmetimit. Për të siguruar mbrojtjen 360°, mjafton të kyçet në vend vula e integruar, duke eliminuar nevojën për të vendosur një kapëse kablli. Gjeometria speciale e kontaktit në kombinim me një shtresë ari prej 50 μ" siguron 4PPoE sipas IEEE 802.3bt në të gjitha kushtet. Materiali i strehës përbëhet nga zink i nikeluar, duke përmbushur kërkesat më të larta mjedisore dhe mekanike. Në kombinim me mbajtësen e modulit të dizajnit, është e mundur një kombinim me të gjitha programet e ndërprerësve. Certifikatat e ndryshme nga laboratorët e pavarur plotësojnë kërkesat më të larta të cilësisë të modulit fixLink<sup>®</sup> SL Keystone. Ato dorëzohen në një paketim kartoni miqësor me mjedisin.',
        ghmtCertificates: [
          {
            name: 'Certifikata PVP Kategoria 6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Raporti i Testit 4PoE sipas DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'Certifikata e Lidhjes së Përhershme GHMT',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'Moduli i lidhjes SL RJ45 Kategoria 6A ISO/IEC, i mbrojtur, i kënduar',
        text: 'Me modulin fixLink<sup>®</sup> SL RJ45, përdoruesi përfiton një modul të lidhjes RJ45 të cilësisë më të lartë, i cili është zhvilluar posaçërisht për aplikime me hapësirë të kufizuar. Me dalje kabllore prej 75°, ky modul është veçanërisht i përshtatshëm për përdorim në prizat e të dhënave dhe instalimet në hapësira bosh. Një thellësi minimale instalimi prej 27 mm lejon një larmi të pafundme aplikimesh. Moduli fixLink<sup>®</sup> SL RJ45 mund të montohet me një mjet prerës anësor brenda një minute. Përmes zonës së lirë të kontaktit, sigurohet një cilësi perfekte e montimit dhe për këtë arsye performanca e garantuar e transmetimit. Gjeometria speciale e kontaktit në kombinim me një shtresë ari prej 50 μ" siguron PoE+ sipas IEEE 80.3at në të gjitha kushtet. Materiali i strehës përbëhet nga zink i nikeluar, duke përmbushur kërkesat më të larta mjedisore dhe mekanike. Në kombinim me mbajtësen e modulit të dizajnit, është e mundur një kombinim me të gjitha programet e ndërprerësve. Certifikatat e ndryshme nga laboratorët e pavarur plotësojnë kërkesat më të larta të cilësisë të modulit fixLink<sup>®</sup> SL Keystone. Ato dorëzohen në një paketim kartoni miqësor me mjedisin.',
        ghmtCertificates: [
          {
            name: 'Certifikata PVP Kategoria 6A GHMT',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certifikata e Lidhjes së Përhershme GHMT',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Mjete dhe aksesorë',
        text: [
          'Mjet për zhveshjen e kabllove për heqjen e mbështjellësit të jashtëm dhe folieve metalike të një kablloje të dhënash. Ngjyra blu.',
          'Shirit bakri për fiksimin e mbrojtjes. Paraprakisht i prerë në 35 x 9 mm. Njësia e paketimit: 20 copë/qese 1500 copë/rrotull',
          'Kapakë mbrojtës ndaj pluhurit me ngjyra për modulet preLink® SL dhe fixLink® SL Keystone.',
          'Njësia e paketimit 50 copë/qese plastike',
        ],
        ghmtCertificates: [
          {
            name: 'Certifikata GHMT PVP Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'Certifikata GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Kabllo instalimi',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Kabllo të dhënash EasyLan® Gold S/FTP AWG22/1 1500 MHz Kat. 7A',
        text: [
          'Kabllo të dhënash për transmetimin e sinjaleve analoge dhe dixhitale në kabllimin në fushën primare, sekondare dhe terciare. I përshtatshëm për aplikime deri në klasën FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certifikata GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'Certifikata GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Fletë të dhënash',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Kabllo të dhënash EasyLan® Silber S/FTP AWG23/1 1200 MHz Kat. 7A',
        text: [
          'Kabllo të dhënash për transmetimin e sinjaleve analoge dhe dixhitale në kabllimin në fushën primare, sekondare dhe terciare. I përshtatshëm për aplikime deri në klasën FA.',
        ],
        ghmtCertificates: [
          {
            name: 'Certifikata GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Fletë të dhënash',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Kabllo të dhënash EasyLan® Bronze S/FTP AWG23/1 1000 MHz Kat. 7',
        text: 'Kabllo të dhënash për transmetimin e sinjaleve analoge dhe dixhitale në kabllimin në fushën primare, sekondare dhe terciare. I përshtatshëm për aplikime deri në klasën FA.',
        datasheets: [
          {
            name: 'Fletë të dhënash',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Prizat e të dhënave',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Kutia dhe korniza për montim të sipërfaqshëm për mbajtësin e modulit të përmendur',
        text: [
          'Kutia e sipërfaqshme IP20, 80x80x42mm për instalimin e mbajtësit të modulit EasyLan',
          'Korniza ndërmjetëse për kutinë e sipërfaqshme për zgjerimin e lartësisë me 10mm',
          'Korniza mbuluese 1-fish 80x80mm',
          'Korniza mbuluese 2-fish 80x150mm',
        ],
        datasheets: [
          {
            name: 'Kutia e sipërfaqshme',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Zgjerimi i thellësisë së kutisë së sipërfaqshme',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Mbajtësi i modulit SL, dalje prej 45°, për montim të brendshëm, BK, AP, BT 1-fish, 2-fish, 3-fish',
        text: 'Mbajtësit e modulit SL janë veçanërisht të përshtatshëm për instalim në kanal kabllor (EK), montim të brendshëm (UP) dhe montim të sipërfaqshëm (AP). Me thyerjen e dy skajeve të montimit, është gjithashtu e mundur montimi në tavan (BT). Mbajtësi i modulit SL mund të mbajë një deri në tre keystones EasyLan absolutisht të sigurt dhe të qëndrueshëm. Ka një dritare të madhe për etiketim të thjeshtë. Pllaka qendrore (50x50 mm) dhe korniza (80x80 mm) janë bërë nga plastika rezistente ndaj UV dhe shumë e fortë. Kështu sigurohet një pamje e qëndrueshme dhe e qëndrueshme ndaj ngjyrës.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Mbajtësi i modulit dizajner SL, dalje prej 45°, për montim të brendshëm, BK, AP, BT 1-fish, 2-fish',
        text: 'Mbajtësi i modulit dizajner përbëhet nga një kornizë prej zinku të derdhur. Është i përshtatshëm për instalim në një kanal kabllor (EK), për montim të brendshëm (UP) ose me një kornizë të veçantë edhe për montim të sipërfaqshëm (AP). Me thyerjen e dy skajeve të montimit, është gjithashtu e mundur montimi në tavan (BT). Priza dizajner është e kombinuar me pothuajse të gjitha programet e ndërprerësve në treg. Për shkak të kompatibilitetit me UAE, priza dizajner nuk ka vend për një kapak mbrojtës ndaj pluhurit.',
        datasheets: [
          {
            name: '1-/2-fish Keystone, pa pllakë qendrore',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: '2-Fish Keystone, me pllakë qendrore 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1-fish me pllakë qendrore',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Mbajtësi i modulit IP44 për montim të sipërfaqshëm, 1-fish, 2-fish',
        text: "Kutia e fortë për montim të sipërfaqshëm është e përshtatshme për instalimin e dy moduleve preLink<sup>®</sup>/fixLink<sup>®</sup> Keystone me ndihmën e një adaptori. Për futjen e dy kabllove me një diametër prej 5.0 - 9.5mm, në pjesën e pasme të kutisë ka dy pika të lehta për t'u shpuar. Mbajtësi i modulit arrin klasën e mbrojtjes IP20 në gjendje të lidhur, ndërsa në gjendje të mbyllur arrin klasën e mbrojtjes IP44.",
      },
      DoseUPleer2xKS: {
        name: 'Mbajtësi i modulit me dalje të drejtë, 2-fish',
        text: 'Mbajtësi i modulit me dalje të drejtë përbëhet nga një kornizë prej zinku të derdhur dhe është i përshtatshëm për instalim në një kanal kabllor (EK), për montim të brendshëm (UP), ose me një kornizë të veçantë edhe për montim të sipërfaqshëm (AP).',
      },
      bodentank: 'Tavan',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'Sistemet e tavaneve OBO/Ackermann UT3, UT4',
        text: 'Pllaka mbajtëse zëvendëson mbajtëset e pajisjeve dhe ofron kështu hapësirën maksimale për futjen e kabllove. Drejtimi i pjerrët i futjes dhe daljes siguron një drejtim të sigurt të kabllove edhe në lartësi shumë të ulëta. Një kombinim me pllakën e instalimit për 4x preLink<sup>®</sup>/fixLink<sup>®</sup> module ose 1x H.D.S. është parashikuar. Pllakat e instalimit montohen me dy vida në pllakën mbajtëse, fushat e panevojshme të pllakës mbajtëse mund të mbulohen me pllaka të verbëra për mbrojtje nga pluhuri.',
        datasheets: [
          {
            name: 'Pllaka mbajtëse për UT3 pa pllakë instalimi',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Pllaka mbajtëse për UT4 pa pllakë instalimi',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'Mbajtësi i tavaneve OBO/Ackermann Basic UT3, UT4',
        text: 'Mbajtësi i tavaneve Keystone zëvendëson mbajtësin e pajisjeve dhe ofron kështu hapësirën maksimale për futjen e kabllove. Drejtimi i pjerrët i futjes dhe daljes siguron një drejtim të sigurt të kabllove edhe në lartësi shumë të ulëta. Pllakat e instalimit montohen me dy vida në pllakën mbajtëse, fushat e panevojshme të mbajtësit të tavaneve Keystone mund të mbulohen me pllaka të verbëra për mbrojtje nga pluhuri. Me ndihmën e një adaptori, mbajtësi i tavaneve mund të përdoret edhe për mbajtësin e pajisjeve Electraplan.',
        datasheets: [
          {
            name: 'Mbajtësi i tavaneve UT3 për deri në 6 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Mbajtësi i tavaneve UT4 për deri në 9 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Mbajtësi i tavaneve UT4 për deri në 12 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'Mbajtësi i tavaneve PUK Basic UT4',
        text: 'Mbajtësi i tavaneve Keystone zëvendëson mbajtësin e pajisjeve dhe ofron kështu hapësirën maksimale për futjen e kabllove. Drejtimi i pjerrët i futjes dhe daljes siguron një drejtim të sigurt të kabllove edhe në lartësi shumë të ulëta. Pllakat e instalimit montohen me dy vida në pllakën mbajtëse, fushat e panevojshme të mbajtësit të tavaneve Keystone mund të mbulohen me pllaka të verbëra për mbrojtje nga pluhuri. Mbajtësit e tavaneve janë të përshtatshëm me sistemin e tavaneve PUK.',
        datasheets: [
          {
            name: 'Mbajtësi i tavaneve UT4 për deri në 9 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Mbajtësi i tavaneve UT4 për deri në 12 module',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'Pjesët për sistemet e tavaneve OBO/Ackermann UT3, UT4, aksesorët',
        text: 'Pjesët për module janë pa pluhur dhe të galvanizuara për arsye të tokëzimit. Pllakat e instalimit montohen me dy vida në pllakën mbajtëse, fushat e panevojshme të pllakës mbajtëse mund të mbulohen me pllaka të verbëra për mbrojtje nga pluhuri.',
        datasheets: [
          {
            name: 'Pllaka e instalimit për 4 module RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Pllaka e instalimit për 3 module RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Adapterët për hekurudha',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Kutia kompakte për modul për hekurudha për 1 modul',
        text: 'Kutia për modul për hekurudha për module preLink<sup>®</sup>/fixLink<sup>®</sup>, e përshtatshme për kabinete shpërndarjeje pa mbulesë për automatët e sigurisë.',
        datasheets: [
          {
            name: 'Kutia kompakte për modul për hekurudha për 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Adapteri Keystone për lidhës F dhe kapak i verbër për modul Keystone',
        text: 'Lidhësi F dhe kapaku i verbër si zëvendësues i modulit\nLidhësi F shërben për integrimin e kabllove koaksiale në panelet e patch-it preLink<sup>®</sup>/fixLink<sup>®</sup> dhe në programin e prizave. Kapaku i verbër për preLink<sup>®</sup>/fixLink<sup>®</sup> mund të përdoret si në fushën e shpërndarjes ashtu edhe në prizat për mbulimin e porteve të papërdorura.\n\nModul i zbrazët për SC-S ose LC-D si zëvendësues i modulit\nModul i zbrazët për lidhësit SC-S ose LC-D në fushën e shpërndarjes ose në prizë.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Kutia për modul për hekurudha për 1 modul',
        text: 'Kutia për modul për hekurudha për module preLink<sup>®</sup>/fixLink<sup>®</sup>, e përshtatshme për kabinete shpërndarjeje me mbulesë për automatët e sigurisë.',
        datasheets: [
          {
            name: 'Kutia për modul për hekurudha për 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panel',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1HE për 24 module Keystone me dalje të drejtë',
        text: 'Fusha e shpërndarjes 19" 1HE është e përshtatshme për deri në 24 module preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone. Ajo është ndërtuar në dy nivele, kështu që modulet janë të rreshtuara në panelin e përparmë. Fusha e shpërndarjes mund të porositet në çelik inox ose në fletë hekuri të pluhurosur.\n\nMateriali i montimit dhe modulet Keystone nuk janë përfshirë në paketim.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1HE për 48 module Keystone me dalje të drejtë',
        text: 'Fusha e shpërndarjes 19" 1HE është e përshtatshme për deri në 48 module fixLink<sup>®</sup> Pro/fixLink<sup>®</sup> Pro+ RJ45 Keystone. Ajo është ndërtuar në dy nivele, kështu që modulet janë të rreshtuara në panelin e përparmë. Fusha e shpërndarjes përbëhet nga fletë hekuri të pluhurosur.\nMateriali i montimit dhe modulet Keystone nuk janë përfshirë në paketim.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0,5HE për 24 module Keystone me dalje të drejtë',
        text: 'Fusha e shpërndarjes preLink<sup>®</sup>/fixLink<sup>®</sup> 19" 0,5HE është e përshtatshme për deri në 24 module fixLink<sup>®</sup> RJ45 Keystone. Ato janë të rreshtuara në panelin e përparmë.\n\nMateriali i montimit dhe modulet Keystone nuk janë përfshirë në paketim.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1HE për 24 module Keystone me dritare etiketimi',
        text: 'Fusha e shpërndarjes 19" 1HE është e përshtatshme për deri në 24 module preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone. Ajo është ndërtuar në dy nivele, kështu që modulet janë të rreshtuara në panelin e përparmë. Fusha e shpërndarjes mund të porositet në çelik inox ose në fletë hekuri të pluhurosur. Për më tepër, është e mundur të etiketohen portet individuale me ndihmën e një fletë etiketime të përfshirë në paketim.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Panel këndor 30° 19" 1HE për 24 module Keystone',
        text: 'Në panelin këndor preLink<sup>®</sup>/fixLink<sup>®</sup>, përdoruesi ka mundësinë të futë 24 module Keystone nën një kënd prej 30°, kështu që kabllot e patch-it të lidhura automatikisht drejtohen drejt anës së kabinetit dhe duhet të fiksohen vetëm anash në anën e patch-it. Në këtë mënyrë, përdoruesi mund të shmangë panelet e tjera për drejtimin e kabllove. Në përdorim, panelet këndore me faqen e përparmë të rrafshuar dhe kabllot e patch-it të vendosura në kënd, kërkojnë më pak hapësirë përpara sesa panelet tradicionale të patch-it me porte të vendosura vertikalisht.\n\nMateriali i montimit dhe modulet Keystone nuk janë përfshirë në paketim.',
      },
      baugruppentraeger: 'Mbajtësit e grupit',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Mbajtësi i grupit 19" 3HE për përdorimin e moduleve të futshme',
        text: 'Mbajtësi i modulit 19" 3HE për përdorimin e moduleve të futshme 7TE në bakër dhe fibra optike. Ata janë të fiksuar në mbajtësin e modulit me dy vida nga ana e panelit. Modulet e futshme mund të nxirren plotësisht nga mbajtësi i modulit. Mund të fiksohen deri në 12 module të futshme 7TE në mbajtësin e modulit.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Moduli i futjes Keystone për mbajtësin e modulit',
        text: 'Moduli i futjes Keystone për pajisjen e mbajtësve të modulit. Moduli i futjes Keystone mund të pajiset me deri në 6 module preLink<sup>®</sup>/fixLink<sup>®</sup> Keystone. Moduli i futjes Keystone është ndërtuar në dy nivele, kështu që modulet janë të rreshtuara me panelin e përparmë. Kabllot fiksohen direkt në strehën e modulit të futjes. Mund të fiksohen 6 kabllo individuale ose 1 kabllo trungu.\n\nMateriali i montimit dhe modulet Keystone nuk janë përfshirë në paketim.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Mbajtësi i modulit (BGT) 19" 1HE për përdorimin e moduleve të futshme',
        text: 'Mbajtësi i modulit (BGT) për përdorimin e moduleve të futshme 7TE në bakër dhe LWL. Ata janë të fiksuar në mbajtësin e modulit me dy vida nga ana e panelit. Modulet e futshme mund të nxirren plotësisht nga mbajtësi i modulit. Mund të fiksohen deri në 3 module të futshme 7TE (=18 porte) në mbajtësin e modulit.\n\nMateriali i montimit dhe modulet Keystone nuk janë përfshirë në paketim.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Mbajtësi i modulit anësor 3HE/14TE për përdorimin e moduleve të futshme',
        text: 'Mbajtësi i modulit anësor (S-BGT) për përdorimin e moduleve të futshme 7TE në bakër dhe LWL. Modulet e futshme janë të fiksuara me dy vida nga ana e panelit në mbajtësin e modulit. Modulet e futshme mund të nxirren plotësisht nga mbajtësi i modulit. Mund të fiksohen deri në 2 module të futshme 7TE (=12 porte) në mbajtësin e modulit. Fiksimi i mbajtësit të modulit anësor (S-BGT) bëhet në vrimat anësore të kabinetit. Kjo e bën të mundur një instalim të lehtë dhe të shpejtë. Përmes montimit anësor në kabinet është e mundur të realizohet një kabllim me hapësirë të kursyer dhe veçanërisht miqësor ndaj klimës, pa ndërprerë rrjedhën e ajrit.\n\nMateriali i montimit dhe modulet Keystone nuk janë përfshirë në paketim.',
      },
      consolidation_point_panel: 'Paneli i pikës së konsolidimit, shinat DIN etj.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Paneli i pikës së konsolidimit nën dysheme 19" ose 10" 1HE',
        text: 'Paneli i pikës së konsolidimit nën dysheme me pllaka të përparmë të ndërrueshme për pranimin e lidhjeve të ndryshme në bakër dhe LWL. Opsionalisht, paneli i pikës së konsolidimit nën dysheme mund të sigurohet me një çelës kundër aksesit të paautorizuar.\n\nMateriali i montimit dhe modulet Keystone nuk janë përfshirë në paketim.',
        datasheets: [
          {
            name: 'Paneli i pikës së konsolidimit 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Paneli i pikës së konsolidimit 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Paneli i pikës së konsolidimit për 4-12 module Keystone',
        text: 'Paneli i pikës së konsolidimit për module preLink<sup>®</sup>/fixLink<sup>®</sup> RJ45 Keystone, për përdorim si panel konsolidimi, montim të drejtpërdrejtë ose edhe me ndihmën e një seti montimi në hekurudha në shpërndarjet elektrike. Paneli i pikës së konsolidimit është ndërtuar në dy nivele. Kështu që modulet janë të rreshtuara në panelin e përparmë.\n\nMateriali i montimit dhe modulet Keystone nuk janë përfshirë në paketim.',
      },
      trunkkabel: 'Kabllo trungu',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'Sistemi CTBC i ndarjes, Kabllo trungu bakri të grumbulluara, Klasa EA ISO/IEC',
        text: 'Sistemi i ndarjes CTBC është i përshtatshëm për përdorim fleksibël në kabllimin e ndërtesave të strukturuara dhe në qendrat e të dhënave. Kabllot e grumbulluara në një mbështjellje janë të pajisura dhe të testuara në fabrikë me module fixLink<sup>®</sup> Keystone. Grumbullimi mund të bëhet si me kabllo instalimi ashtu edhe me kabllo fleksibël. Gjatësia maksimale e kabllos së grumbulluar është deri në 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'Sistemi CTCC i ndarjes, Kabllo trungu kompakt bakri, Klasa EA ISO/IEC',
        text: 'Sistemi i ndarjes CTCC është i përshtatshëm për përdorim fleksibël në kabllimin e ndërtesave të strukturuara dhe në qendrat e të dhënave për shkak të diametrit minimal të kabllos dhe ngarkesës së ulët të zjarrit. Gjatësia maksimale e kabllos së grumbulluar është varësisht nga tipi 60-90 m. Sistemi i ndarjes si zgjidhje 10-Gbit për kabllimin e qendrave të të dhënave plotëson të paktën kërkesat e Klasës EA.',
      },
      hds_innengabel: 'Kablit e brendshme H.D.S.',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'Kabllo e brendshme H.D.S. Kategoria 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'Kabllo e brendshme H.D.S. Kategoria 7 S/FTP për kabllimin e thjeshtë midis kabineteve në qendrat e të dhënave dhe lidhjeve të shumëfishta nga dhomat e shpërndarjes në nënshpërndarjet. Ndërtimi i kabllos përbëhet nga 24 elemente kabllore të mbrojtura në palë, ku secila palë përbën një element të lidhur. Çdo element lidhës është i pajisur me një shirit numërimi për identifikim më të lehtë. Elementi qendror i zgjatjes fleksibël mbron kabllon veçanërisht mirë gjatë futjes. Mbështjellja përbëhet nga një material fleksibël LSOH.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'Kabllo e brendshme H.D.S. Kategoria 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'Kabllo e brendshme H.D.S. Kategoria 7 S/FTP për kabllimin e thjeshtë midis kabineteve në qendrat e të dhënave dhe lidhjeve të shumëfishta nga dhomat e shpërndarjes në nënshpërndarjet. Ndërtimi i kabllos përbëhet nga 24 elemente kabllore të mbrojtura në palë, ku secila palë përbën një element të lidhur. Çdo element lidhës është i pajisur me një shirit numërimi për identifikim më të lehtë. Elementi qendror i zgjatjes fleksibël mbron kabllon veçanërisht mirë gjatë futjes. Mbështjellja përbëhet nga një material fleksibël LSOH.',
      },
      generic: {
        download: 'Shkarko',
      },
    },
  },

  imprint: {
    headline: 'Gjurmë',
  },

  dataProtection: {
    headline: 'Mbrojtja e të Dhënave',
  },

  footer: {
    dataProtection: 'Mbrojtja e të Dhënave',
    imprint: 'Gjurmë',
    contact: 'Kontaktoni',
  },

  UI: {
    autocomplete: {
      searchError: 'Kërkimi nuk mund të kryhet.',
      searchEmpty: 'Nuk ka rezultate',
    },
    dropdown: {
      emptySelection: '--- ju lutem zgjidhni ---',
    },
  },
}

export const langKey = 'AL'

const Default = {
  translation,
  langKey,
}

export default Default
