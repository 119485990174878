export const translation = {
  header: {
    items: [
      {
        name: 'Početna',
        href: '/',
      },
      {
        name: 'Sustav',
        href: '#system',
      },
      {
        name: 'Modul',
        href: '#module',
      },
      {
        name: 'Montaža',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Jezik',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Jednostavnost',
        text2: 'u povezivanju',
      },
      subtitle: 'Tvoja veza za budućnost',
      cta: 'više',
    },
    handling: {
      title: 'INTUITIVNO RUKOVANJE SUSREĆE NAPREDNU TEHNOLOGIJU',
      vorreiterPrefix: 'Kombinacija preciznosti i funkcionalnog dizajna čini ',
      vorreiterSuffix: ' vodećim izborom u tehnologiji povezivanja.',
      experte: ' nudi učinkovita 360° rješenja koja optimalno povezuju dizajn i izvedbu kako bi smanjila složenost.',
      pionier: ' je pionir u transformaciji radnih prostora, spajajući stručnost s intuitivnim rukovanjem.',
    },
    download: {
      prefix: 'Pregled ',
      suffix: ' - preuzmi sustav',
      cta: 'Preuzimanje',
      href: 'https://fixlink.eu/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Pregled proizvoda',
      title: 'FIXLINK<sup>®</sup> - SUSTAV',
    },
    module: {
      overview: 'Pregled preuzimanja',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT - Certifikati',
          text: 'Vjerujemo u GHMT i njihov Premium Verification Program za neovisno osiguranje kvalitete naših mrežnih proizvoda. Tako garantiramo, temeljem 20 godina iskustva, najviše standarde proizvoda za naše klijente.',
        },
        datasheets: {
          title: 'Tehnički listovi',
          text: 'Ovdje možete pronaći sve potrebne tehničke listove. Preuzmite ih i imajte sve informacije na jednom mjestu.',
        },
        compatibilitySheets: {
          title: 'Liste kompatibilnosti',
          text: '',
        },
        excelTemplates: {
          title: 'Prozori za označavanje kao Excel predlošci',
          text: '',
        },
      },
    },
    video: {
      title: 'UPUTE ZA MONTAŽU',
      text: 'Pogledajte video ili ovdje jednostavno preuzmite PDF',
      manual: {
        title: 'UPUTE ZA MONTAŽU:',
      },
    },
    separator: {
      title: 'Više od samo povezivanja',
      title2: 'Otkrij EasyLan svemir',
      text: 'Otkrijte raznolikost inovativnih mrežnih rješenja na EasyLan.de: Prilagođena vama. Promjena igre za vašu mrežu: Naši sustavi kabliranja i rješenja za upravljanje kabelima.',
      cta: 'više',
    },
    combination: {
      bau: {
        prefix: 'Iskusite maksimalnu prilagodbu i kvalitetu,',
        text: ' EasyLan-ovi nosači modula ',
        suffix: 'izravno iz Europe.',
      },
      intelligent: {
        prefix: 'Vaša mreža',
        text: ' po mjeri ',
        suffix: 's konfigurabilnom EasyLan splicing kutijom',
      },
      fixlink: {
        prefix: 'S našim ',
        suffix: ' proizvodima možete dugoročno poboljšati svoje poslovne zgrade.',
      },
      hds: {
        prefix: 'The',
        text: ' EasyLan<sup>®</sup> H.D.S. - sustav ',
        suffix: 'spaja jednostavnost instalacije i održavanja kao nijedan drugi sustav prtljažnih kabela na tržištu.',
      },
    },
    recommended: {
      titleLabel: 'Preporučeno',
      title: 'fixLink<sup>®</sup> - SUSTAV',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Garancija proizvoda',
        text: [
          'Garancija proizvoda uključuje sve komponente ',
          '- optičkog vlakna ili bakrenog sustava kabliranja, koje je instalirao kvalificirani instalater u skladu s uputama ',
          ' kao i važećim standardima instalacije EN50174.',
        ],
      },
      {
        title: 'Garancija performansi',
        text: [
          'Garancija performansi, uz aplikaciju (Ethernet, Fiber Channel, ATM, itd.) garantira svojstva sustava, uključujući brzine prijenosa (10MBit, 100MBit, 1GBit i 10GBit).',
        ],
      },
      {
        title: 'Garancija aplikacije',
        text: [
          'Garancija aplikacije garantira sve specificirane aplikacije (Ethernet, Fiber Channel, ATM, itd.), koje se temelje na važećim standardima kabliranja prema ISO/IEC 11801, EN50173 i EIA/TIA 568B u vrijeme instalacije.',
        ],
      },
    ],
    end: {
      title: 'POVEZIVANJE NIKAD NIJE BILO OVAKO JEDNOSTAVNO.',
      text: 'Intuitivno rukovanje susreće naprednu tehnologiju; otkrijte besprijekornu vezu budućnosti: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Priključni moduli',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone-modul Kat. 6A ISO/IEC, zaštićen',
        text: 'S fixLink<sup>®</sup> Pro RJ45 Keystone-modulom korisnik dobiva RJ45 utični modul najviše kvalitete. fixLink<sup>®</sup> Pro RJ45 Keystone-modul može se montirati bočnim rezačem u manje od minute. Zbog slobodno dostupne kontaktne zone osigurana je savršena kvaliteta montaže i time osigurana prijenosna učinkovitost. Posebna geometrija kontakta u kombinaciji s 30 μ" zlatnim premazom osigurava 4PPoE prema IEEE 802.3bt u svim uvjetima. Materijal kućišta izrađen je od niklanog tlačno lijevanog cinka i stoga zadovoljava najviše ekološke i mehaničke zahtjeve. U kombinaciji s dizajniranim nosačem modula, moguća je kombinacija sa svim programima prekidača. Isporučuju se u ekološki prihvatljivoj kartonskoj ambalaži.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Kat. 6A ISO/IEC, zaštićen',
        text: 'S fixLink<sup>®</sup> Extenderom mogu se produžiti kablovi bez velikog napora. Osim toga, mogu se reaktivirati i produžiti neaktivni kablovi do sljedeće potrebne točke spajanja. Ograničeni prostor u kabelskom kanalu optimalno se koristi. Extender se jednostavno pričvrsti na kraj instaliranog kabela u kanalu ili na zid. Nakon instalacije, tako produženi kabel može se koristiti za prijenos čak i za 10-gigabitni Ethernet i podržava PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug Kat. 6A ISO/IEC zaštićen',
        text: 'S fixLink<sup>®</sup> RJ45 utičnim modulom korisnik dobiva polje koje se može montirati s RJ45 utičnim modulom najviše kvalitete i fleksibilnosti. Posebna geometrija kontakta u kombinaciji s 50 μ" zlatnim premazom osigurava 4PPoE prema IEEE 802.3bt u svim uvjetima. Materijal kućišta izrađen je od niklanog tlačno lijevanog cinka i stoga zadovoljava najviše ekološke i mehaničke zahtjeve. Različite certifikacije (aktualni popis: www.easylan.de) od neovisnih ispitnih laboratorija zaokružuju najvišu kvalitetu fixLink<sup>®</sup> sustava.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'RJ45 Keystone-modul Kat. 6A EIA/TIA, nezaštićen',
        text: 'S fixLink<sup>®</sup> RJ45 Keystone-modulom (PoE+ sposobnim) korisnik dobiva RJ45 utični modul najviše kvalitete. fixLink<sup>®</sup> RJ45 Keystone-modul može se montirati bočnim rezačem u manje od minute. Zbog slobodno dostupne kontaktne zone osigurana je savršena kvaliteta montaže i time osigurana prijenosna učinkovitost. Materijal kućišta izrađen je od polikarbonata i stoga zadovoljava najviše ekološke i mehaničke zahtjeve. U kombinaciji s dizajniranim nosačem modula, moguća je kombinacija sa svim programima prekidača. Različite certifikacije od neovisnih ispitnih laboratorija zaokružuju najvišu kvalitetu fixLink<sup>®</sup> SL Keystone-modula. Isporučuju se u ekološki prihvatljivoj kartonskoj ambalaži.',
      },
      fixLink_SL_02: {
        name: 'SL RJ45 Keystone-modul Kat. 6A ISO/IEC, zaštićen',
        text: 'S fixLink<sup>®</sup> SL RJ45 Keystone-modulom korisnik dobiva RJ45 utični modul najviše kvalitete. fixLink<sup>®</sup> SL RJ45 Keystone-modul može se montirati bočnim rezačem u manje od minute. Zbog slobodno dostupne kontaktne zone osigurana je savršena kvaliteta montaže i time osigurana prijenosna učinkovitost. Za 360° uzemljenje potrebno je samo zatvoriti integrirani klin, čime se izbjegava dugotrajno postavljanje kabelskih vezica. Posebna geometrija kontakta u kombinaciji s 50 μ" zlatnim premazom osigurava 4PPoE prema IEEE 802.3bt u svim uvjetima. Materijal kućišta izrađen je od niklanog tlačno lijevanog cinka i stoga zadovoljava najviše ekološke i mehaničke zahtjeve. U kombinaciji s dizajniranim nosačem modula, moguća je kombinacija sa svim programima prekidača. Različite certifikacije od neovisnih ispitnih laboratorija zaokružuju najvišu kvalitetu fixLink<sup>®</sup> SL Keystone-modula. Isporučuju se u ekološki prihvatljivoj kartonskoj ambalaži.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Izvještaj o ispitivanju 4PoE prema DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'SL RJ45 Keystone-modul kutni Kat. 6A ISO/IEC, zaštićen',
        text: 'S fixLink<sup>®</sup> SL RJ45 Keystone-modulom korisnik dobiva RJ45 utični modul najviše kvalitete, koji je posebno razvijen za aplikacije s ograničenim prostorom. Zbog 75° izlaza kabela, modul je posebno pogodan za upotrebu u podatkovnim utičnicama i ugradbama u šuplje zidove. Minimalna ugradbena dubina od 27 mm omogućava gotovo neograničenu raznolikost aplikacija. fixLink<sup>®</sup> SL RJ45 Keystone-modul može se montirati bočnim rezačem u manje od minute. Zbog slobodno dostupne kontaktne zone osigurana je savršena kvaliteta montaže i time osigurana prijenosna učinkovitost. Posebna geometrija kontakta u kombinaciji s 50 μ" zlatnim premazom osigurava PoE+ prema IEEE 80.3at u svim uvjetima. Materijal kućišta izrađen je od niklanog tlačno lijevanog cinka i stoga zadovoljava najviše ekološke i mehaničke zahtjeve. U kombinaciji s dizajniranim nosačem modula, moguća je kombinacija sa svim programima prekidača. Različite certifikacije od neovisnih ispitnih laboratorija zaokružuju najvišu kvalitetu fixLink<sup>®</sup> SL Keystone-modula. Isporučuju se u ekološki prihvatljivoj kartonskoj ambalaži.',
        ghmtCertificates: [
          {
            name: 'GHMT PVP Kat.6A certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Alati i dodaci',
        text: [
          'Alat za skidanje izolacije za uklanjanje vanjskog omotača i metalnih folija podatkovnog kabela. Plava boja.',
          'Bakarna traka za fiksiranje ekrana. Prethodno izrezana na 35 x 9 mm. Jedinica pakiranja: 20 komada/vrećica 1500 komada/rola',
          'Obojeni poklopci za zaštitu od prašine za preLink® SL i fixLink® SL Keystone module.',
          'Jedinica pakiranja 50 komada/plastična vrećica',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP certifikat Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Instalacijski kabel',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Podatkovni kabel EasyLan® Gold S/FTP AWG22/1 1500 MHz Kat. 7A',
        text: [
          'Podatkovni kabel za prijenos analognih i digitalnih signala pri kabliranju u primarnom, sekundarnom i tercijarnom području. Pogodan za primjene do klase FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'GHMT Permanent Link certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Tehnički list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Podatkovni kabel EasyLan® Silber S/FTP AWG23/1 1200 MHz Kat. 7A',
        text: [
          'Podatkovni kabel za prijenos analognih i digitalnih signala pri kabliranju u primarnom, sekundarnom i tercijarnom području. Pogodan za primjene do klase FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP certifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Tehnički list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Podatkovni kabel EasyLan® Bronze S/FTP AWG23/1 1000 MHz Kat. 7',
        text: 'Podatkovni kabel za prijenos analognih i digitalnih signala pri kabliranju u primarnom, sekundarnom i tercijarnom području. Pogodan za primjene do klase FA.',
        datasheets: [
          {
            name: 'Tehnički list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Podatkovne utičnice',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'AP kućište i okvir za navedene nosače modula',
        text: [
          'Na površinu montirano kućište IP20, 80x80x42mm za ugradnju EasyLan nosača modula',
          'Međuokvir za AP kućište za povećanje visine za 10mm',
          'Jednostruki okvir 80x80mm',
          'Dvostruki okvir 80x150mm',
        ],
        datasheets: [
          {
            name: 'Na površinu montirano kućište',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Proširenje dubine AP kućišta',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Nosač modula SL, izlaz pod 45° UP, BK, AP, BT jednostruki, dvostruki, trostruki',
        text: 'Nosači modula SL posebno su pogodni za ugradnju u kabelske kanale (EK), ugradnju ispod žbuke (UP) i za montažu na površinu (AP). Uklanjanjem dviju montažnih držača također je moguća montaža u podne kutije (BT). Nosač modula SL može sigurno i trajno prihvatiti jedan do tri EasyLan keystone-a. Ima veliki, centralno smješten prozor za označavanje za jednostavno označavanje. Središnja ploča (50x50 mm) i okvir (80x80 mm) izrađeni su od UV otpornog i izuzetno otpornog na lomljenje plastičnog materijala. Time je osigurana dugotrajna i postojana boja.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Dizajnirani nosač modula SL, izlaz pod 45° UP, BK, AP, BT jednostruki, dvostruki',
        text: 'Dizajnirani nosač modula izrađen je od okvira od tlačno lijevanog cinka. Pogodan je za ugradnju u kabelske kanale (EK), za ugradnju ispod žbuke (UP) ili pomoću zasebnog okvira također za montažu na površinu (AP). Uklanjanjem dviju montažnih držača također je moguća montaža u podne kutije (BT). Dizajnirana podatkovna utičnica može se kombinirati s gotovo svim dostupnim programima prekidača na tržištu. Zbog kompatibilnosti s UAE dizajnirana podatkovna utičnica ne nudi prostor za zaštitni poklopac od prašine.',
        datasheets: [
          {
            name: 'Jednostruki/dvostruki keystone, bez središnje ploče',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: 'Dvostruki keystone, sa središnjom pločom 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: 'Jednostruki sa središnjom pločom',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Nosač modula IP44 na površini, jednostruki, dvostruki',
        text: 'Robusno na površinu montirano kućište je putem adaptera pogodno za ugradnju dva preLink<sup>®</sup> / fixLink<sup>®</sup> keystone-modula. Za uvod dva kabela promjera od 5,0 - 9,5mm, na stražnjoj strani kućišta nalaze se dva lako probušiva otvora. Nosač modula postiže zaštitu IP20 u umetnutom stanju, a u zatvorenom stanju zaštitu IP44.',
      },
      DoseUPleer2xKS: {
        name: 'Nosač modula ravan izlaz, dvostruki',
        text: 'Nosač modula s ravnim keystone-izlazom izrađen je od okvira od tlačno lijevanog cinka i pogodan je za ugradnju u kabelske kanale (EK), za ugradnju ispod žbuke (UP), ili pomoću zasebnog okvira također za montažu na površinu (AP).',
      },
      bodentank: 'Podne kutije',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'OBO/Ackermann sustavi podnih kutija UT3, UT4',
        text: 'Nosač ploče zamjenjuje nosače uređaja i tako nudi maksimalan prostor za uvođenje kabela. Koso uvođenje i odvođenje omogućuje sigurnu vožnju kabela čak i kod vrlo niskih međukatova. Moguća je kombinacija s pločama za ugradnju za 4x preLink<sup>®</sup>/fixLink<sup>®</sup> modula ili 1x H.D.S. Ploče za ugradnju pričvršćuju se s dvije vijka na nosač ploče, nepotrebna polja nosača ploče mogu se prekriti zaštitnim pločama protiv prašine.',
        datasheets: [
          {
            name: 'Nosač ploče za UT3 bez ploče za ugradnju',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Nosač ploče za UT4 bez ploče za ugradnju',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'OBO/Ackermann nosač podne kutije Basic UT3, UT4',
        text: 'Nosač podne kutije za keystone zamjenjuje nosač uređaja i tako nudi maksimalan prostor za uvođenje kabela. Koso uvođenje i odvođenje omogućuje sigurnu vožnju kabela čak i kod vrlo niskih međukatova. Ploče za ugradnju pričvršćuju se s dvije vijka na nosač ploče, nepotrebni izlazi za keystone nosača podne kutije mogu se prekriti zaštitnim pločama protiv prašine. Pomoću adaptera nosač podne kutije može se koristiti i za nosače uređaja Electraplan.',
        datasheets: [
          {
            name: 'Nosač podne kutije UT3 za do 6 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Nosač podne kutije UT4 za do 9 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Nosač podne kutije UT4 za do 12 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'PUK nosač podne kutije Basic UT4',
        text: 'Nosač podne kutije za keystone zamjenjuje nosač uređaja i tako nudi maksimalan prostor za uvođenje kabela. Koso uvođenje i odvođenje omogućuje sigurnu vožnju kabela čak i kod vrlo niskih međukatova. Ploče za ugradnju pričvršćuju se s dvije vijka na nosač ploče, nepotrebni izlazi za keystone nosača podne kutije mogu se prekriti zaštitnim pločama protiv prašine. Nosači podne kutije kompatibilni su sa sustavom podnih kutija PUK.',
        datasheets: [
          {
            name: 'Nosač podne kutije UT4 za do 9 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Nosač podne kutije UT4 za do 12 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'OBO/Ackermann umetci za sustave podnih kutija UT3, UT4, pribor',
        text: 'Umetci za module nisu praškasto obloženi i pocinčani su zbog uzemljenja. Ploče za ugradnju pričvršćuju se s dvije vijka na nosač ploče, nepotrebna polja nosača ploče mogu se prekriti zaštitnim pločama protiv prašine.',
        datasheets: [
          {
            name: 'Ploča za ugradnju za 4x RJ45 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Ploča za ugradnju za 3x RJ45 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Adapter za DIN šinu',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Kompaktno kućište modula za DIN šinu za 1 modul',
        text: 'Kućište modula za DIN šinu za preLink<sup>®</sup> / fixLink<sup>®</sup> module, pogodno za razvodne ormare bez pokrova za osigurače.',
        datasheets: [
          {
            name: 'Kompaktno kućište modula za DIN šinu za 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Keystone-F-spojnica i prazni keystone-modul',
        text: 'F-spojnica i prazna spojnica kao zamjena za module\nF-spojnica služi za integraciju koaksijalnih kabela u preLink<sup>®</sup> / fixLink<sup>®</sup> patch paneleme i programe utičnica. Prazna spojnica preLink<sup>®</sup>/fixLink<sup>®</sup> može se koristiti u razdjelniku i u utičnicama za pokrivanje neiskorištenih portova.\n\nPrazni modul za SC-S odnosno LC-D kao zamjena za module\nPrazni modul za SC-S odnosno LC-D spojnice u razdjelniku ili utičnici.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Kućište modula za DIN šinu za 1 modul',
        text: 'Kućište modula za DIN šinu za preLink<sup>®</sup> / fixLink<sup>®</sup> module, pogodno za razvodne ormare s pokrovom za osigurače.',
        datasheets: [
          {
            name: 'Kućište modula za DIN šinu za 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panel',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1HE za 24 keystone-modula ravan izlaz',
        text: 'Razdjelni panel 19" 1HE može se opremiti s do 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 keystone-modula. Sastoji se od dvije razine, tako da su moduli poravnati s prednjom pločom. Razdjelni panel može se naručiti u nehrđajućem čeliku ili obojenom željeznom limu.\n\nMontažni materijal i keystone-moduli nisu uključeni u isporuku.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1HE za 48 keystone-modula ravan izlaz',
        text: 'Razdjelni panel 19" 1HE može se opremiti s do 48 fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 keystone-modula. Sastoji se od dvije razine, tako da su moduli poravnati s prednjom pločom. Razdjelni panel je izrađen od obojenog željeznog lima.\nMontažni materijal i keystone-moduli nisu uključeni u isporuku.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0,5HE za 24 keystone-modula ravan izlaz',
        text: 'Razdjelni panel preLink<sup>®</sup> / fixLink<sup>®</sup> 19" 0,5HE može se opremiti s do 24 fixLink<sup>®</sup> RJ45 keystone-modula. Oni su poravnati s prednjom pločom.\n\nMontažni materijal i keystone-moduli nisu uključeni u isporuku.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1HE za 24 keystone-modula uključujući prozor za označavanje',
        text: 'Razdjelni panel 19" 1HE može se opremiti s do 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 keystone-modula. Sastoji se od dvije razine, tako da su moduli poravnati s prednjom pločom. Razdjelni panel može se naručiti u nehrđajućem čeliku ili obojenom željeznom limu. Dodatno postoji mogućnost označavanja pojedinih portova pomoću priloženog, izbušenog lista za označavanje 12x33.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Kutni panel 30° 19" 1HE za 24 keystone-modula',
        text: 'Kod preLink<sup>®</sup> / fixLink<sup>®</sup> kutnog panela korisnik ima mogućnost umetanja 24 keystone-modula pod kutom od 30° u panel, tako da priključeni patch-kablovi automatski vode do strane ormara i trebaju biti pričvršćeni samo sa strane patch-razine. Time se može odustati od dodatnih panela za upravljanje kablovima za vođenje kabela. U radu, kutni paneli s ravnom prednjom stranom i koso postavljenim patch-kablovima zahtijevaju manje prostora prema naprijed nego uobičajeni patch-paneli sa vertikalno ugrađenim portovima.\n\nMontažni materijal i keystone-moduli nisu uključeni u isporuku.',
      },
      baugruppentraeger: 'Nosači modula',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Nosač modula 19" 3HE za ugradnju modula za umetanje',
        text: 'Nosač modula 19" 3HE za ugradnju 7TE umetnutih modula u bakru i vlaknima. Fiksiraju se s 2 vijka s prednje strane panela na nosač modula. Moduli za umetanje mogu se potpuno izvući iz nosača modula. Na nosač modula može se pričvrstiti maksimalno 12x 7TE umetnutih modula.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Keystone modul za umetanje za nosač modula',
        text: 'Keystone modul za umetanje za opremanje nosača modula. Keystone modul za umetanje može se opremiti s do 6 preLink<sup>®</sup>- odnosno fixLink<sup>®</sup>-keystone-modula. Keystone modul za umetanje sastoji se od dvije razine, tako da su moduli poravnati s prednjom pločom. Hvatanje kabela odvija se izravno na kućištu modula za umetanje. Može se pričvrstiti 6 pojedinačnih kabela ili 1 trunk kabel.\n\nMontažni materijal i keystone-moduli nisu uključeni u isporuku.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Nosač modula (BGT) 19" 1HE za ugradnju modula za umetanje',
        text: 'Nosač modula (BGT) za ugradnju 7TE umetnutih modula u bakru i optičkim vlaknima. Fiksiraju se s 2 vijka s prednje strane panela na nosač modula. Moduli za umetanje mogu se potpuno izvući iz nosača modula. Na nosač modula može se pričvrstiti maksimalno 3x 7TE umetnutih modula (=18 portova).\n\nMontažni materijal i keystone-moduli nisu uključeni u isporuku.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Bočni nosač modula 3HE/14TE za ugradnju modula za umetanje',
        text: 'Bočni nosač modula (S-BGT) za ugradnju 7TE umetnutih modula u bakru i optičkim vlaknima. Moduli za umetanje fiksiraju se s 2 vijka s prednje strane panela na nosač modula. Moduli za umetanje mogu se potpuno izvući iz nosača modula. Na nosač modula može se pričvrstiti maksimalno 2x 7TE umetnutih modula (=12 portova). Fiksiranje bočnog nosača modula (S-BGT) odvija se na bočnoj strani ormara. To omogućuje jednostavnu i brzu montažu. Bočna montaža ormara omogućuje realizaciju prostora za uštedu prostora, posebno klimatski prihvatljivo kabliranje, bez ometanja protoka zraka.\n\nMontažni materijal i keystone-moduli nisu uključeni u isporuku.',
      },
      consolidation_point_panel: 'Ploča konsolidacijske točke, DIN tračnice itd.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Podni panel konsolidacijske točke 19" odnosno 10" 1HE',
        text: 'Podni panel konsolidacijske točke s izmjenjivim prednjim pločama za smještaj različitih priključaka u bakru i optičkim vlaknima. Opcionalno se podni panel konsolidacijske točke može osigurati zaključavanjem protiv neovlaštenog pristupa.\n\nMontažni materijal i keystone-moduli nisu uključeni u isporuku.',
        datasheets: [
          {
            name: 'Panel konsolidacijske točke 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Panel konsolidacijske točke 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Panel za konsolidacijsku točku za 4-12 keystone-modula',
        text: 'Panel za konsolidacijsku točku za preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 keystone module, za upotrebu kao panel za konsolidaciju, izravnu montažu ili također pomoću montažnog seta na DIN šine u elektrodistribucijama. Panel za konsolidacijsku točku sastoji se od dvije razine. Moduli su poravnati s prednjom pločom.\n\nMontažni materijal i keystone moduli nisu uključeni u isporuku.',
      },
      trunkkabel: 'Trunk kabeli',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'SL CTBC sustav za raspodjelu Copper-Trunk-Bundled-Cable, klasa EA ISO/IEC',
        text: 'CTBC sustav za raspodjelu pogodan je za fleksibilnu upotrebu u strukturiranom kabliranju zgrada i podatkovnim centrima. Kablovi spojeni u pletenu cijev tvornički su opremljeni fixLink<sup>®</sup>-keystone modulima i testirani. Spajanje se može obaviti s instalacijskim kablovima kao i s fleksibilnim kablovima. Maksimalna duljina spojenog kabela iznosi do 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'SL CTCC sustav za raspodjelu Copper-Trunk-Compact-Cable, klasa EA ISO/IEC',
        text: 'CTCC sustav za raspodjelu pogodan je zbog svog minimalnog promjera kabela i stoga male požarne opterećenosti za fleksibilnu upotrebu u strukturiranom kabliranju zgrada i podatkovnim centrima. Maksimalna duljina višestrukog kabela ovisi o vrsti i iznosi 60-90 m. Sustav za raspodjelu kao 10-Gbit rješenje za podatkovne centre ispunjava najmanje zahtjeve klase EA.',
      },
      hds_innengabel: 'H.D.S. unutarnji kabeli',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'H.D.S. unutarnji kabel Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'H.D.S. unutarnji kabel Kat. 7 S/FTP za jednostavno kabliranje između ormara u podatkovnim centrima i višestruke veze između razvodnih soba i poddistribucija. Konstrukcija kabela sastoji se od 24 para zaštićenih kabelskih elemenata, pri čemu su 4 para grupirana u jedan element upletenja. Svaki element upletenja označen je brojčanom trakom za lakšu identifikaciju. Fleksibilni središnji element za rasterećenje naprezanja posebno štiti kabel tijekom uvlačenja. Plašt se sastoji od fleksibilnog LSOH materijala.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'H.D.S. unutarnji kabel Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'H.D.S. unutarnji kabel Kat. 7 S/FTP za jednostavno kabliranje između ormara u podatkovnim centrima i višestruke veze između razvodnih soba i poddistribucija. Konstrukcija kabela sastoji se od 24 para zaštićenih kabelskih elemenata, pri čemu su 4 para grupirana u jedan element upletenja. Svaki element upletenja označen je brojčanom trakom za lakšu identifikaciju. Fleksibilni središnji element za rasterećenje naprezanja posebno štiti kabel tijekom uvlačenja. Plašt se sastoji od fleksibilnog LSOH materijala.',
      },
      generic: {
        download: 'Preuzimanje',
      },
    },
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Zaštita podataka',
  },

  footer: {
    dataProtection: 'Zaštita podataka',
    imprint: 'Impressum',
    contact: 'Kontakt',
  },

  UI: {
    autocomplete: {
      searchError: 'Pretraga nije uspjela.',
      searchEmpty: 'Nema rezultata',
    },
    dropdown: {
      emptySelection: '--- molimo odaberite ---',
    },
  },
}

export const langKey = 'HR'

const Default = {
  translation,
  langKey,
}

export default Default
