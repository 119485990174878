export const translation = {
  header: {
    items: [
      {
        name: 'الصفحة الرئيسية',
        href: '/',
      },
      {
        name: 'النظام',
        href: '#system',
      },
      {
        name: 'وحدة',
        href: '#module',
      },
      {
        name: 'التجميع',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'اللغة',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'البساطة',
        text2: 'في الاتصال',
      },
      subtitle: 'رابطك للمستقبل',
      cta: 'المزيد',
    },
    handling: {
      title: 'التعامل البديهي يلتقي بالتكنولوجيا المتقدمة',
      vorreiterPrefix: 'الجمع بين الدقة والتصميم الوظيفي يجعل ',
      vorreiterSuffix: ' الخيار الرائد في تكنولوجيا الشبكات.',
      experte: ' يقدم حلولاً فعالة 360°، تجمع بين التصميم والأداء الأمثل لتقليل التعقيد.',
      pionier: ' رائد في إعادة تشكيل أماكن العمل، يجمع بين الخبرة والتعامل البديهي.',
    },
    download: {
      prefix: 'نظرة عامة ',
      suffix: ' - تحميل النظام',
      cta: 'تنزيل',
      href: 'https://fixlink.eu/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'نظرة عامة على المنتج',
      title: 'FIXLINK<sup>®</sup> - النظام',
    },
    module: {
      overview: 'تحميل نظرة عامة',
      accordion: {
        ghmtCertificates: {
          title: 'شهادات GHMT',
          text: 'نعتمد على GHMT وبرنامج التحقق المتميز الخاص بهم لضمان الجودة المستقلة لمنتجات شبكتنا. نضمن بذلك، استنادًا إلى 20 عامًا من الخبرة، أعلى معايير الجودة لعملائنا.',
        },
        datasheets: {
          title: 'أوراق البيانات',
          text: 'هنا تجد جميع أوراق البيانات اللازمة. قم بتنزيلها واحصل على جميع المعلومات في لمحة.',
        },
        compatibilitySheets: {
          title: 'قوائم التوافق',
          text: '',
        },
        excelTemplates: {
          title: 'نافذة التسمية كقالب Excel',
          text: '',
        },
      },
    },
    video: {
      title: 'دليل التجميع',
      text: 'شاهد الفيديو أو قم بتنزيل ملف PDF هنا',
      manual: {
        title: 'أدلة التجميع:',
      },
    },
    separator: {
      title: 'أكثر من مجرد اتصال',
      title2: 'اكتشف عالم EasyLan',
      text: 'اكتشف مجموعة متنوعة من حلول الشبكات المبتكرة على EasyLan.de: مصممة خصيصًا لك. مغير اللعبة لشبكتك: أنظمة الكابلات وحلول إدارة الكابلات الخاصة بنا.',
      cta: 'المزيد',
    },
    combination: {
      bau: {
        prefix: 'جرب مع التخصيص والجودة القصوى،',
        text: ' حوامل EasyLan ',
        suffix: 'مباشرة من أوروبا.',
      },
      intelligent: {
        prefix: 'شبكتك',
        text: ' مصممة خصيصًا ',
        suffix: 'مع صندوق EasyLan القابل للتكوين',
      },
      fixlink: {
        prefix: 'مع منتجاتنا ',
        suffix: 'يمكنك تحسين مباني المكاتب الخاصة بك على المدى الطويل.',
      },
      hds: {
        prefix: 'النظام',
        text: ' EasyLan<sup>®</sup> H.D.S. ',
        suffix: 'يجمع بين سهولة التركيب والصيانة بشكل لا مثيل له في أي نظام كابلات في السوق.',
      },
    },
    recommended: {
      titleLabel: 'موصى به',
      title: 'fixLink<sup>®</sup> - النظام',
      text: 'لوريم إيبسوم دولار سيت أميت، كونسيكتيتور أديبيسينغ إليت. أينيان كومودو ليجولا إيجيت دولار. أينيان ماسا. كوم سوسيس ناتوكوي بيناتيبوس إي ماجنس ديس بارتورينت مونتيس، ناسكيريد ريديكولوس موس.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'لوريم إيبسوم دولار ميت' },
        { imgName: 'b', name: 'FIXLINK B', text: 'لوريم إيبسوم دولار ميت' },
        { imgName: 'c', name: 'FIXLINK C', text: 'لوريم إيبسوم دولار ميت' },
        { imgName: 'd', name: 'FIXLINK D', text: 'لوريم إيبسوم دولار ميت' },
        { imgName: 'e', name: 'FIXLINK E', text: 'لوريم إيبسوم دولار ميت' },
      ],
    },
    guarantee: [
      {
        title: 'ضمان المنتج',
        text: [
          'يشمل ضمان المنتج جميع مكونات ',
          '- نظام الكابلات الألياف الضوئية أو النحاسية، التي يتم تركيبها واختبارها بواسطة فني مؤهل وفقًا لمتطلبات ',
          ' وكذلك معايير التثبيت EN50174 المعمول بها.',
        ],
      },
      {
        title: 'ضمان الأداء',
        text: [
          'يضمن ضمان الأداء، بالإضافة إلى التطبيق (Ethernet، Fiber Channel، ATM، إلخ)، خصائص النظام بما في ذلك سرعات النقل (10MBit، 100MBit، 1GBit و 10GBit).',
        ],
      },
      {
        title: 'ضمان التطبيق',
        text: [
          'يضمن ضمان التطبيق جميع التطبيقات المحددة (Ethernet، Fiber Channel، ATM، إلخ)، التي تستند إلى معايير الكابلات المعمول بها في وقت التثبيت وفقًا لـ ISO/IEC 11801، EN50173 و EIA/TIA 568B.',
        ],
      },
    ],
    end: {
      title: 'لم تكن الروابط أسهل من قبل.',
      text: 'التعامل البديهي يلتقي بالتكنولوجيا المتقدمة؛ اكتشف الاتصال السلس للمستقبل: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'وحدات الاتصال',
      csm_FixLink_Pro03_b20813b518: {
        name: 'وحدة RJ45 Keystone-Pro الفئة 6A ISO/IEC، محمي',
        text: 'مع وحدة fixLink<sup>®</sup> Pro RJ45 Keystone، يحصل المستخدم على وحدة RJ45 عالية الجودة. يمكن تركيب وحدة fixLink<sup>®</sup> Pro RJ45 Keystone باستخدام قاطع جانبي في أقل من دقيقة. بفضل منطقة الاتصال المفتوحة، يتم ضمان جودة التجميع المثالية وبالتالي أداء النقل الآمن. تضمن الهندسة الخاصة للاتصال بالاقتران مع طبقة ذهبية بسماكة 30 ميكرو" 4PPoE وفقًا لـ IEEE 802.3bt تحت جميع الظروف. يتكون مادة الغلاف من سبائك الزنك المطلية بالنيكل وبالتالي تفي بأعلى متطلبات البيئة والميكانيكا. يمكن الجمع بين وحدة الاستقبال المصممة وجميع برامج التبديل. يتم تسليمها في عبوات كرتونية صديقة للبيئة.',
        ghmtCertificates: [
          {
            name: 'شهادة PVP الفئة 6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'شهادة الرابط الدائم',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'موسع الفئة 6A ISO/IEC، محمي',
        text: 'مع موسع fixLink<sup>®</sup>، يمكن تمديد الكابلات بسهولة. بالإضافة إلى ذلك، يمكن إعادة تفعيل الكابلات المهجورة وتمديدها إلى نقطة الاتصال التالية المطلوبة. يتم استخدام المساحة الضيقة في قناة الكابلات بشكل مثالي. يتم تثبيت الموسع ببساطة في نهاية الكابل المثبت في القناة أو على الحائط. بعد التثبيت، يمكن استخدام الكابل الممدد حتى الطول الأقصى للرابط لنقل Ethernet بسرعة 10 جيجابت ويدعم PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug الفئة 6A ISO/IEC محمي',
        text: 'مع وحدة توصيل RJ45 fixLink<sup>®</sup>، يحصل المستخدم على وحدة توصيل RJ45 قابلة للتجميع في الموقع بجودة ومرونة عالية. تضمن الهند سة الخاصة للاتصال بالاقتران مع طبقة ذهبية بسماكة 50 ميكرو" 4PPoE وفقًا لـ IEEE 80.3bt تحت جميع الظروف. يتكون مادة الغلاف من سبائك الزنك المطلية بالنيكل وبالتالي تفي بأعلى متطلبات البيئة والميكانيكا. مجموعة متنوعة من الشهادات (قائمة حالية: www.easylan.de) من مختبرات اختبار مستقلة تؤكد أعلى معايير الجودة لنظام fixLink<sup>®</sup>.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'وحدة RJ45 Keystone الفئة 6A EIA/TIA، غير محمي',
        text: 'مع وحدة fixLink<sup>®</sup> RJ45 Keystone (قادر على PoE+)، يحصل المستخدم على وحدة RJ45 عالية الجودة. يمكن تركيب وحدة fixLink<sup>®</sup> RJ45 Keystone باستخدام قاطع جانبي في أقل من دقيقة. بفضل منطقة الاتصال المفتوحة، يتم ضمان جودة التجميع المثالية وبالتالي أداء النقل الآمن. يتكون مادة الغلاف من البولي كربونات وتفي بأعلى متطلبات البيئة والميكانيكا. يمكن الجمع بين وحدة الاستقبال المصممة وجميع برامج التبديل. مجموعة متنوعة من الشهادات من مختبرات اختبار مستقلة تؤكد أعلى معايير الجودة لوحدة fixLink<sup>®</sup> SL Keystone. يتم تسليمها في عبوات كرتونية صديقة للبيئة.',
      },
      fixLink_SL_02: {
        name: 'وحدة SL RJ45 Keystone الفئة 6A ISO/IEC، محمي',
        text: 'مع وحدة fixLink<sup>®</sup> SL RJ45 Keystone، يحصل المستخدم على وحدة RJ45 عالية الجودة. يمكن تركيب وحدة fixLink<sup>®</sup> SL RJ45 Keystone باستخدام قاطع جانبي في أقل من دقيقة. بفضل منطقة الاتصال المفتوحة، يتم ضمان جودة التجميع المثالية وبالتالي أداء النقل الآمن. لإزالة الحماية 360°، يكفي فقط تثبيت الوتد المدمج، لا حاجة لوضع رابط الكابل بشكل مضيع للوقت. تضمن الهندسة الخاصة للاتصال بالاقتران مع طبقة ذهبية بسماكة 50 ميكرو" 4PPoE وفقًا لـ IEEE 802.3bt تحت جميع الظروف. يتكون مادة الغلاف من سبائك الزنك المطلية بالنيكل وبالتالي تفي بأعلى متطلبات البيئة والميكانيكا. يمكن الجمع بين وحدة الاستقبال المصممة وجميع برامج التبديل. مجموعة متنوعة من الشهادات من مختبرات اختبار مستقلة تؤكد أعلى معايير الجودة لوحدة fixLink<sup>®</sup> SL Keystone. يتم تسليمها في عبوات كرتونية صديقة للبيئة.',
        ghmtCertificates: [
          {
            name: 'شهادة PVP الفئة 6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'تقرير اختبار 4PoE وفقًا لـ DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'شهادة GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'وحدة SL RJ45 Keystone بزاوية الفئة 6A ISO/IEC، محمي',
        text: 'مع وحدة fixLink<sup>®</sup> SL RJ45 Keystone، يحصل المستخدم على وحدة RJ45 عالية الجودة، التي تم تطويرها خصيصًا للتطبيقات ذات المساحة المحدودة. بفضل خروج الكابل بزاوية 75°، تكون الوحدة مناسبة بشكل خاص للاستخدام في المقابس الشبكية والتركيبات الجوفاء. يسمح العمق الأدنى للتثبيت البالغ 27 مم بتنوع غير محدود تقريبًا في التطبيقات. يمكن تركيب وحدة fixLink<sup>®</sup> SL RJ45 Keystone باستخدام قاطع جانبي في أقل من دقيقة. بفضل منطقة الاتصال المفتوحة، يتم ضمان جودة التجميع المثالية وبالتالي أداء النقل الآمن. تضمن الهندسة الخاصة للاتصال بالاقتران مع طبقة ذهبية بسماكة 50 ميكرو" PoE+ وفقًا لـ IEEE 80.3at تحت جميع الظروف. يتكون مادة الغلاف من سبائك الزنك المطلية بالنيكل وبالتالي تفي بأعلى متطلبات البيئة والميكانيكا. يمكن الجمع بين وحدة الاستقبال المصممة وجميع برامج التبديل. مجموعة متنوعة من الشهادات من مختبرات اختبار مستقلة تؤكد أعلى معايير الجودة لوحدة fixLink<sup>®</sup> SL Keystone. يتم تسليمها في عبوات كرتونية صديقة للبيئة.',
        ghmtCertificates: [
          {
            name: 'شهادة GHMT PVP الفئة 6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'شهادة GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'أدوات وإكسسوارات',
        text: [
          'أداة لتعرية الكابلات لإزالة الغلاف الخارجي والرقائق المعدنية لكابل البيانات. لون أزرق.',
          'شريط نحاسي لتثبيت الغلاف الواقي. مقطوع مسبقًا إلى 35 × 9 مم. وحدة التعبئة: 20 قطعة/كيس 1500 قطعة/لفة',
          'أغطية حماية من الغبار ملونة للوحدات preLink® SL وfixLink® SL Keystone.',
          'وحدة التعبئة 50 قطعة/كيس بلاستيكي',
        ],
        ghmtCertificates: [
          {
            name: 'شهادة GHMT PVP الفئة 6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'شهادة GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'كابل تركيب',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'كابل بيانات EasyLan® Gold S/FTP AWG22/1 1500 ميجاهرتز الفئة 7A',
        text: ['كابل بيانات لنقل الإشارات التناظرية والرقمية في الكابلات في المناطق الأولية والثانوية والثالثية. مناسب لتطبيقات حتى الفئة FA.'],
        ghmtCertificates: [
          {
            name: 'شهادة GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'شهادة GHMT Permanent Link',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'ورقة البيانات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'كابل بيانات EasyLan® Silber S/FTP AWG23/1 1200 ميجاهرتز الفئة 7A',
        text: ['كابل بيانات لنقل الإشارات التناظرية والرقمية في الكابلات في المناطق الأولية والثانوية والثالثية. مناسب لتطبيقات حتى الفئة FA.'],
        ghmtCertificates: [
          {
            name: 'شهادة GHMT PVP',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'ورقة البيانات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'كابل بيانات EasyLan® Bronze S/FTP AWG23/1 1000 ميجاهرتز الفئة 7',
        text: 'كابل بيانات لنقل الإشارات التناظرية والرقمية في الكابلات في المناطق الأولية والثانوية والثالثية. مناسب لتطبيقات حتى الفئة FA.',
        datasheets: [
          {
            name: 'ورقة البيانات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'مآخذ البيانات',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'غلاف وإطار للتثبيت السطحي للوحدات المذكورة',
        text: [
          'غلاف للتثبيت السطحي IP20، 80x80x42mm لتركيب وحدات EasyLan',
          'إطار لتوسيع عمق الغلاف السطحي بمقدار 10mm',
          'إطار تغطية 1-فجوة 80x80mm',
          'إطار تغطية 2-فجوة 80x150mm',
        ],
        datasheets: [
          {
            name: 'غلاف التثبيت السطحي',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'توسيع عمق غلاف التثبيت السطحي',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'وحدة استقبال SL، مخرج بزاوية 45° UP، BK، AP، BT 1-فجوة، 2-فجوة، 3-فجوة',
        text: 'وحدات الاستقبال SL مناسبة بشكل خاص للتثبيت في قناة الكابلات (EK)، التثبيت الجوفي (UP) وللتثبيت السطحي (AP). من خلال كسر اثنين من علامات التثبيت، يمكن أيضًا استخدامه لتثبيت الخزانات الأرضية (BT). يمكن لوحدة الاستقبال SL تأمين استقبال واحد إلى ثلاثة Keystones من EasyLan بشكل دائم. لديها نافذة تسمية كبيرة مركزية لتحديد بسيط. تتكون اللوحة المركزية (50x50 مم) والإطار (80x80 مم) من بلاستيك غير حساس للأشعة فوق البنفسجية وعالي الكسر. هذا يضمن مظهرًا طويل الأمد ولونًا ثابتًا.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'وحدة استقبال بتصميم SL، مخرج بزاوية 45° UP، BK، AP، BT 1-فجوة، 2-فجوة',
        text: 'تتكون وحدة الاستقبال المصممة من إطار من سبائك الزنك. إنها مناسبة للتثبيت في قناة الكابلات (EK)، للتثبيت الجوفي (UP) أو باستخدام إطار منفصل أيضًا للتثبيت السطحي (AP). من خلال كسر اثنين من علامات التثبيت، يمكن أيضًا استخدامه لتثبيت الخزانات الأرضية (BT). يمكن دمج مقبس البيانات المصمم مع جميع برامج التبديل المتاحة في السوق. بفضل التوافق مع UAE، لا يوفر مقبس البيانات المصمم مكانًا ل غطاء الحماية من الغبار.',
        datasheets: [
          {
            name: 'Keystone واحد / مزدوج، بدون لوحة مركزية',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: 'Keystone مزدوج، مع لوحة مركزية 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: 'Keystone واحد مع لوحة مركزية',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'وحدة استقبال IP44 للتثبيت السطحي، 1-فجوة، 2-فجوة',
        text: 'الغلاف السطحي القوي مناسب لتركيب وحدتين من وحدات preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone باستخدام محول. توجد نقطتين في الجزء الخلفي من الغلاف لتسهيل إدخال كابلين بقطر 5.0 - 9.5 مم. تحقق وحدة الاستقبال في حالة التوصيل فئة الحماية IP20، وفي حالة الإغلاق فئة الحماية IP44.',
      },
      DoseUPleer2xKS: {
        name: 'وحدة استقبال مخرج مستقيم، 2-فجوة',
        text: 'تتكون وحدة الاستقبال بمخرج Keystone المستقيم من إطار من سبائك الزنك وهي مناسبة للتثبيت في قناة الكابلات (EK)، التثبيت الجوفي (UP)، أو باستخدام إطار منفصل أيضًا للتثبيت السطحي (AP).',
      },
      bodentank: 'الخزان الأرضي',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'أنظمة خزان الأرض OBO/Ackermann UT3، UT4',
        text: 'تحل لوحة الحمل محل حامل الجهاز وتوفر بالتالي أكبر مساحة ممكنة لتوجيه الكابلات. تتيح التوجيهات المنحدرة توجيه الكابلات بأمان حتى مع الأرضيات البينية المنخفضة جدًا. من الممكن الجمع بين لوحات التركيب لتركيب 4x preLink<sup>®</sup>/fixLink<sup>®</sup>-Modules أو 1x H.D.S. يتم تثبيت لوحات التركيب بمسمارين على لوحة الحمل، ويمكن تغطية الحقول غير المستخدمة من لوحة الحمل بلوحات عمياء للحماية من الغبار.',
        datasheets: [
          {
            name: 'لوحة حمل UT3 بدون لوحة تركيب',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'لوحة حمل UT4 بدون لوحة تركيب',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'حامل الخزان الأرضي الأساسي OBO/Ackermann UT3، UT4',
        text: 'يحاكي حامل الخزان الأرضي Keystone حامل الجهاز ويوفر بالتالي أكبر مساحة ممكنة لتوجيه الكابلات. تتيح التوجيهات المنحدرة توجيه الكابلات بأمان حتى مع الأرضيات البينية المنخفضة جدًا. يتم تثبيت لوحات التركيب بمسمارين على لوحة الحمل، ويمكن تغطية فتحات Keystone غير المستخدمة من حامل الخزان الأرضي بلوحات عمياء للحماية من الغبار. باستخدام محول، يمكن أيضًا استخدام حامل الخزان الأرضي مع حاملات أجهزة Electraplan.',
        datasheets: [
          {
            name: 'حامل الخزان الأرضي UT3 لما يصل إلى 6 وحدات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'حامل الخزان الأرضي UT4 لما يصل إلى 9 وحدات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'حامل الخزان الأرضي UT4 لما يصل إلى 12 وحدة',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'حامل الخزان الأرضي الأساسي PUK UT4',
        text: 'يحاكي حامل الخزان الأرضي Keystone حامل الجهاز ويوفر بالتالي أكبر مساحة ممكنة لتوجيه الكابلات. تتيح التوجيهات المنحدرة توجيه الكابلات بأمان حتى مع الأرضيات البينية المنخفضة جدًا. يتم تثبيت لوحات التركيب بمسمارين على لوحة الحمل، ويمكن تغطية فتحات Keystone غير المستخدمة من حامل الخزان الأرضي بلوحات عمياء للحماية من الغبار. حاملات الخزان الأرضي متوافقة مع نظام الخزان الأرضي PUK.',
        datasheets: [
          {
            name: 'حامل الخزان الأرضي UT4 لما يصل إلى 9 وحدات',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'حامل الخزان الأرضي UT4 لما يصل إلى 12 وحدة',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'إدراج OBO/Ackermann لأنظمة الخزان الأرضي UT3، UT4، الملحقات',
        text: 'الإدراجات للوحدات غير مطلية ومسحوقة لأسباب التأريض ومجلفنة بالنار. يتم تثبيت لوحات التركيب بمسمارين على لوحة الحمل، ويمكن تغطية الحقول غير المستخدمة من لوحة الحمل بلوحات عمياء للحماية من الغبار.',
        datasheets: [
          {
            name: 'لوحة تركيب لـ 4 وحدات RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'لوحة تركيب لـ 3 وحدات RJ45',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'مهايئات السكك الحديدية',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'وحدة غلاف السكة الحديدية المدمجة لوحدة واحدة',
        text: 'وحدة غلاف السكة الحديدية لوحدات preLink<sup>®</sup> / fixLink<sup>®</sup>، مناسبة لخزانات التوزيع بدون غطاء لقواطع الأمان.',
        datasheets: [
          {
            name: 'وحدة غلاف السكة الحديدية المدمجة لوحدة واحدة',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'مهايئات F-Keystone ووحدة تغطية خالية Keystone',
        text: 'مهايئ F-Keystone ووحدة تغطية خالية كبديل للوحدة\nيتم استخدام مهايئ F لتكامل كابلات Coax في لوحات preLink<sup>®</sup> / fixLink<sup>®</sup> Patch وفي برنامج المآخذ. يمكن استخدام وحدة التغطية الخالية من preLink<sup>®</sup>/fixLink<sup>®</sup> لتغطية المنافذ غير المستخدمة سواء في لوحة التوزيع أو في المآخذ.\n\nوحدة خالية لـ SC-S أو LC-D كبديل للوحدة\nوحدة خالية لـ SC-S أو LC-D في لوحة التوزيع أو المأخذ.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'وحدة غلاف السكة الحديدية لوحدة واحدة',
        text: 'وحدة غلاف السكة الحديدية لوحدات preLink<sup>®</sup> / fixLink<sup>®</sup>، مناسبة لخزانات التوزيع مع غطاء لقواطع الأمان.',
        datasheets: [
          {
            name: 'وحدة غلاف السكة الحديدية لوحدة واحدة',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'لوحة',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'لوحة 19" 1HE لـ 24 وحدة Keystone بمخرج مستقيم',
        text: 'يمكن تركيب لوحة التوزيع 19" 1HE مع ما يصل إلى 24 وحدة RJ45 Keystone من preLink<sup>®</sup> / fixLink<sup>®</sup>. تم بناء اللوحة على مستويين، بحيث تكون الوحدات محاذية مع اللوحة الأمامية. يمكن طلب لوحة التوزيع من الفولاذ المقاوم للصدأ أو من الفولاذ المطلي بالبودرة.\n\nلا يتم تضمين مواد التثبيت والوحدات Keystone في العبوة.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'لوحة 19" 1HE لـ 48 وحدة Keystone بمخرج مستقيم',
        text: 'يمكن تركيب لوحة التوزيع 19" 1HE مع ما يصل إلى 48 وحدة RJ45 Keystone من fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+. تم بناء اللوحة على مستويين، بحيث تكون الوحدات محاذية مع اللوحة الأمامية. تتكون لوحة التوزيع من الفولاذ المطلي بالبودرة.\nلا يتم تضمين مواد التثبيت والوحدات Keystone في العبوة.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'لوحة 19" 0.5HE لـ 24 وحدة Keystone بمخرج مستقيم',
        text: 'يمكن تركيب لوحة التوزيع 19" 0.5HE مع ما يصل إلى 24 وحدة RJ45 Keystone من fixLink<sup>®</sup> Pro. تكون الوحدات محاذية مع اللوحة الأمامية.\n\nلا يتم تضمين مواد التثبيت والوحدات Keystone في العبوة.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'لوحة 19" 1HE لـ 24 وحدة Keystone مع نافذة تسمية',
        text: 'يمكن تركيب لوحة التوزيع 19" 1HE مع ما يصل إلى 24 وحدة RJ45 Keystone من preLink<sup>®</sup> / fixLink<sup>®</sup>. تم بناء اللوحة على مستويين، بحيث تكون الوحدات محاذية مع اللوحة الأمامية. يمكن طلب لوحة التوزيع من الفولاذ المقاوم للصدأ أو من الفولاذ المطلي بالبودرة. بالإضافة إلى ذلك، يمكن تمييز كل منفذ بمساعدة ورقة تسمية مقطوعة 12x33 مضمنة.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'لوحة بزاوية 30° 19" 1HE لـ 24 وحدة Keystone',
        text: 'مع لوحة الزاوية 30° preLink<sup>®</sup> / fixLink<sup>®</sup>، يمكن للمستخدم تركيب 24 وحدة Keystone بزاوية 30° في اللوحة، مما يسمح بتوجيه الكابلات المتصلة تلقائيًا إلى جانب الخزانة ويجب تثبيتها فقط بجانب مستوى التصحيح. وبالتالي، يمكن للمستخدم الاستغناء عن لوحات التوجيه الإضافية لتوجيه الكابلات. في التشغيل، تحتاج لوحات الزاوية مع اللوحة الأمامية المستوية والكابلات المتصلة المائلة إلى مساحة أقل من الأمام مقارنةً بلوحات التوزيع التقليدية مع المنافذ الرأسية.\n\nلا يتم تضمين مواد التثبيت والوحدات Keystone في العبوة.',
      },
      baugruppentraeger: 'حامل الوحدة',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'حامل الوحدة 19" 3HE لاستخدام الوحدات القابلة للإدخال',
        text: 'حامل الوحدة 19" 3HE لاستخدام الوحدات القابلة للإدخال 7TE في النحاس والألياف الضوئية. يتم تثبيتها باستخدام مسمارين من جانب اللوحة الأمامية على حامل الوحدة. يمكن سحب الوحدات القابلة للإدخال بالكامل من حامل الوحدة. يمكن تثبيت ما يصل إلى 12 وحدة 7TE في حامل الوحدة.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'وحدة الإدخال Keystone لحامل الوحدة',
        text: 'وحدة الإدخال Keystone لتجهيز حاملات الوحدة. يمكن تجهيز وحدة الإدخال Keystone بما يصل إلى 6 وحدات RJ45 Keystone من preLink<sup>®</sup> / fixLink<sup>®</sup>. تم بناء وحدة الإدخال Keystone على مستويين، بحيث تكون الوحدات محاذية مع اللوحة الأمامية. يتم تثبيت الكابلات مباشرة في غلاف وحدة الإدخال. يمكن تثبيت 6 كابلات فردية أو كابل صندوق واحد.\n\nلا يتم تضمين مواد التثبيت والوحدات Keystone في العبوة.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'حامل الوحدة (BGT) 19" 1HE لاستخدام الوحدات القابلة للإدخال',
        text: 'حامل الوحدة (BGT) لاستخدام الوحدات القابلة للإدخال 7TE في النحاس والألياف الضوئية. يتم تثبيتها باستخدام مسمارين من جانب اللوحة الأمامية على حامل الوحدة. يمكن سحب الوحدات القابلة للإدخال بالكامل من حامل الوحدة. يمكن تثبيت ما يصل إلى 3 وحدات 7TE (=18 منافذ) في حامل الوحدة.\n\nلا يتم تضمين مواد التثبيت والوحدات Keystone في العبوة.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'حامل الوحدة الجانبي 3HE/14TE لاستخدام الوحدات القابلة للإدخال',
        text: 'حامل الوحدة الجانبي (S-BGT) لاستخدام الوحدات القابلة للإدخال 7TE في النحاس والألياف الضوئية. يتم تثبيت الوحدات القابلة للإدخال باستخدام مسمارين من جانب اللوحة الأمامية على حامل الوحدة. يمكن سحب الوحدات القابلة للإدخال بالكامل من حامل الوحدة. يمكن تثبيت ما يصل إلى 2 وحدات 7TE (=12 منافذ) في حامل الوحدة. يتم تثبيت حامل الوحدة الجانبي (S-BGT) على الثقب الجانبي للخزانة. هذا يسمح بتثبيت بسيط وسريع. من خلال التثبيت الجانبي للخزانة، يمكن تحقيق كابل مرن وصديق للبيئة دون عرقلة تدفق الهواء.\n\nلا يتم تضمين مواد التثبيت والوحدات Keystone في العبوة.',
      },
      consolidation_point_panel: 'لوحة نقاط الدمج، وقضبان DIN وما إلى ذلك.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'لوحة نقطة التوحيد تحت الأرض 19" أو 10" 1HE',
        text: 'لوحة نقطة التوحيد تحت الأرض مع لوحات أمامية قابلة للتبديل لاستيعاب أنواع مختلفة من التوصيلات في النحاس والألياف الضوئية. يمكن تأمين لوحة نقطة التوحيد تحت الأرض اختياريًا بقفل لمنع الوصول غير المصرح به.\n\nلا يتم تضمين مواد التثبيت والوحدات Keystone في العبوة.',
        datasheets: [
          {
            name: 'لوحة نقطة التوحيد 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'لوحة نقطة التوحيد 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'لوحة نقطة التوحيد للتثبيت فوق الأرض لـ 4-12 وحدات Keystone',
        text: 'لوحة نقطة التوحيد للتثبيت فوق الأرض لـ preLink<sup>®</sup> / fixLink<sup>®</sup> وحدات RJ45 Keystone، للاستخدام كنقطة توحيد، التثبيت المباشر أو باستخدام مجموعة التثبيت على السكك الحديدية في توزيعات التكنولوجيا الكهربائية. تم بناء لوحة نقطة التوحيد على مستويين. بحيث تكون الوحدات محاذية مع اللوحة الأمامية.\n\nلا يتم تضمين مواد التثبيت والوحدات Keystone في العبوة.',
      },
      trunkkabel: 'كابل الصندوق',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'نظام تقسيم SL CTBC Copper-Trunk-Bundled-Cable، الفئة EA ISO/IEC',
        text: 'يوفر نظام التقسيم CTBC استخدامًا مرنًا في الكابلات الهيكلية للمباني ومراكز البيانات. الكابلات المجمعة في غلاف شبكي مهيأة ومختبرة مسبقًا بوحدات fixLink<sup>®</sup> Keystone. يمكن أن يتم التجميع إما بكابلات التركيب أو الكابلات المرنة. الحد الأقصى لطول الكابل المجمّع يصل إلى 20 متر.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'نظام تقسيم SL CTCC Copper-Trunk-Compact-Cable، الفئة EA ISO/IEC',
        text: 'يتميز نظام التقسيم CTCC بقطر كابله الصغير وبالتالي يقلل من الحمل الحراري، مما يجعله مناسبًا للاستخدام المرن في الكابلات الهيكلية للمباني ومراكز البيانات. الحد الأقصى لطول الكابل المتعدد يعتمد على النوع 60-90 متر. يفي نظام التقسيم كحل 10-Gbit لمراكز البيانات بمتطلبات الفئة EA على الأقل.',
      },
      hds_innengabel: 'كابل H.D.S. الداخلي',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'كابل H.D.S. الداخلي الفئة 7، 6x (4 x 2 x AWG 23) S-FTP B2ca، S-FTP Dca',
        text: 'كابل H.D.S. الداخلي الفئة 7 S/FTP للتركيب بين الخزانات في مراكز البيانات والوصلات المتعددة بين غرف التوزيع والتوزيعات الفرعية. يتكون تركيب الكابل من 24 عنصر كابل مجدول، كل 4 أزواج مكونة من عنصر مجدول واحد. يتم تزويد كل عنصر مجدول بشريط ترقيم لتحديد الهوية بسهولة. يوفر عنصر الشد المركزي المرن حماية جيدة للكابل أثناء التثبيت. يتكون الغلاف من مادة LSOH مرنة.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'كابل H.D.S. الداخلي الفئة 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'كابل H.D.S. الداخلي الفئة 7 S/FTP للتركيب بين الخزانات في مراكز البيانات والوصلات المتعددة بين غرف التوزيع والتوزيعات الفرعية. يتكون تركيب الكابل من 24 عنصر كابل مجدول، كل 4 أزواج مكونة من عنصر مجدول واحد. يتم تزويد كل عنصر مجدول بشريط ترقيم لتحديد الهوية بسهولة. يوفر عنصر الشد المركزي المرن حماية جيدة للكابل أثناء التثبيت. يتكون الغلاف من مادة LSOH مرنة.',
      },
      generic: {
        download: 'تنزيل',
      },
    },
  },

  imprint: {
    headline: 'البصمة',
  },

  dataProtection: {
    headline: 'حماية البيانات',
  },

  footer: {
    dataProtection: 'حماية البيانات',
    imprint: 'البصمة',
    contact: 'اتصال',
  },

  UI: {
    autocomplete: {
      searchError: 'لم يتم تنفيذ البحث.',
      searchEmpty: 'لا توجد نتائج',
    },
    dropdown: {
      emptySelection: '--- الرجاء الاختيار ---',
    },
  },
}

export const langKey = 'MA'

const Default = {
  translation,
  langKey,
}

export default Default
