export const translation = {
  header: {
    items: [
      {
        name: 'Početna',
        href: '/',
      },
      {
        name: 'Sistem',
        href: '#system',
      },
      {
        name: 'Modul',
        href: '#module',
      },
      {
        name: 'Montaža',
        href: '#montage',
      },
      {
        name: 'EasyLan',
        href: 'https://www.easylan.de/',
        newTab: true,
      },
    ],
    language: 'Jezik',
  },

  easylan: {
    fixLink: 'fixLink<sup>®</sup>',
    hero: {
      title: {
        text1: 'Jednostavnost',
        text2: 'u povezivanju',
      },
      subtitle: 'Tvoj link za budućnost',
      cta: 'više',
    },
    handling: {
      title: 'INTUITIVNA RUKOVANJE SREĆE NAPREDNU TEHNOLOGIJU',
      vorreiterPrefix: 'Kombinacija preciznosti i funkcionalnog dizajna čini ',
      vorreiterSuffix: ' vodećim izborom u mrežnoj tehnologiji.',
      experte: ' nudi efikasna 360° rešenja koja optimalno povezuju dizajn i performanse, kako bi se minimizovala složenost.',
      pionier: ' je pionir u transformaciji radnih prostora, spajajući stručnost sa intuitivnim rukovanjem.',
    },
    download: {
      prefix: 'Pregled ',
      suffix: ' - Preuzmi sistem',
      cta: 'Preuzmi',
      href: 'https://fixlink.eu/PUBLIC_STORAGE/FixLink_System_Uebersicht_2024_DE_v2.pdf',
    },
    system: {
      titleLabel: 'Pregled proizvoda',
      title: 'FIXLINK<sup>®</sup> - SISTEM',
    },
    module: {
      overview: 'Преузмите преглед',
      accordion: {
        ghmtCertificates: {
          title: 'GHMT - Sertifikati',
          text: 'Verujemo GHMT-u i njihovom Premium Verification Program-u za nezavisno osiguranje kvaliteta naših mrežnih proizvoda. Na osnovu 20 godina stručnosti, garantujemo najviše standarde proizvoda za naše klijente.',
        },
        datasheets: {
          title: 'Tehnički listovi',
          text: 'Ovde možeš pronaći sve potrebne tehničke listove. Preuzmi ih i imaj sve informacije na dohvat ruke.',
        },
        compatibilitySheets: {
          title: 'Liste kompatibilnosti',
          text: '',
        },
        excelTemplates: {
          title: 'Excel šabloni za označavanje prozora',
          text: '',
        },
      },
    },
    video: {
      title: 'UPUTSTVO ZA MONTAŽU',
      text: 'Pogledaj video ili jednostavno preuzmi PDF ovde',
      manual: {
        title: 'UPUTSTVA ZA MONTAŽU:',
      },
    },
    separator: {
      title: 'Više od povezanosti',
      title2: 'Otkrij EasyLan univerzum',
      text: 'Otkrij raznovrsnost inovativnih mrežnih rešenja na EasyLan.de: Prilagođena baš tebi. GameChanger za tvoju mrežu: Naši sistemi za kabliranje i rešenja za upravljanje kablovima.',
      cta: 'više',
    },
    combination: {
      bau: {
        prefix: 'Iskusi maksimalnu prilagodljivost i kvalitet sa',
        text: ' EasyLan-ovim nosiocima komponenti ',
        suffix: 'direktno iz Evrope.',
      },
      intelligent: {
        prefix: 'Tvoja mreža',
        text: ' prilagođena ',
        suffix: 'sa konfigurisanim EasyLan spojnim kutijama',
      },
      fixlink: {
        prefix: 'Sa našim ',
        suffix: ' proizvodima možete dugoročno unaprediti vaše poslovne zgrade.',
      },
      hds: {
        prefix: 'EasyLan<sup>®</sup> H.D.S. - Sistem ',
        text: ' spaja jednostavnost instalacije i održavanja kao nijedan drugi sistem trunk kabliranja na tržištu.',
        suffix: '',
      },
    },
    recommended: {
      titleLabel: 'Preporučeno',
      title: 'fixLink<sup>®</sup> - SISTEM',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
      items: [
        { imgName: 'a', name: 'FIXLINK A', text: 'Lorem ipsum dolor met' },
        { imgName: 'b', name: 'FIXLINK B', text: 'Lorem ipsum dolor met' },
        { imgName: 'c', name: 'FIXLINK C', text: 'Lorem ipsum dolor met' },
        { imgName: 'd', name: 'FIXLINK D', text: 'Lorem ipsum dolor met' },
        { imgName: 'e', name: 'FIXLINK E', text: 'Lorem ipsum dolor met' },
      ],
    },
    guarantee: [
      {
        title: 'Garancija proizvoda',
        text: [
          'Garancija proizvoda obuhvata sve komponente ',
          '- optičkog i bakarnog sistema kabliranja, koje je instalirao kvalifikovani instalater prema smernicama ',
          ' kao i važećim instalacionim normama EN50174.',
        ],
      },
      {
        title: 'Garancija performansi',
        text: [
          'Garancija performansi garantuje, pored aplikacija (Ethernet, Fiber Channel, ATM, itd.), i karakteristike sistema kao što su brzine prenosa (10MBit, 100MBit, 1GBit i 10GBit).',
        ],
      },
      {
        title: 'Garancija aplikacija',
        text: [
          'Garancija aplikacija garantuje sve specifikovane aplikacije (Ethernet, Fiber Channel, ATM, itd.), koje se zasnivaju na aktuelnim standardima kabliranja ISO/IEC 11801, EN50173 i EIA/TIA 568B u trenutku instalacije.',
        ],
      },
    ],
    end: {
      title: 'POVEZIVANJE NIKADA NIJE BILO OVAKO JEDNOSTAVNO.',
      text: 'Intuitivno rukovanje susreće naprednu tehnologiju; otkrijte besprekornu vezu budućnosti: fixLink<sup>®</sup>',
    },
    categoryData: {
      modules: 'Priključni moduli',
      csm_FixLink_Pro03_b20813b518: {
        name: 'Pro RJ45 Keystone-Modul Kat. 6A ISO/IEC, zaštićen',
        text: 'Sa fixLink<sup>®</sup> Pro RJ45 Keystone-Modulom, korisnik dobija RJ45 priključni modul najvišeg kvaliteta. fixLink<sup>®</sup> Pro RJ45 Keystone-Modul može biti konfigurisano pomoću bočnih rezača za manje od minut. Zahvaljujući slobodno dostupnoj kontaktnoj zoni, osigurana je savršena kvaliteta konfigurisanja i time zajamčena performansa prenosa. Specijalna geometrija kontakta u kombinaciji sa 30 μ" zlatnom prevlakom osigurava 4PPoE prema IEEE 802.3bt u svim uslovima. Materijal kućišta je izrađen od niklovanog cink-liva i ispunjava najviše ekološke i mehaničke zahteve. U kombinaciji sa dizajnerski prilagodljivim nosačem modula, moguća je kombinacija sa svim prekidačkim programima. Isporučuju se u ekološki prihvatljivom kartonskom pakovanju.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Permanent Link sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Pro+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      csm_fixLink_Extender_a0613ee77a: {
        name: 'Extender Kat. 6A ISO/IEC, zaštićen',
        text: 'Sa fixLink<sup>®</sup> Extenderom, kablovi se mogu produžiti bez mnogo truda. Takođe, neaktivni kablovi mogu se ponovo aktivirati i produžiti do sledećeg potrebnog priključka. Time se optimalno koristi ograničen prostor u kablovskom kanalu. Extender se jednostavno pričvršćuje na kraj instaliranog kabla u kanal ili na zid. Nakon instalacije, produženi kabl može se koristiti za 10-Gigabitne Ethernet prenose i podržava PoE+ (Power over Ethernet).',
      },
      csm_fixLinkRJ45Plug_Katalog_c858f0a6ed: {
        name: 'RJ45 Plug Kat. 6A ISO/IEC zaštićen',
        text: 'Sa fixLink<sup>®</sup> RJ45 priključnim modulom, korisnik dobija polje-konfigurisano RJ45 priključni modul najvišeg kvaliteta i fleksibilnosti. Specijalna geometrija kontakta u kombinaciji sa 50 μ" zlatnom prevlakom osigurava 4PPoE prema IEEE 80.3bt u svim uslovima. Materijal kućišta je izrađen od niklovanog cink-liva i ispunjava najviše ekološke i mehaničke zahteve. Razne sertifikacije (aktuelna lista: www.easylan.de) od nezavisnih laboratorija za testiranje zaokružuju najviši kvalitet fixLink<sup>®</sup> sistema.',
      },
      csm_FixlinkUTP_e5de94d502: {
        name: 'RJ45 Keystone-Modul Kat. 6A EIA/TIA, nezaštićen',
        text: 'Sa fixLink<sup>®</sup> RJ45 Keystone-Modulom (PoE+ sposobnim), korisnik dobija RJ45 priključni modul najvišeg kvaliteta. fixLink<sup>®</sup> RJ45 Keystone-Modul može biti konfigurisano pomoću bočnih rezača za manje od minut. Zahvaljujući slobodno dostupnoj kontaktnoj zoni, osigurana je savršena kvaliteta konfigurisanja i time zajamčena performansa prenosa. Materijal kućišta je izrađen od polikarbonata i ispunjava najviše ekološke i mehaničke zahteve. U kombinaciji sa dizajnerski prilagodljivim nosačem modula, moguća je kombinacija sa svim prekidačkim programima. Razne sertifikacije od nezavisnih laboratorija za testiranje zaokružuju najviši kvalitet fixLink<sup>®</sup> SL Keystone-Modula. Isporučuju se u ekološki prihvatljivom kartonskom pakovanju.',
      },
      fixLink_SL_02: {
        name: 'SL RJ45 Keystone-Modul Kat. 6A ISO/IEC, zaštićen',
        text: 'Sa fixLink<sup>®</sup> SL RJ45 Keystone-Modulom, korisnik dobija RJ45 priključni modul najvišeg kvaliteta. fixLink<sup>®</sup> SL RJ45 Keystone-Modul može biti konfigurisano pomoću bočnih rezača za manje od minut. Zahvaljujući slobodno dostupnoj kontaktnoj zoni, osigurana je savršena kvaliteta konfigurisanja i time zajamčena performansa prenosa. Za 360° zaštitu, samo je potrebno pričvrstiti integrisani klin, čime se izbegava dugotrajna primena kablovske veze. Specijalna geometrija kontakta u kombinaciji sa 50 μ" zlatnom prevlakom osigurava 4PPoE prema IEEE 802.3bt u svim uslovima. Materijal kućišta je izrađen od niklovanog cink-liva i ispunjava najviše ekološke i mehaničke zahteve. U kombinaciji sa dizajnerski prilagodljivim nosačem modula, moguća je kombinacija sa svim prekidačkim programima. Razne sertifikacije od nezavisnih laboratorija za testiranje zaokružuju najviši kvalitet fixLink<sup>®</sup> SL Keystone-Modula. Isporučuju se u ekološki prihvatljivom kartonskom pakovanju.',
        ghmtCertificates: [
          {
            name: 'PVP Kat.6A sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z5951i-20.pdf',
          },
          {
            name: 'Izveštaj o ispitivanju 4PoE prema DIN EN ISO/IEC 17025',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/P4712a-17-D.pdf',
          },
          {
            name: 'GHMT Permanent Link sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul/z8144a-23.pdf',
          },
        ],
      },
      fixLinkSLGewinkelt: {
        name: 'SL RJ45 Keystone-Modul zakrivljen Kat. 6A ISO/IEC, zaštićen',
        text: 'Sa fixLink<sup>®</sup> SL RJ45 Keystone-Modulom, korisnik dobija RJ45 priključni modul najvišeg kvaliteta, koji je specijalno razvijen za primene sa ograničenim prostorom. Zahvaljujući svom 75° kablovskom izlazu, modul je posebno pogodan za primenu u priključnim kutijama i šupljim kablovskim sistemima. Minimalna dubina ugradnje od 27 mm omogućava gotovo neograničenu raznovrsnost primena. fixLink<sup>®</sup> SL RJ45 Keystone-Modul može biti konfigurisano pomoću bočnih rezača za manje od minut. Zahvaljujući slobodno dostupnoj kontaktnoj zoni, osigurana je savršena kvaliteta konfigurisanja i time zajamčena performansa prenosa. Specijalna geometrija kontakta u kombinaciji sa 50 μ" zlatnom prevlakom osigurava PoE+ prema IEEE 80.3at u svim uslovima. Materijal kućišta je izrađen od niklovanog cink-liva i ispunjava najviše ekološke i mehaničke zahteve. U kombinaciji sa dizajnerski prilagodljivim nosačem modula, moguća je kombinacija sa svim prekidačkim programima. Razne sertifikacije od nezavisnih laboratorija za testiranje zaokružuju najviši kvalitet fixLink<sup>®</sup> SL Keystone-Modula. Isporučuju se u ekološki prihvatljivom kartonskom pakovanju.',
        ghmtCertificates: [
          {
            name: 'GHMT PVP Kat.6A sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      werkzeugeUndZubehoer: {
        name: 'Alati i dodaci',
        text: [
          'Alat za skidanje izolacije za uklanjanje spoljnog omotača i metalnih folija podatkovnog kabla. Plava boja.',
          'Bakarna traka za fiksiranje ekrana. Prethodno isečena na 35 x 9 mm. Jedinica pakovanja: 20 komada/kesa 1500 komada/rolna',
          'Obojeni poklopci za zaštitu od prašine za preLink® SL i fixLink® SL Keystone module.',
          'Jedinica pakovanja 50 komada/plastična kesa',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP sertifikat Kat.6A',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z5951i-20.pdf',
          },
          {
            name: 'GHMT Permanent Link sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/SL+RJ45+Keystone-Modul+gewinkelt/z8144a-23.pdf',
          },
        ],
      },
      verlegekabel: 'Instalacioni kabl',
      goldCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Podatkovni kabl EasyLan® Gold S/FTP AWG22/1 1500 MHz Kat. 7A',
        text: [
          'Podatkovni kabl za prenos analognih i digitalnih signala pri kabliranju u primarnoj, sekundarnoj i tercijarnoj oblasti. Pogodan za primene do klase FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z7566f-22.pdf',
          },
          {
            name: 'GHMT Permanent Link sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/z8144a-23.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Tehnički list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Gold/ZN-DK210304_J_EasyLan_Datasheet_Data_Cable_S-FTP_Gold_AWG22_Cat7A_1500MHz.pdf',
          },
        ],
      },
      silberCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Podatkovni kabl EasyLan® Silber S/FTP AWG23/1 1200 MHz Kat. 7A',
        text: [
          'Podatkovni kabl za prenos analognih i digitalnih signala pri kabliranju u primarnoj, sekundarnoj i tercijarnoj oblasti. Pogodan za primene do klase FA.',
        ],
        ghmtCertificates: [
          {
            name: 'GHMT PVP sertifikat',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/z7565e-22.pdf',
          },
        ],
        datasheets: [
          {
            name: 'Tehnički list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Silber/ZN-DK210305_J_EasyLan_Datasheet_Data_Cable_S-FTP_Silver_AWG23_Cat7A_1200MHz.pdf',
          },
        ],
      },
      bronzeCsm_Datenkabel_SFTP_orange_074bad6e0f: {
        name: 'Podatkovni kabl EasyLan® Bronze S/FTP AWG23/1 1000 MHz Kat. 7',
        text: 'Podatkovni kabl za prenos analognih i digitalnih signala pri kabliranju u primarnoj, sekundarnoj i tercijarnoj oblasti. Pogodan za primene do klase FA.',
        datasheets: [
          {
            name: 'Tehnički list',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Verlegekabel_Bronze/ZN-DK210306_L_EasyLan_Datasheet_Data_Cable_S-FTP_Bronze_AWG23_Cat7_1000MHz.pdf',
          },
        ],
      },
      datendosen: 'Priključne kutije',
      csm_Abdeckrahmen_1fach_d4075872d1: {
        name: 'Nadgradna kućišta i okviri za navedene nosače modula',
        text: [
          'Nadgradna kućišta IP20, 80x80x42mm za ugradnju EasyLan nosača modula',
          'Međuram za nadgradna kućišta za povećanje visine za 10mm',
          'Pokrovni okvir 1-fazni 80x80mm',
          'Pokrovni okvir 2-fazni 80x150mm',
        ],
        datasheets: [
          {
            name: 'Nadgradna kućišta',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161044_B_ADZ00101_EasyLan_Datasheet_Aufputzgeha%CC%88use.pdf',
          },
          {
            name: 'Produbljenje nadgradnog kućišta',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/AP-Geh%C3%A4use+und+Rahmen+f%C3%BCr+vorgenannte+Modulaufnahmen/ZN-CU161045_B_CKVDDR90_EasyLan_Datasheet_Tiefenerweiterung_AP-Geha%CC%88use.pdf',
          },
        ],
      },
      csm_Datendose_1PortmitRahmen_a8e4d5e731: {
        name: 'Nosač modula SL, 45° izlaz UP, BK, AP, BT 1-fazni, 2-fazni, 3-fazni',
        text: 'Nosači modula SL su posebno pogodni za ugradnju u kablovski kanal (EK), za ugradnju ispod maltera (UP) i za nadgradnu montažu (AP). Uklanjanjem dva montažna nosača, moguće je i montaža u podnom kanalu (BT). Nosač modula SL može sigurno i trajno prihvatiti jedan do tri EasyLan Keystone-a. Poseduje veliki, centralno postavljeni prozor za označavanje radi lakše identifikacije. Centralna ploča (50x50 mm) i okvir (80x80 mm) izrađeni su od UV-neosetljive i ekstremno otporne plastike. Time je osiguran dugotrajan i postojan izgled.',
      },
      csm_Datendose_designfähig_1fach_f35757e7bc: {
        name: 'Dizajnerski prilagodljiv nosač modula SL, 45° izlaz UP, BK, AP, BT 1-fazni, 2-fazni',
        text: 'Dizajnerski prilagodljiv nosač modula izrađen je od cink-livanog okvira. Pogodan je za ugradnju u kablovski kanal (EK), za ugradnju ispod maltera (UP) ili pomoću posebnog okvira i za nadgradnu montažu (AP). Uklanjanjem dva montažna nosača, moguće je i montaža u podnom kanalu (BT). Dizajnerska priključna kutija može se kombinovati sa gotovo svim prekidačkim programima dostupnim na tržištu. Zbog kompatibilnosti sa UAE, dizajnerska priključna kutija nema mesto za poklopac za zaštitu od prašine.',
        datasheets: [
          {
            name: '1-/2-fazni Keystone, bez centralne ploče',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151005_F_CKVD1DS_EasyLan_Datasheet_designfa%CC%88hige_Modulaufnahme_1-_2-fach_ohne_ZP.pdf',
          },
          {
            name: '2-fazni Keystone, sa centralnom pločom 50x50mm',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL151006_E_EasyLan_CKVD1D1_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_2-fach_mit_ZP.pdf',
          },
          {
            name: '1-fazni sa centralnom pločom',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Designf%C3%A4hige+Modulaufnahme+SL/ZN-BL161067_B_EasyLan_CKVD1D2_Datasheet_designfa%CC%88hige_Modulaufnahme_SL_mit_ZP_1-fach.pdf',
          },
        ],
      },
      csm_IP44_AP_Dose_offen_2e48717c62: {
        name: 'Nosač modula IP44 nadgradni, 1-fazni, 2-fazni',
        text: 'Robusno nadgradno kućište je pogodno za ugradnju dva preLink<sup>®</sup> / fixLink<sup>®</sup> Keystone modula pomoću adaptera. Za uvođenje dva kabla prečnika od 5,0 - 9,5mm, pozadi na kućištu se nalaze dva mesta koja se lako mogu probušiti. Nosač modula dostiže zaštitni nivo IP20 u uvučenom stanju, a IP44 u zatvorenom stanju.',
      },
      DoseUPleer2xKS: {
        name: 'Nosač modula ravnog izlaza, 2-fazni',
        text: 'Nosač modula sa ravnim Keystone izlazom izrađen je od cink-livanog okvira i pogodan je za ugradnju u kablovski kanal (EK), za ugradnju ispod maltera (UP), ili pomoću posebnog okvira i za nadgradnu montažu (AP).',
      },
      bodentank: 'Podni kanali',
      csm_Bodentank_2er_Rahmen_fa84a9dd52: {
        name: 'OBO/Ackermann podni kanali UT3, UT4',
        text: 'Nosač zamenjuje nosač uređaja i nudi maksimalni prostor za uvođenje kablova. Kosa uvođenja i odvođenja omogućavaju sigurnu vođenje kablova čak i pri vrlo niskim međupodovima. Moguće je kombinovati sa pločama za ugradnju 4x preLink<sup>®</sup>/fixLink<sup>®</sup> modula ili 1x H.D.S. Ploče za ugradnju se pričvršćuju sa dve vijka na nosač, a neiskorišćeni otvori nosača mogu se zatvoriti poklopcima za zaštitu od prašine.',
        datasheets: [
          {
            name: 'Nosač za UT3 bez ploče za ugradnju',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161042_C_ABG00011_EasyLan_Datasheet_Bodentanktraegerplatte_UT3.pdf',
          },
          {
            name: 'Nosač za UT4 bez ploče za ugradnju',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanksysteme+UT3/ZN-CU161041_C_ABG00012_EasyLan_Datasheet_Bodentanksystem_UT4.pdf',
          },
        ],
      },
      csm_OBO_Bodentanktraeger_Basic_6x_Kestone_84e5329394: {
        name: 'OBO/Ackermann nosač za podne kanale Basic UT3, UT4',
        text: 'Nosač za podne kanale Keystone zamenjuje nosač uređaja i nudi maksimalni prostor za uvođenje kablova. Kosa uvođenja i odvođenja omogućavaju sigurnu vođenje kablova čak i pri vrlo niskim međupodovima. Ploče za ugradnju se pričvršćuju sa dve vijka na nosač, a neiskorišćeni otvori nosača mogu se zatvoriti poklopcima za zaštitu od prašine. Pomoću adaptera, nosač za podne kanale se može koristiti i za Electraplan nosače uređaja.',
        datasheets: [
          {
            name: 'Nosač za podne kanale UT3 za do 6 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161056_C_ABGECO23_EasyLan_Datasheet_Bodentanktraeger_ECO_UT3_fuer_bis_zu_6_Module.pdf',
          },
          {
            name: 'Nosač za podne kanale UT4 za do 9 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161055_C_ABGECO33_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_9_Module.pdf',
          },
          {
            name: 'Nosač za podne kanale UT4 za do 12 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Bodentanktr%C3%A4ger+Basic+UT3/ZN-BL161057_D_ABGECO34_EasyLan_Datasheet_Bodentanktraeger_ECO_UT4_fuer_bis_zu_12_Module.pdf',
          },
        ],
      },
      csm_PUK_Bodentank_3x3KS_2752d44a76: {
        name: 'PUK nosač za podne kanale Basic UT4',
        text: 'Nosač za podne kanale Keystone zamenjuje nosač uređaja i nudi maksimalni prostor za uvođenje kablova. Kosa uvođenja i odvođenja omogućavaju sigurnu vođenje kablova čak i pri vrlo niskim međupodovima. Ploče za ugradnju se pričvršćuju sa dve vijka na nosač, a neiskorišćeni otvori nosača mogu se zatvoriti poklopcima za zaštitu od prašine. Nosači za podne kanale su kompatibilni sa PUK sistemom podnih kanala.',
        datasheets: [
          {
            name: 'Nosač za podne kanale UT4 za do 9 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181395_B_ABGPUK33_EasyLan_Datasheet_Bodentanktraeger_PUK_3x3_Keystones.pdf',
          },
          {
            name: 'Nosač za podne kanale UT4 za do 12 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/PUK+Bodentanktr%C3%A4ger+Basic+UT4/ZN-BL181396_B_ABGPUK34_EasyLan_Datasheet_Bodentanktraeger_PUK_3x4_Keystones.pdf',
          },
        ],
      },
      OSO_Einsatz_3xKS: {
        name: 'OBO/Ackermann ulošci za sisteme podnih kanala UT3, UT4, pribor',
        text: 'Ulošci za module su iz razloga uzemljenja nelakirani i vruće pocinkovani. Ploče za ugradnju se pričvršćuju sa dve vijka na nosač, a neiskorišćeni otvori nosača mogu se zatvoriti poklopcima za zaštitu od prašine.',
        datasheets: [
          {
            name: 'Ploča za ugradnju za 4x RJ45 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU161043_B_EasyLan_Datasheet_Bodentanktraegereinsatz_4xKS_ABG00014.pdf',
          },
          {
            name: 'Ploča za ugradnju za 3x RJ45 modula',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/OBO-Ackermann+Eins%C3%A4tze+f%C3%BCr+Bodentanksysteme+UT3/ZN-CU200571_B_EasyLan_Datasheet_Bodentanktraegereinsatz_3xKS_ABG00016.pdf',
          },
        ],
      },
      hutschienenadapter: 'Adapter za montažu na šine',
      csm_Hutschienenmodul_Kompakt_01_66214719b0: {
        name: 'Kompaktno kućište za montažu na šine za 1 modul',
        text: 'Kućište za montažu na šine za preLink<sup>®</sup> / fixLink<sup>®</sup> module, pogodno za distribucione ormane bez poklopca za automatske osigurače.',
        datasheets: [
          {
            name: 'Kompaktno kućište za montažu na šine za 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU161008_B_CKVHECOMOD_EasyLan_Datasheet_Hutschienenmodulgehaeuse_fuer_Sicherungsautomaten.pdf',
          },
        ],
      },
      KeystoneAdapterFKupplung: {
        name: 'Keystone F-spojnica i poklopac za prazne module Keystone',
        text: 'F-spojnica i poklopac za prazne module kao zamena za module\nF-spojnica služi za integraciju koaksijalnih kablova u preLink<sup>®</sup> / fixLink<sup>®</sup> Patchpanels i u program kutija. preLink<sup>®</sup> / fixLink<sup>®</sup> poklopac za prazne module može se koristiti i u distribucionim poljima i u kutijama za pokrivanje neiskorišćenih portova.\n\nPrazan modul za SC-S odnosno LC-D kao zamena za module\nPrazan modul za SC-S odnosno LC-D spojeve u distribucionom polju ili kutiji.',
      },
      csm_Hutschienenmodul_01_32c872ee2e: {
        name: 'Kućište za montažu na šine za 1 modul',
        text: 'Kućište za montažu na šine za preLink<sup>®</sup> / fixLink<sup>®</sup> module, pogodno za distribucione ormane sa poklopcem za automatske osigurače.',
        datasheets: [
          {
            name: 'Kućište za montažu na šine za 1 modul',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Hutschienen-Modulgeh%C3%A4use+und+-Adapterset/ZN-CU200572_B_CKVHMOD_EasyLan_Datasheet_Carrier_Rail_Modular_Housing_for_one_Modul_.pdf',
          },
        ],
      },
      panel: 'Panel',
      csm_1HE_fixLink_Panel_5f97ddb36b: {
        name: 'Panel 19" 1HE za 24 Keystone modula ravnog izlaza',
        text: 'Distribuciono polje 19" 1HE može se opremiti sa do 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone modula. Postavljeno je u dva nivoa, tako da su moduli u ravni sa prednjom pločom. Distribuciono polje može se naručiti od nehrđajućeg čelika ili pocinkovanog čelika.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_48x_fixLink_Panel_19a5ba2e55: {
        name: 'Panel 19" 1HE za 48 Keystone modula ravnog izlaza',
        text: 'Distribuciono polje 19" 1HE može se opremiti sa do 48 fixLink<sup>®</sup> Pro / fixLink<sup>®</sup> Pro+ RJ45 Keystone modula. Postavljeno je u dva nivoa, tako da su moduli u ravni sa prednjom pločom. Distribuciono polje je izrađeno od pocinkovanog čelika.\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_05HE_panel_fixlink_c73fbc8cf9: {
        name: 'Panel 19" 0,5HE za 24 Keystone modula ravnog izlaza',
        text: 'preLink<sup>®</sup> / fixLink<sup>®</sup> distribuciono polje 19" 0,5HE može se opremiti sa do 24 fixLink<sup>®</sup> RJ45 Keystone modula. Postavljeni su u ravni sa prednjom pločom.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_Beschriftungspanel_70ebc940db: {
        name: 'Panel 19" 1HE za 24 Keystone modula sa prozorom za označavanje',
        text: 'Distribuciono polje 19" 1HE može se opremiti sa do 24 preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone modula. Postavljeno je u dva nivoa, tako da su moduli u ravni sa prednjom pločom. Distribuciono polje može se naručiti od nehrđajućeg čelika ili pocinkovanog čelika. Pored toga, moguće je označiti pojedinačne portove pomoću priloženog 12x33 perforiranog lista za označavanje.',
      },
      csm_Winkelpanel_30__22e31b820a: {
        name: 'Panel pod uglom 30° 19" 1HE za 24 Keystone modula',
        text: 'Kod preLink<sup>®</sup> / fixLink<sup>®</sup> panela pod uglom, korisnik može postaviti 24 Keystone modula pod uglom od 30° u panel, čime priključeni patch kablovi automatski vode prema strani ormara i samo ih treba pričvrstiti pored patch zone. Time korisnik može izbeći dodatne panele za vođenje kablova. U radu, paneli pod uglom sa ravnom prednjom stranom i koso postavljenim patch kablovima zauzimaju manje prostora napred nego standardni patch paneli sa vertikalno postavljenim portovima.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      baugruppentraeger: 'Nosioci modula',
      csm_BGT_3HE1_8bf0b66dbe: {
        name: 'Nosač modula 19" 3HE za upotrebu ugradnih modula',
        text: 'Nosač modula 19" 3HE za upotrebu 7TE ugradnih modula u bakru i optici. Pričvršćuju se sa dve vijka sa strane panela na nosač modula. Ugradni moduli se mogu potpuno izvući iz nosača modula. Maksimalno se može pričvrstiti 12x 7TE ugradnih modula u nosaču modula.',
      },
      csm_fixLink_preLink_Einschubmodul_02_b91415f81e: {
        name: 'Ugradni modul Keystone za nosače modula',
        text: 'Ugradni modul Keystone za opremanje nosača modula. Ugradni modul Keystone može se opremiti sa do 6 preLink<sup>®</sup> odnosno fixLink<sup>®</sup> Keystone modula. Ugradni modul Keystone je postavljen u dva nivoa, tako da su moduli u ravni sa prednjom pločom. Pričvršćivanje kablova vrši se direktno na kućištu ugradnog modula. Mogu se pričvrstiti 6 pojedinačnih kablova ili 1 trunk kabl.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_Modulträger_1HE_488e9c42e4: {
        name: 'Nosač modula (BGT) 19" 1HE za upotrebu ugradnih modula',
        text: 'Nosač modula (BGT) za upotrebu 7TE ugradnih modula u bakru i optici. Pričvršćuju se sa dve vijka sa strane panela na nosač modula. Ugradni moduli se mogu potpuno izvući iz nosača modula. Maksimalno se može pričvrstiti 3x 7TE ugradnih modula (=18 portova) u nosaču modula.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      csm_Sidemodulträger_3HE_87268249f6: {
        name: 'Bočni nosač modula 3HE/14TE za upotrebu ugradnih modula',
        text: 'Bočni nosač modula (S-BGT) za upotrebu 7TE ugradnih modula u bakru i optici. Ugradni moduli se pričvršćuju sa dve vijka sa strane panela na nosač modula. Ugradni moduli se mogu potpuno izvući iz nosača modula. Maksimalno se može pričvrstiti 2x 7TE ugradnih modula (=12 portova) u nosaču modula. Pričvršćivanje bočnog nosača modula (S-BGT) vrši se na bočnoj perforaciji ormara. Time je omogućena jednostavna i brza montaža. Bočnom montažom ormara, moguće je realizovati prostor za kabliranje koji štedi prostor i posebno je klimatski prihvatljiv, bez ometanja protoka vazduha.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      consolidation_point_panel: 'Панел тачке консолидације, ДИН шине итд.',
      csm_CPGehäuse_10___39edc363aa: {
        name: 'Podni Consolidation Point Panel 19" odnosno 10" 1HE',
        text: 'Podni Consolidation Point Panel sa zamenljivim prednjim pločama za prihvat različitih priključaka u bakru i optici. Opcionalno, podni Consolidation Point Panel može biti osiguran bravom protiv neovlašćenog pristupa.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
        datasheets: [
          {
            name: 'Consolidation Point Panel 10"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151025_B_CPPF01KS1E_Datasheet_EasyLan_CP_Box_10_Zoll_1U.pdf',
          },
          {
            name: 'Consolidation Point Panel 19"',
            href: 'https://s3.eu-central-1.amazonaws.com/think-internet-de.bfs-zvk-easylan/PUBLIC_STORAGE/Unterflur-Consolidation+Point+Panel+19%22+bzw.+10%22+1HE/ZN-BL151024_B_CPPF09KS1E_Datasheet_EasyLan_CP_Box_19_Zoll_1U.pdf',
          },
        ],
      },
      csm_Unterbaupanel_4x_KS_RAL7035_b8b62c45c1: {
        name: 'Nadgradni Consolidation Point Panel za 4-12 Keystone modula',
        text: 'Nadgradni Consolidation Point Panel za preLink<sup>®</sup> / fixLink<sup>®</sup> RJ45 Keystone module, za upotrebu kao Consolidation Panel, direktna montaža ili pomoću montažnog seta za montažu na šine u elektro-distributivnim razvodnim ormanima. Nadgradni Consolidation Point Panel postavljen je u dva nivoa. Tako su moduli u ravni sa prednjom pločom.\n\nMontažni materijal i Keystone moduli nisu uključeni u isporuku.',
      },
      trunkkabel: 'Trunk kablovi',
      csm_CTBC_Trunkkabel_fixLink_8023e4b1c3: {
        name: 'SL CTBC sistem podeljenih kablova Copper-Trunk-Bundled-Cable, klasa EA ISO/IEC',
        text: 'CTBC sistem podeljenih kablova nudi se za fleksibilnu upotrebu u strukturalnom kabliranju zgrada i data centrima. Kablovi skupljeni u zaštitni omotač fabrički su konfigurisani sa fixLink<sup>®</sup> Keystone modulima i testirani. Skupljanje se može izvoditi kako sa instalacionim kablovima, tako i sa fleksibilnim kablovima. Maksimalna dužina skupljenog kabla je do 20 m.',
      },
      csm_CTCC_Trunkkabel_fixLink_efb2b05f62: {
        name: 'SL CTCC kompaktni sistem kablova Copper-Trunk-Compact-Cable, klasa EA ISO/IEC',
        text: 'CTCC kompaktni sistem kablova nudi se zbog svog minimalnog prečnika kabla i stoga male požarne opasnosti za fleksibilnu upotrebu u strukturalnom kabliranju zgrada i data centrima. Maksimalna dužina višestrukog kabla iznosi zavisno od tipa 60-90 m. Sistem podeljenih kablova kao 10-Gbit rešenje za data centre ispunjava najmanje zahtev klase EA.',
      },
      hds_innengabel: 'H.D.S. unutrašnji kablovi',
      csm_HDS_Innenkabel01_4cd8e032b6: {
        name: 'H.D.S. unutrašnji kabl Kat. 7, 6x (4 x 2 x AWG 23) S-FTP B2ca, S-FTP Dca',
        text: 'H.D.S. unutrašnji kabl Kat. 7 S/FTP za jednostavno kabliranje između ormara u data centrima i višestruke veze od distribucionih soba do pod-distributivnih tačaka. Kabl je sastavljen od 24 parno oklopljenih kablovskih elemenata, po 4 para su grupisana u jednu uvijenu jedinicu. Svaka uvijena jedinica je označena numeričkom trakom radi lakše identifikacije. Fleksibilni centralni element za rasterećenje naprezanja štiti kabl posebno dobro pri uvlačenju. Plašt je izrađen od fleksibilnog LSOH materijala.',
      },
      csm_HDS_Innenkabel01_4cd8e032b7: {
        name: 'H.D.S. unutrašnji kabl Kat. 7 6x (4 x 2 x AWG 26) S-FTP Dca',
        text: 'H.D.S. unutrašnji kabl Kat. 7 S/FTP za jednostavno kabliranje između ormara u data centrima i višestruke veze od distribucionih soba do pod-distributivnih tačaka. Kabl je sastavljen od 24 parno oklopljenih kablovskih elemenata, po 4 para su grupisana u jednu uvijenu jedinicu. Svaka uvijena jedinica je označena numeričkom trakom radi lakše identifikacije. Fleksibilni centralni element za rasterećenje naprezanja štiti kabl posebno dobro pri uvlačenju. Plašt je izrađen od fleksibilnog LSOH materijala.',
      },
      generic: {
        download: 'Preuzmi',
      },
    },
  },

  imprint: {
    headline: 'Impresum',
  },

  dataProtection: {
    headline: 'Zaštita podataka',
  },

  footer: {
    dataProtection: 'Zaštita podataka',
    imprint: 'Impresum',
    contact: 'Kontakt',
  },

  UI: {
    autocomplete: {
      searchError: 'Pretraga nije mogla biti izvršena.',
      searchEmpty: 'Nema rezultata',
    },
    dropdown: {
      emptySelection: '--- molimo izaberite ---',
    },
  },
}

export const langKey = 'SR'

const Default = {
  translation,
  langKey,
}

export default Default
